import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/index';

@Injectable({
	providedIn: 'root'
})
export class CrashService {
	httpClient: HttpClient;

	constructor(httpClient: HttpClient) {
		this.httpClient = httpClient;
	}

	getCrashStats(postData: any): Observable<any> {
		return this.httpClient.post(environment.apiUrl + '/crash-stats', postData);
	}

	getCrashes(postData: any) {
		return this.httpClient.post(environment.apiUrl + '/crashes', postData);
	}

	getRoutes(state:String): Observable<any> {
		return this.httpClient.post(environment.apiUrl + '/routes', {"state" : state});
	}

	getCarriers(state:string): Observable<any> {
		return this.httpClient.post(environment.apiUrl + '/carriers', {"state" : state});
	}

	getCrashRisks(postData): Observable<any> {
		return this.httpClient.post(environment.apiUrl + '/crash-predictions', postData);
	}

	getCrashFields(): Observable<any> {
		return this.httpClient.get(environment.apiUrl + '/crash-field-lookups');
	}

	getCrashTileSplits(): Observable<any> {
		return this.httpClient.post(environment.apiUrl + '/crash-tile-splits', {});
	}

}
