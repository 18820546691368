import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class InspectionDataDownloadService {
	private headers = {
		'Date' : "inspenddate",
        'End Time' : 'inspendtime',
        'Vehicle Type' : 'vehicletruckbusind',
        "Barracks" : "barracks_name",
		"Inspection Level" : "insptypelevelcode",
		"Trooper Name" : "inspectorname",
		"Trooper Badge #" : "inspectorcode",
		"Any Driver OOS Violations" : "any_driver_out_of_service",
        "Any Vehicle OOS Violations" : "any_vehicle_out_of_service",
        "Any OOS Violations" : "any_out_of_service",
        "# Violations" : "num_violations",
        "# Driver Violations" : "num_driver_violations",
        "# Vehicle Violations" : "num_vehicle_violations",
        "Result of Crash" : "any_crash_related",
        "Traffic Enforcement" : "insptrafficenfflag",
		"HazMat" : "hazmat",
        "Interstate/Intrastate" : "carrierinterstateflag",
        "GVWR" : "vehiclegcweightrating",
        "Duration (minutes)" : "inspduration",
        "Location" : "highwaydesctext",
        "Carrier" : "carriername",
	};

	constructor() {
	}

	generateCSV(data) {
		
		let csvContent = '';
		const headerKeys = Object.keys(this.headers);
		csvContent += headerKeys.join(',') + '\r\n';
		data.forEach((rowArray) => {
			const row = headerKeys.map((h) => {
				if(rowArray[this.headers[h]] == null) {
					return ""
				} else {
					return '"' + rowArray[this.headers[h]] + '"';
				}
			}).join(',');
			csvContent += row + '\r\n';
		});

		let hiddenElt = document.createElement('a');
		const csvData = new Blob([csvContent], {type: 'text/csv'});
		const csvUrl = URL.createObjectURL(csvData);
		// const encodedUri = encodeURI(csvContent);
		hiddenElt.href = csvUrl;
		hiddenElt.target = "_blank";
		hiddenElt.download = "data.csv";
		hiddenElt.click();
		hiddenElt.remove();
		URL.revokeObjectURL(csvUrl);
	}
}
