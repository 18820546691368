import { Component, OnInit } from '@angular/core';
import * as forge from 'node-forge';
import { AuthService } from './auth.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { BaseComponent } from '../common/common.component';
import { CommonService } from '../common/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {

  username: string;
  password: string;
  message = "";
  returnUrl: string;
  pubKey: string;
  rememberMe: boolean;

  loginServ: AuthService

  constructor(commonService:CommonService, loginServ:AuthService, private router: Router) { 
    super(commonService)
    this.loginServ = loginServ;
  }

  ngOnInit() {
		if (sessionStorage.getItem('returnUrl')){
			this.returnUrl = sessionStorage.getItem('returnUrl');
			sessionStorage.removeItem('returnUrl');
		} else {
			this.returnUrl = '/';
		}

		sessionStorage.removeItem('pubKey');
		this.loginServ.getPublicKey().subscribe((data) => {
			this.pubKey = data['pubKey'];
			sessionStorage.setItem('pubKey', data['pubKey']);
		}, error => {
      this.handleError(error);
    });    
  }

  login() {
    this.hasError = false;
    try {
      // Import Public Key
		  var rsa = forge.pki.rsa;
		  var publicKey = <forge.pki.rsa.PublicKey> forge.pki.publicKeyFromPem(sessionStorage.getItem('pubKey'));
    } catch {
      // if for whatever reason the public key is invalid, show an error message
      this.handleError({});
      return;
    }
		// Username Encryption
		var bufUser = forge.util.createBuffer(this.username, 'utf8');
		var encUser = publicKey.encrypt(bufUser.bytes(), 'RSA-OAEP');
		var b64EncUser = forge.util.encode64(encUser);

		// Password Encryption
		var bufPass = forge.util.createBuffer(this.password, 'utf8');
		var encPass = publicKey.encrypt(bufPass.bytes(), 'RSA-OAEP');
		var b64EncPass = forge.util.encode64(encPass);

    let expires = moment().add(1, 'd');
    if(this.rememberMe) {
      expires = moment().add(365, 'd');
    }

    // Send encrypted user data to backend
    let postContent = {"username" : b64EncUser, "password" : b64EncPass, "expires" : expires.toISOString()};
		this.loginServ.login(postContent).subscribe((result: any) => {
      this.router.navigate([this.returnUrl]);
		},
		error => {
      this.loginServ.logout();
      this.handleError(error);
		})
  }

}
