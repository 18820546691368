import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'predicatefilter',
    pure: false
})
export class PredicateFilterPipe implements PipeTransform {
    transform(items: any[], predicate: (item: any) => boolean): any {
        if (!items || !predicate) {
            return items;
        }
        return items.filter(item => predicate(item));
    }
}