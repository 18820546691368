import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class RequestAccountService {

	constructor(private http: HttpClient) {
	}


	sendRequest(formData) {
		return this.http.post(`${environment.apiUrl}/request-account`, formData);
	}
}
