import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {CommonService, IMonthInfo} from '../common/common.service';
import {Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import * as d3 from 'd3';
import * as moment from 'moment';
import {BaseComponent} from '../common/common.component';
import {CrashService} from './crash.service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {delay} from 'rxjs/internal/operators';
import {start} from "repl";

declare var vega: any;

@Component({
  selector: 'app-crash-time',
  templateUrl: './crash-time.component.html',
  styleUrls: ['./crash-time.component.css']
})
export class CrashTimeComponent extends BaseComponent implements OnInit {

  id: number;
  zone: NgZone;
  crashService: CrashService;
  monthDayNumbers = _.range(1, 32);
  lastCrashDate: Date;
  startDateFilter: moment.Moment;
  endDateFilter: moment.Moment;
  activeMonthOptions: IMonthInfo[];

  @ViewChild('yearlyDateContainer') yearlyDateContainer: ElementRef;
  yearlyDateView: any;
  yearlyDateViewPromise: Promise<any>;

  @ViewChild('hourAndDayOfWeekContainer') hourAndDayOfWeekContainer: ElementRef;
  hourAndDayOfWeekView: any;
  hourAndDayOfWeekViewPromise: Promise<any>;


  @ViewChild('weatherEffectsContainer') weatherEffectsContainer: ElementRef;
  weatherEffectsView: any;
  weatherEffectsViewPromise: Promise<any>;

  crashStats: any;
  crashStatSubscription: Subscription;
  crashMinColor = 'rgb(197,229,222)';
  crashMaxColor = 'rgb(92,121,157)';
  crashInterpolator = d3.interpolateRgb(this.crashMinColor, this.crashMaxColor);

  dateRangePromise: Observable<object>;

  injuryDropdownSettings = {
    singleSelection: false,
    itemsShowLimit: 1,
};

  constructor(crashService: CrashService, commonService: CommonService, zone: NgZone) {
    super(commonService);
    this.crashService = crashService;
    this.zone = zone;
  }

  ngOnInit() {
    this.selectedInjuryStatus = [];
    super.ngOnInit();

    this.commonService.getCrashDateRange().subscribe((data: any) => {
      this.lastCrashDate = moment(data.last_crash_date).toDate();
      this.refreshCrashData();
    }, err => {
      this.handleError(err);
    });

    this.hourAndDayOfWeekViewPromise = this.setupVegaView('/assets/vega-specs/crashes-by-hour-and-day-of-week.json',
      this.hourAndDayOfWeekContainer, 'hourAndDayOfWeekView');

    this.yearlyDateViewPromise = this.setupVegaView('/assets/vega-specs/crashes-by-date.json',
      this.yearlyDateContainer, 'yearlyDateView', 600);

    this.weatherEffectsViewPromise = this.setupVegaView('/assets/vega-specs/weather-effects-by-month.json',
      this.weatherEffectsContainer, 'weatherEffectsView');
  }

  ngbDateStructToMoment(s: NgbDateStruct) {
    return moment({year: s.year, month: s.month - 1, day: s.day});
  }

  getPostParameters() {
    if (this.timeRange) {
      const sortedYears = this.timeRange.years.sort((a, b) => a.name - b.name);
      const endYear = sortedYears.length - 1;
      const startQuarter = [sortedYears[endYear].name, sortedYears[endYear].selectedQuarters.sort()[sortedYears[endYear].selectedQuarters.length - 1]];
      const endQuarter = [sortedYears[0].name, sortedYears[0].selectedQuarters.sort()[0]];
      if (this.timeRange.fiscalYear) {
        this.endDateFilter = moment(this.getFiscalQuarterStartDate(endQuarter)).subtract(1, 'day');
        this.startDateFilter = moment(this.getFiscalQuarterStartDate(startQuarter));
        this.activeMonthOptions = _.cloneDeep(this.fiscalOrderMonthOptions);
      } else {
        const startSubtract = startQuarter[1] === 1 ? -3 : -4;
        this.startDateFilter = moment([startQuarter[0], (startQuarter[1] * 3) - startSubtract, 1]);
        this.endDateFilter = moment([endQuarter[0], (startQuarter[1] * 3) - 1, 31]);
        this.activeMonthOptions = _.cloneDeep(this.monthOptions);
      }
    }

    return {
      barracks: _.filter(this.selectedRegions.barracks, b => b.type == 'barracks').map(b => b.barracks_id),
      vehicle_type: this.selectedVehicleType,
      injury_status_filter: this.selectedInjuryStatus,
      include_cargo_body_type: true,
      include_driver_contrib_code: true,
      include_route_detail: true,
      include_carrier_detail: true,
      state_filter: this.selectedState,
      time_range_filter: this.timeRange,
      counties: _.map(this.selectedRegions.counties, c => c.county_fips)
    };
  }

  buildLegend(elementId, min, max) {
    const w = 300;
    const h = 50;
    d3.select(elementId).selectAll('*').remove();
    const key = d3.select(elementId).append('svg')
      .attr('width', w)
      .attr('height', h)
      .attr('class', 'legend');

    const defs = key.append('defs');

    const legend = defs.append('svg:linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '100%')
      .attr('y2', '100%')
      .attr('spreadMethod', 'pad');

    legend.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', this.crashMinColor)
      .attr('stop-opacity', 1);

    legend.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.crashMaxColor)
      .attr('stop-opacity', 1);

    key.append('text')
      .html('Crashes')
      .attr('y', 20);

    key.append('rect')
      .attr('width', w - 70)
      .attr('height', h - 20)
      .style('fill', 'url(#gradient' + ')')
      .attr('transform', 'translate(70,20)');

    const y = d3.scaleLinear()
      .range([0, w - 70])
      .domain([min, max]);

    const axis = d3.axisTop(y);

    key.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(70,20)')
      .call(axis);
    key.attr('width', w+6);
  }

  refreshCrashData() {
    this.performCommonChangeHandling();

    const postParams = this.getPostParameters();
    if (this.crashStatSubscription) {
      this.crashStatSubscription.unsubscribe();
    }

    this.crashStatSubscription = this.crashService.getCrashStats(postParams)
      .pipe(delay(900)).subscribe(data => {
        this.clearError();

        this.crashStats = data;
        const crashes_by_month_day = _.map(_.groupBy(this.crashStats.crashes_by_date, 'month_day'), (v) => {
          const dt = moment(v[0].crashdate);
          return {
            "num_crashes" : _.sum(_.map(v, "num_crashes")),
            "month_day" : v[0]["month_day"],
            "monthNameAbbrev" : dt.format("MMM"),
            "month" : dt.month(),
            "day" : dt.date()
          };
        });
        const crash_vals_yearly = _.map(crashes_by_month_day, "num_crashes");
        this.crashStats.min_crash_count_daily = _.min(crash_vals_yearly);
        this.crashStats.max_crash_count_daily = _.max(crash_vals_yearly);
        this.buildLegend('#yearly-legend-container',this.crashStats.min_crash_count_daily,this.crashStats.max_crash_count_daily);

        const crash_vals_hourly = _.map(this.crashStats.crashes_by_hour_dow, 'num_crashes');
        this.crashStats.min_crash_count_hourly = _.min(crash_vals_hourly);
        this.crashStats.max_crash_count_hourly = _.max(crash_vals_hourly);

        this.buildLegend('#weekly-legend-container',this.crashStats.min_crash_count_hourly, this.crashStats.max_crash_count_hourly);

        _.each(this.crashStats.crashes_by_date, d => {
          d.crashdate_dt = moment(d.crashdate);
          d.crashdate_fmt = d.crashdate_dt.format('YYYY-MM-DD');
          d.month = d.crashdate_dt.month();
          d.day = d.crashdate_dt.date();
          d.monthNameAbbrev = d.crashdate_dt.format('MMM');
        });

        const crashesByWeatherEffect = _.flatMap(_.groupBy(this.crashStats.crashes_by_date, 'monthNameAbbrev'), (recs, month) => {
          const num_snow_sleet_crashes = _.reduce(recs, (m, c) => m + c.num_snow_sleet_crashes, 0);
          const num_rain_wind_fog_crashes = _.reduce(recs, (m, c) => m + c.num_rain_wind_fog_crashes, 0);
          const num_crashes = _.reduce(recs, (m, c) => m + c.num_crashes, 0);
          const firstDate = _.min(_.map(recs, 'crashdate_fmt'));
          // this hack ensures that vega re-sorts the x-axis when switching between fiscal and calendar
          const monthName = month + (this.timeRange && this.timeRange.fiscalYear ? ' ' : '');
          return ([
            {
              monthName: monthName, weather_effect: 'Snow/Sleet', yearMon: firstDate,
              pct_of_crashes: num_crashes == 0 ? 0 : Math.round((num_snow_sleet_crashes / num_crashes) * 100)
            },
            {
              monthName: monthName, weather_effect: 'Rain/Wind/Fog', yearMon: firstDate,
              pct_of_crashes: num_crashes == 0 ? 0 : Math.round((num_rain_wind_fog_crashes / num_crashes) * 100)
            }
          ]);
        });

        const hourTotals = _.map(_.groupBy(this.crashStats.crashes_by_hour_dow, 'hour'), (groups, key) => {
          return ({
            hour: parseInt(key),
            downame: 'All',
            num_crashes: groups.reduce((m, e) => e.num_crashes + m, 0),
          });
        });
        const maxHourTotal = _.max(_.map(hourTotals, 'num_crashes'));
        hourTotals.map((h: any) => {
          h.barSize = h.num_crashes / maxHourTotal * 3;
        });

        const dowTotals = _.map(_.groupBy(this.crashStats.crashes_by_hour_dow, 'downame'), (groups, key) => {
          return ({
            hour: 'All',
            downame: key,
            num_crashes: groups.reduce((m, e) => e.num_crashes + m, 0),
            barSize: 1.5
          });
        });
        const maxDowTotal = _.max(_.map(dowTotals, 'num_crashes'));
        dowTotals.map(d => {
          d.barSize = d.num_crashes / maxDowTotal * 3;
        });

        this.hourAndDayOfWeekViewPromise.then(function (outerThis: CrashTimeComponent) {
          outerThis.hourAndDayOfWeekView.signal('colorRange', [outerThis.crashMinColor, outerThis.crashMaxColor]);
          outerThis.hourAndDayOfWeekView.signal('colorDomain', [outerThis.crashStats.min_crash_count_hourly, outerThis.crashStats.max_crash_count_hourly]);

          const changeset = vega.changeset().remove(() => true).insert(outerThis.crashStats.crashes_by_hour_dow);
          outerThis.hourAndDayOfWeekView.change('table', changeset);
          const changeset2 = vega.changeset().remove(() => true).insert(hourTotals);
          outerThis.hourAndDayOfWeekView.change('colTotals', changeset2);
          const changeset3 = vega.changeset().remove(() => true).insert(dowTotals);
          outerThis.hourAndDayOfWeekView.change('rowTotals', changeset3).run();
        }, (err) => {
          this.handleError(err);
        });

        const daysTotal = _.map(_.groupBy(this.crashStats.crashes_by_date, 'day'), (groups, key) => {
          return ({
            day: parseInt(key),
            domname: key,
            monthNameAbbrev: 'All',
            num_crashes: groups.reduce((m, e) => e.num_crashes + m, 0),
          });
        });
        const maxDayTotal = _.max(_.map(daysTotal, 'num_crashes'));
        daysTotal.map((h: any) => {
          h.barSize = h.num_crashes / maxDayTotal * 3;
        });

        const monthsTotal = _.map(_.groupBy(this.crashStats.crashes_by_date, 'monthNameAbbrev'), (groups, key) => {
          return ({
            month: 'All',
            monthNameAbbrev: key,
            num_crashes: groups.reduce((m, e) => e.num_crashes + m, 0),
            barSize: 1.5
          });
        });


        const maxMonthTotal = _.max(_.map(monthsTotal, 'num_crashes'));
        monthsTotal.map(d => {
          d.barSize = d.num_crashes / maxMonthTotal * 3;
        });

        this.yearlyDateViewPromise.then(function (outerThis: CrashTimeComponent) {
          outerThis.yearlyDateView.signal('colorRange', [outerThis.crashMinColor, outerThis.crashMaxColor]);
          outerThis.yearlyDateView.signal('colorDomain', [outerThis.crashStats.min_crash_count_daily, outerThis.crashStats.max_crash_count_daily]);

          const changeset = vega.changeset().remove(() => true).insert(crashes_by_month_day);
          outerThis.yearlyDateView.change('table', changeset);


          const changeset4 = vega.changeset().remove(() => true);
          outerThis.yearlyDateView.change('months', changeset4).run();
          const changeset5 = vega.changeset().insert(outerThis.activeMonthOptions);
          outerThis.yearlyDateView.change('months', changeset5).run();


          const changeset2 = vega.changeset().remove(() => true).insert(monthsTotal);
          outerThis.yearlyDateView.change('colTotals', changeset2);
          const changeset3 = vega.changeset().remove(() => true).insert(daysTotal);
          outerThis.yearlyDateView.change('rowTotals', changeset3).run();

        }, (err) => {
          this.handleError(err);
        });

        this.weatherEffectsViewPromise.then(function (outerthis: CrashTimeComponent) {
          const changeset = vega.changeset().remove(() => true).insert(crashesByWeatherEffect);
          outerthis.weatherEffectsView.change('table', changeset).run();
        }, err => {
          this.handleError(err);
        });

      }, err => {
        this.handleError(err);
      });
  }

  interpolateCrashColor(numCrashes) {
    const pct = (numCrashes - this.crashStats.min_crash_count_daily) /
      (this.crashStats.max_crash_count_daily - this.crashStats.min_crash_count_daily);
    return this.crashInterpolator(pct);
  }

  onInputChange($event, forceUpdateField?) {
    if(forceUpdateField) {
      this[forceUpdateField] = $event;
    }
    this.refreshCrashData();
  }

  public getCrashBackgroundForDate(year: number, month: number, day: number) {
    if (this.timeRange && this.timeRange.fiscalYear && month < 11) {
      year = year + 1;
    }
    const dateRepr = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
    const mdate = moment(dateRepr);
    if (!mdate.isValid() || mdate < this.startDateFilter || mdate > this.endDateFilter) {
      return ('rgba(0, 0, 0, 0)');
    } else {
      const crashRec = this.crashStats.indexed_crashes_by_date[dateRepr];
      const numCrashes = crashRec ? crashRec.num_crashes : 0;
      return this.interpolateCrashColor(numCrashes);
    }

  }

  resetFilter() {
    this.resetSelectionValues();
    this.refreshCrashData();
  }
}
