import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ICrashFilterOptions } from '../crash-mapping-data';

@Injectable({
	providedIn: 'root'
})
export class DownloadDataService {
	col1Header = {
		'CDS Crash Numb': 'crash_numb',
		'Crash Report Number': 'crashreportnum',
		'Date (MM/DD/YYYY)': 'crash_formated_date',
		'Day of Week': 'crash_week_day',
		Time: 'formatted_time',
		Severity: 'csf',
		'Number of CMVs': 'num_cmvs',
		'Number of Vehicles': 'num_vehicles',
		'Number of Fatalities': 'numberoffatalities',
		'Number of Injuries': 'numberofinjuries',
		Towaway: 'towaway',
		'Resulting Inspection': 'resulting_inspection',
		'Resulting OOS': 'resulting_oos_inspection',
		'Manner of Collision': 'mcf',
		Trafficway: 'twf',
		'Roadway Junction': 'rjf',
		Weather: 'wcf',
		'Road Surface': 'rsf',
		'Light Condition': 'alf',
		'School Bus Related': 'school_bus_related',
		'Work Zone Related': 'workzone_related_text',
	};

	col2Header = {
		County: 'county_name',
		Barracks: 'barracks_name',
		// Town: 'city_town_code',
		Town: 'town_name',
		'Street Location': 'crashstreetlocation',
		'Reporting Agency': 'reportingagency',
		'Officer Name & Badge Number': 'officerbadgenum',
		'Vehicle Configuration': 'vehicle_configs',
		'Cargo Body Type': 'cargo_body_types',
		GVWR: 'gvwrf',
		'Registration State': 'vehiclelicstates',
		'Carrier Address & Town & State': 'carrier_full_address',
		'Interstate/Intrastate': 'isf',
		'Carrier Name': 'carriernames',
		'USDOT Number': 'usdotnums',
		'Driver State': 'cmv_driver_state',
		'Driver License Class': 'driverlicenseclasses',
		'Driver Cited': 'driver_cited',
		'Driver Contributing Code': 'cmv_driver_contrib_code_desc',
		'Hazmat Placard': 'haz_mat_placard_text',
		'Hazmat 1 Digit': 'hm1digitnums',
		'Hazmat 4 Digit': 'hm4digitnums',
		'Hazmat Name': 'hmnames',
	};

	col1HeaderKeys = Object.keys(this.col1Header);
	col2HeaderKeys = Object.keys(this.col2Header);

	decodeKey: ICrashFilterOptions;

	setDecodeKey(key) {
		this.decodeKey = key;
	}

	formatItem(d) {
		const dateObj = moment(d.crashdate);
		const timeObj = moment('1970-01-01 ' + d.crashtime);
		d.crash_formated_date = dateObj.format('MM/DD/YYYY');
		d.crash_week_day = dateObj.format('dddd');
		d.formatted_time = timeObj.format('HHmm');
		d.carrier_full_address = d.addressstreettexts + ' ' +
			d.addresszipcodes + ' ' +
			d.addresscitynames + ' ' +
			d.addressstatecodes;


		d.csf = this.decodeValue(d.crash_severity_code, 'crash_severity');
		d.mcf = this.decodeValue(d.manr_coll_code, 'manner_collision', 'manr_coll');
		d.twf = this.decodeValue(d.trafficway, 'trafficway_descr', 'trafy_descr');
		d.wcf = this.decodeValue(d.weathercondition, 'weather_condition', 'weath_cond');
		d.rjf = this.decodeValue(d.rdwy_jnct_type_code, 'rdwy_jnct_type');
		d.rsf = this.decodeValue(d.roadsurfacecondition, 'road_surf_cond');
		d.alf = this.decodeValue(d.lightcondition, 'ambient_light', 'ambnt_light');
		d.dccf = this.decodeValue(d.cmv_driver_contrib_code, 'driver_cntrb_circ');
		d.gvwrf = this.decodeValue(+d.vehiclegvwrs, 'gvwr_power_unit', 'gross_vehc_wght_ratg');
		d.county_name = d.crashfipscountycode in this.decodeKey.codeIndexedCounty ? this.decodeKey.codeIndexedCounty[d.crashfipscountycode].cnty_name : "";
		d.school_bus_related = d.schl_bus_reld_code in this.decodeKey.codeIndexedBus ? this.decodeKey.codeIndexedBus[d.schl_bus_reld_code].schl_bus_reld_descr : "";
		d.workzone_related_text = d.workzone_related == 1 ? 'Yes' : 'No';
		d.haz_mat_placard_text = parseInt(d.hmplacards) in this.decodeKey.codeIndexedPlacard ? this.decodeKey.codeIndexedPlacard[parseInt(d.hmplacards)].haz_mat_placard_descr : "";
		d.isf = d.interstate == 1 ? 'Interstate' : 'Intrastate';
		d.driver_cited = d.citation_issued ? 'Yes' : 'No';
		d.driver_city_state = d.cmv_driver_city + ', ' + d.cmv_driver_state;
		d.cmv_driver_contrib_code_desc = d.cmv_driver_contrib_code in this.decodeKey.codeIndexedDriverContrib ? this.decodeKey.codeIndexedDriverContrib[d.cmv_driver_contrib_code].drvr_cntrb_circ_descr : "";

		return d;
	}


	decodeValue(item, decodeKey, compareKey = null) {
		compareKey = compareKey ? compareKey : decodeKey;
		const decodedKey = this.decodeKey[decodeKey].find((c) => item === c[compareKey + '_code']);
		return decodedKey ? decodedKey[compareKey + '_descr'] : '';
	}

	labelItem(item) {

	}

	generateCSV(data) {
		let csvContent = '';
		const combinedHeaders = {...this.col1Header, ...this.col2Header};
		const headerKeys = this.col1HeaderKeys.concat(this.col2HeaderKeys);
		csvContent += headerKeys.join(',') + '\r\n';
		data.forEach((rowArray) => {
			const row = headerKeys.filter((h) => combinedHeaders[h] !== 'header').map((h) => {
				if(rowArray[combinedHeaders[h]] == null) {
					return "";
				} else {
					return '"' + rowArray[combinedHeaders[h]] + '"';
				}
			}).join(',');
			csvContent += row + '\r\n';
		});

		let hiddenElt = document.createElement('a');
		const csvData = new Blob([csvContent], {type: 'text/csv'});
		const csvUrl = URL.createObjectURL(csvData);
		// const encodedUri = encodeURI(csvContent);
		hiddenElt.href = csvUrl;
		hiddenElt.target = "_blank";
		hiddenElt.download = "data.csv";
		hiddenElt.click();
		hiddenElt.remove();
		URL.revokeObjectURL(csvUrl);
	}
}
