import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { InspectionComponent } from './inspection/inspection.component';
import { InspectionTrooperComponent } from './inspection/inspection-trooper.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './auth/login.component';
import { AccessGuard } from './auth/access-guard.service';
import { UsersComponent } from './users/users.component';
import { CrashLocationComponent } from './crash/crash-location.component';
import { CrashTimeComponent } from './crash/crash-time.component';
import { CrashTrendsComponent } from './crash/crash-trends.component';
import { CrashComponent } from './crash/crash.component';
import { GoalTrackingComponent } from './goal-tracking/goal-tracking.component';
import { CrashMappingComponent } from './crash-mapping/crash-mapping.component';
import { InspectionMappingComponent } from './inspection-mapping/inspection-mapping.component';
import { CrashRiskModelComponent } from './crash-risk-model/crash-risk-model.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { RequestAccountComponent } from './request-account/request-account.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  {path: '', component: HomeComponent, data: {requiresLogin: false}, canActivate: [AccessGuard]},
  {path: 'inspection', component: InspectionComponent, data: {requiresLogin: true}, canActivate: [AccessGuard]},
  {path: 'inspection-trooper', component: InspectionTrooperComponent, data: {requiresLogin: true},  canActivate: [AccessGuard]},
  {path: 'inspection-mapping', component: InspectionMappingComponent, data: {requiresLogin: true},  canActivate: [AccessGuard]},
  {path: 'crash', component: CrashComponent, data: {requiresLogin: true},  canActivate: [AccessGuard]},
  {path: 'crash-time', component: CrashTimeComponent, data: {requiresLogin: true},  canActivate: [AccessGuard]},
  {path: 'crash-trends', component: CrashTrendsComponent, data: {requiresLogin: true},  canActivate: [AccessGuard]},
  {path: 'crash-mapping', component: CrashMappingComponent, data: {requiresLogin: true},  canActivate: [AccessGuard]},
  {path: 'crash-risk-model', component: CrashRiskModelComponent, data: {requiresLogin: true},  canActivate: [AccessGuard]},
  {path: 'goal-tracking', component: GoalTrackingComponent, data: {requiresLogin: true}, canActivate: [AccessGuard]},
  {path: 'goal-management', component: GoalTrackingComponent, data: {requiresLogin: true}, canActivate: [AccessGuard]},
  {path: 'login', component: LoginComponent, data: {requiresLogin: false}, canActivate: [AccessGuard]},
  {path: 'password-reset', component: PasswordResetComponent, data: {requiresLogin: false}, canActivate: [AccessGuard]},
  {path: 'password-reset/:user/:token', component: PasswordResetComponent, data: {requiresLogin: false}, canActivate: [AccessGuard]},
  {path: 'request-account', component: RequestAccountComponent, data: {requiresLogin: false}, canActivate: [AccessGuard]},
  {path: 'users', component: UsersComponent, data: {requiresLogin: true}, canActivate: [AccessGuard]},
  {path: 'about', component: AboutComponent, data: {requiresLogin: false}, canActivate: [AccessGuard]},
  {path: '**', component: PageNotFoundComponent, data: {requiresLogin: false}, canActivate: [AccessGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AccessGuard]
})
export class AppRoutingModule { }
