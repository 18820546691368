import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-crash-counts-bar',
  templateUrl: './crash-counts-bar.component.html'
})
export class CrashCountsBarComponent implements OnInit {

  @Input() crashCounts:any;
  @Input() dataFreshnessInfo:any;  
  @Input() selectedVehicleType:string;
  @Input() selectedInjuryStatus:string | string[];
  vehicleType:string;
  vehicleTypePlural: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.selectedVehicleType == "All CMVs") {
      this.vehicleType = "CMV";
      this.vehicleTypePlural = "CMVs";
    } else if(this.selectedVehicleType == "Large Truck") {
      this.vehicleType = "TRUCK";
      this.vehicleTypePlural = "TRUCKS";
    } else {
      this.vehicleType = "BUS";
      this.vehicleTypePlural = "BUSES";
    }
  }

  supportsFatalCrashRate() {
    return this.selectedInjuryStatus == undefined || this.selectedInjuryStatus == "All" || this.selectedInjuryStatus.length == 0 || _.find(this.selectedInjuryStatus, c => c == 'All' || c == "Fatal Injury");
  }

}

/*
Inj Sev: Non-Fatal Injury (relative to time range & veh type & jurisdiction)
  CMVs Involved in Injury Crashes (count distinct of MCMIS Crash ID)
  CMV Involved Injuries 
  CMV Injury Crashes/All CMV Crashes 
Inj Sev: No Injury/Towaway (relative to time range & veh type & jurisdiction)
  CMVs Involved in Towaway Crashes
  CMV Towaway Crashes/All CMV Crashes 
Inj Sev: Fatal (relative to time range & veh type & jurisdiction)
  CMVs Involved in Fatal Crashes
  CMV Involved Fatalities
  CMV Fatal Crashes/All CMV Crashes
Inj Sev: All (relative to time range & veh type & jurisdiction)
  CMVs Involved in Crashes
  CMV Involved Injuries
  CMV Involved Fatalities
  CMV Fatal Crashes/All CMV Crashes
*/