import { Component, OnInit, Input } from '@angular/core';
import { IDataFreshnessInfo } from '../common/common.service';

@Component({
  selector: 'app-inspection-counts-bar',
  templateUrl: './inspection-counts-bar.component.html'
})
export class InspectionCountsBarComponent implements OnInit {

  @Input() inspCounts:any;
  @Input() selectedVehicleType:string;
  @Input() dataFreshnessInfo:IDataFreshnessInfo;
  vehicleType:string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.selectedVehicleType == "All CMVs") {
      this.vehicleType = "CMV";
    } else if(this.selectedVehicleType == "Large Truck") {
      this.vehicleType = "TRUCK";
    } else {
      this.vehicleType = "BUS";
    }
  }

}
