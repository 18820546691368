import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanYN'
})
export class BooleanYNPipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if(value == null || value == undefined) {
      return "-";
    } else if(value == 0) {
      return "N";
    } else {
      return "Y";
    }
  }

}
