import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment'

@Injectable({
	providedIn: 'root'
})
export class InspectionService {
	httpClient: HttpClient;

	constructor(httpClient: HttpClient) {
		this.httpClient = httpClient;
	}

	getInspectionStats(barracks: number[], 
		time_range_filter:any, vehicle_type: string, state:string, 
		counties: number[],
		include_trooper_detail: boolean = false,
		inspection_levels:number[] = []) {
		return this.httpClient.post(environment.apiUrl + '/inspection-stats', {
			barracks,
      		vehicle_type,
			include_trooper_detail,
			state,
			time_range_filter,
			counties,
			inspection_levels
		});
	}

	getInspectionsMapping(postData: any) {
		return this.httpClient.post(environment.apiUrl + '/inspection-mapping', postData);
	}
}
