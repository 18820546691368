import { Injectable } from '@angular/core';
import { geoJSON, icon, marker, tileLayer } from 'leaflet';
import * as d3 from 'd3';
import { InvertibleGeoJSONOptions } from './crash-mapping-data';
import { CrashMappingComponent } from './crash-mapping.component';
import { BehaviorSubject } from 'rxjs';
import {CommonService, IJurisdictionKeysInfo} from "../common/common.service";
import {InspectionMappingComponent} from "../inspection-mapping/inspection-mapping.component";
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class CrashMappingMapService {
	private crashMinColor = 'rgb(74,161,82)';
	private crashMidColor = 'rgb(245,210,122)';
	private crashMaxColor = 'rgb(200,0,49)';
	protected crashesByBarracks: any;
	protected crashesByRegionBkp: { dataByCount: any, min: number, max: number };
	private minCrashBarrack: number;
	private maxCrashBarrack: number;
	focusSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
	markerOnClick: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	regionSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');

	constructor(private commonService: CommonService) {
	}

	generateHeatMap(barracksGeoJson, cmComponent, regionType: string) {
    if(barracksGeoJson && cmComponent.crashes) {
      const jurisdictionKeys = this.commonService.getJurisdictionKeys(regionType);
      this.crashesByRegionBkp = this.calculateCrashesByBarracks(cmComponent.crashesForMapping, jurisdictionKeys, true);
      this.buildMapLegend();
      if ( barracksGeoJson && this.crashesByRegionBkp && !_.isEmpty(this.crashesByRegionBkp)) {
        barracksGeoJson.features = barracksGeoJson.features.map((f) => {
          if ( this.crashesByRegionBkp.dataByCount[f.properties[jurisdictionKeys.idKey]] !== undefined ) {
            f.properties.crashes = this.crashesByRegionBkp.dataByCount[f.properties[jurisdictionKeys.idKey]].length;
            f.properties.name  = f.properties[jurisdictionKeys.nameKey];
          } else {
            f.properties.crashes = 0;
            f.properties.name  = f.properties[jurisdictionKeys.nameKey];
          }
          return f;
        });
        // add and style the geojson polygons
        // const inspInterpolator = d3.interpolateRgb(inspMinColor, inspMaxColor);
        const interp1 = d3.interpolateRgb(this.crashMinColor, this.crashMidColor);
        const interp2 = d3.interpolateRgb(this.crashMidColor, this.crashMaxColor);
        const crashInterpolator = t => {
          return (t < 0.5 ? interp1(t * 2) : interp2((t - 0.5) * 2));
        }

        const selectedRegions = cmComponent.selectedRegions.type === 'barracks' ? cmComponent.selectedRegions.barracks : cmComponent.selectedRegions.counties;

        const leafletLayers: any = [
          tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 10, minZoom: 6}),
          geoJSON(barracksGeoJson, {
            invert: true,
            style: {
              color: '#999999',
              weight: 5,
              opacity: 0,
              fillOpacity: 0.02,
            }
          } as InvertibleGeoJSONOptions),
          geoJSON(barracksGeoJson, {
            invert: false,
            style: (feature) => {
              const color = this.safeInterpolate(feature.properties.crashes,
                this.crashesByRegionBkp.min, this.crashesByRegionBkp.max,
                crashInterpolator);
              let  opacity = 1,
                fillOpacity = 0.9;
              if ( selectedRegions.length > 0  && !selectedRegions.find((b) => cmComponent.matchRegion(b, feature))) {
                    opacity = 0.4;
                    fillOpacity = 0.3;
              }
              return {
                color: 'black',
                fillColor: color,
                opacity,
                weight: 1,
                fillOpacity
              };
            },
            onEachFeature: (feature, layer) => {
              layer.on({
                click: ((e) =>  this.regionSubject.next(this.commonService.mapClickFilter(e, cmComponent.selectedRegions, cmComponent.indexedBarracksOptions))),
                mouseover: (e: any) => {
                  // if ( cmComponent.selectedBarracks.length > 0 &&
                  //   !cmComponent.selectedBarracks.find(b => cmComponent.matchRegion(b,feature)) )
                  // { return; }
                  // running with zone to make sure changes trigger databinding
                  this.focusSubject.next({
                    showingFeatureDiv: true,
                    focusFeature: feature
                  });
                },
                mouseout: (e) => {
                  this.focusSubject.next({
                    showingFeatureDiv: false,
                  });
                }
              });

              if ( cmComponent.selectedBarracks.length > 0 ) {
                const barrackExists = cmComponent.selectedBarracks.find(b => cmComponent.matchRegion(b,feature));
                if ( !barrackExists ) {
                  return;
                }
              }
            },
          } as InvertibleGeoJSONOptions)
        ];
        return leafletLayers;
      }
		}
	}

	addMarkers(crashes) {
	  if(!crashes) return;
		const leafletLayers: any = [
			tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
			})
		];
		crashes.forEach((gp) => {
			leafletLayers.push(marker([+gp.latitude, +gp.longitude], {
				icon: icon({
					iconSize: [12, 20],
					iconAnchor: [6, 19],
					iconUrl: 'assets/marker-icon.png',
					// shadowUrl: 'assets/marker-shadow.png'
				}),
				clickable: true,
			}).on('click', (e) => {
				this.markerOnClick.next(e);
			}));
		});
		return leafletLayers;
	}





  safeInterpolate(val: number, minVal: number, maxVal: number, interpolator: any) {
		const featurePct = (val - minVal) / (maxVal - minVal);
		let color: string;
		if ( val == maxVal ) {
			color = interpolator(1);
		} else if ( val == 0 ) {
			color = interpolator(0);
		} else {
			color = interpolator(featurePct);
		}
		return color;
	}

	calculateCrashesByBarracks(crashes, jurisdictionKeys: IJurisdictionKeysInfo, returnValue?) {
	  const calculatedData = this.commonService.getMinMax(crashes, jurisdictionKeys.crashKey);
    if( returnValue ){
      return calculatedData;
    } else {
      this.crashesByBarracks = calculatedData.dataByCount;
      this.minCrashBarrack = calculatedData.min;
      this.maxCrashBarrack = calculatedData.max;
    }
	}

	buildMapLegend() {
		const w = 100;
		const h = 350;
		document.getElementById('map-legend-container').innerHTML = '<br>';
		// d3.select('#').selectAll('*').remove();
		const key = d3.select('#map-legend-container').append('svg')
			.attr('width', w)
			.attr('height', h)
			.attr('class', 'legend');

		const defs = key.append('defs');

		const buildSingleLegend = (
			minColor: string, midColor: string, maxColor: string,
			lowValue: number, highValue: number,
			title: string, yoffset: number) => {
			const componentHeight = (h - 50);
			const legend = defs.append('svg:linearGradient')
				.attr('id', 'gradient' + title)
				.attr('x1', '100%')
				.attr('y1', '0%')
				.attr('x2', '100%')
				.attr('y2', '100%')
				.attr('spreadMethod', 'pad');

			legend.append('stop')
				.attr('offset', '0%')
				.attr('stop-color', maxColor)
				.attr('stop-opacity', 1);

			legend.append('stop')
				.attr('offset', '50%')
				.attr('stop-color', midColor)
				.attr('stop-opacity', 1);

			legend.append('stop')
				.attr('offset', '100%')
				.attr('stop-color', minColor)
				.attr('stop-opacity', 1);

			key.append('text')
				.html(title)
				.attr('y', 15 + yoffset);

			key.append('rect')
				.attr('width', w - 50)
				.attr('height', componentHeight)
				.style('fill', 'url(#gradient' + title + ')')
				.attr('transform', 'translate(0,' + (yoffset + 20) + ')');

			const y = d3.scaleLinear()
				.range([componentHeight, 0])
				.domain([lowValue, highValue]);
			y.ticks(5);

			const yAxis = d3.axisRight(y);

			key.append('g')
				.attr('class', 'y axis')
				.attr('transform', 'translate(51,' + (20 + yoffset) + ')')
				.call(yAxis);
		};

		// buildSingleLegend(this.inspMinColor, this.inspMaxColor, lowInspValue, highInspValue, 'Inspections', 0);
		buildSingleLegend(this.crashMinColor, this.crashMidColor, this.crashMaxColor,
      this.crashesByRegionBkp.min, this.crashesByRegionBkp.max, 'Crashes', 0);
	}

}
