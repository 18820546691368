import { Component, Input, OnInit } from '@angular/core';
import { DownloadDataService } from './download-data.service';

@Component({
	selector: 'app-crash-mapping-datatable',
	templateUrl: './crash-mapping-datatable.component.html',
	styleUrls: ['./crash-mapping-datatable.component.css']
})
export class CrashMappingDatatableComponent implements OnInit {
	@Input() crashData = Array(5);
	@Input() decodeKey: any = {};
	public detailToggle = {};
	public col1Headers: {};
	public col1HeadersKeys: string[];
	public col2Headers: {};
	public col2HeadersKeys: string[];
	public slicedCrashData = [];

	constructor(private downloadDataService: DownloadDataService) {
	}

	ngOnInit() {
		this.col1Headers = this.downloadDataService.col1Header;
		this.col1HeadersKeys = this.downloadDataService.col1HeaderKeys;
		this.col2Headers = this.downloadDataService.col2Header;
		this.col2HeadersKeys = this.downloadDataService.col2HeaderKeys;

		this.ngOnChanges();
	}

	download() {
		this.downloadDataService.generateCSV(this.crashData);
	}

	ngOnChanges() {
		if ( this.crashData && this.decodeKey ) {
			this.downloadDataService.setDecodeKey(this.decodeKey);
			this.crashData = this.crashData.map((d) => {
				return this.downloadDataService.formatItem(d);
			});
			this.slicedCrashData = this.crashData.slice(0, 50);
		}
	}


}
