import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../common/common.component';
import {CommonService, IJurisdictionInfo} from '../common/common.service';
import {CrashService} from './crash.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import {Subscription} from 'rxjs/index';
import {CrashMappingMapService} from "../crash-mapping/crash-mapping-map.service";

declare var vega: any;

@Component({
  selector: 'app-crash',
  templateUrl: './crash.component.html',
  styleUrls: ['./crash.component.css']
})
export class CrashComponent extends BaseComponent implements OnInit {

  @ViewChild('localVsStateContainer') localStatePoliceWaffleContainer: ElementRef;
  localVsStateChartView: any;
  localVsStateViewPromise: Promise<any>;

  @ViewChild('cargoBodyTypeContainer') cargoBodyTypeContainer: ElementRef;
  cargoBodyTypeChartView: any;
  cargoBodyTypeViewPromise: Promise<any>;

  @ViewChild('driverContribContainer') driverContribContainer: ElementRef;
  driverContribChartView: any;
  driverContribViewPromise: Promise<any>;
  @ViewChild('fixedSubHeader') fixedSubHeader: ElementRef;

  injuryDropdownSettings = {
      singleSelection: false,
      itemsShowLimit: 1,
  };

  crashStats: any;
  crashStatsSubscription: Subscription;
  lastCrashDate: Date;
  heatmap: any;
  regionsGeoJson: any;
  showingFeatureDiv = false;
  focusFeature: any;
  leafletCenter: any;
  leafletZoom: number;
  leafletLayers: any[];
  lastReportStateValue: string;
  regionsGeoJsonSubscription: Subscription;
  leafletOptions: any;
  crashes: any;
  crashesForMapping: any;

  constructor(commonService: CommonService,
              private crashService: CrashService,
              private cms: CrashMappingMapService,
              private zone: NgZone) {
    super(commonService);
  }

  ngOnInit() {
    this.selectedInjuryStatus = [];
    super.ngOnInit();


    this.localVsStateViewPromise = this.setupVegaView('/assets/vega-specs/local-state-police-donut.json',
      this.localStatePoliceWaffleContainer, 'localVsStateChartView');

    this.cargoBodyTypeViewPromise = this.setupVegaView('/assets/vega-specs/crashes-by-body-type.json',
      this.cargoBodyTypeContainer, 'cargoBodyTypeChartView');

    this.driverContribViewPromise = this.setupVegaView('/assets/vega-specs/crashes-by-driver-contrib-code-vg.json',
      this.driverContribContainer, 'driverContribChartView', 500);
    this.commonService.getCrashDateRange().subscribe((data: any) => {
      this.lastCrashDate = moment(data.last_crash_date).toDate();
      this.refreshCrashData();
    }, err => {
      this.handleError(err);
    });

    this.cms.regionSubject.subscribe((data: IJurisdictionInfo) => {
      if (data) {
        this.selectedRegions = data;
      }
    });
    this.cms.focusSubject.subscribe((data) => {
      this.zone.run(() => {
        this.showingFeatureDiv = data.showingFeatureDiv;
        this.focusFeature = data.focusFeature;
      });
    });
  }

  onInputChange($event, forceUpdateField?) {
    if (forceUpdateField) {
      this[forceUpdateField] = $event;
    }
    this.refreshCrashData();
  }

  getPostParameters() {
    return {
      barracks: _.filter(this.selectedRegions.barracks, b => b.type == 'barracks').map(b => b.barracks_id),
      vehicle_type: this.selectedVehicleType,
      injury_status_filter: this.selectedInjuryStatus,
      include_cargo_body_type: true,
      include_driver_contrib_code: true,
      include_route_detail: true,
      include_carrier_detail: true,
      state_filter: this.selectedState,
      time_range_filter: this.timeRange,
      counties: _.map(this.selectedRegions.counties, c => c.county_fips)
    };
  }

  refreshCrashData() {

    this.performCommonChangeHandling();

    const postParams = this.getPostParameters();
    if (this.crashStatsSubscription) {
      this.crashStatsSubscription.unsubscribe();
    }

    this.crashStatsSubscription = this.crashService.getCrashStats(postParams).subscribe(data => {
      this.clearError();
      this.crashStats = data;
      this.setMapData();
      const totalBodyTypeCrashes = _.reduce(this.crashStats.crashes_by_body_type, (memo, val) => {
        return memo + val.num_crashes;
      }, 0);
      _.each(this.crashStats.crashes_by_body_type, v => {
        v.pct_of_crashes = Math.round((v.num_crashes / totalBodyTypeCrashes) * 1000.0) / 10.0;
      });
      this.crashStats.crashes_by_body_type = _.orderBy(this.crashStats.crashes_by_body_type, 'pct_of_crashes');

      this.crashStats.stateLocalData = this.crashStats.crashes_by_agency_type;

      this.localVsStateViewPromise.then(function (outerThis: CrashComponent) {
        const changeset = vega.changeset().remove(() => true).insert(outerThis.crashStats.stateLocalData);
        outerThis.localVsStateChartView.change('table', changeset);
        const changeset2 = vega.changeset().remove(() => true).insert(outerThis.crashStats.overall_counts);
        outerThis.localVsStateChartView.change('crash_summary', changeset2).run();
      }, (err) => {
        this.handleError(err);
      });

      this.cargoBodyTypeViewPromise.then(function (outerThis: CrashComponent) {
        const changeset = vega.changeset().remove(() => true).insert(outerThis.crashStats.crashes_by_body_type);
        outerThis.cargoBodyTypeChartView.change('table', changeset).run();
      }, (err) => {
        this.handleError(err);
      });

      const crashesByDcc = _.mapValues(_.groupBy(this.crashStats.crashes_by_driver_contrib_code, 'drvr_cntrb_circ_descr'), (v, k) => {
        return (v.reduce((m, c) => c.num_crashes + m, 0))
      });
      this.crashStats.crashes_by_driver_contrib_code.forEach(element => {
        element['total_crashes'] = crashesByDcc[element['drvr_cntrb_circ_descr']];
      });
      this.crashStats.crashes_by_driver_contrib_code = _.sortBy(this.crashStats.crashes_by_driver_contrib_code, 'total_crashes');
      const maxDCCCrashCount = _.max(_.map(this.crashStats.crashes_by_driver_contrib_code, 'total_crashes'));

      this.driverContribViewPromise.then(function (outerThis: CrashComponent) {
        const changeset = vega.changeset().remove(() => true).insert(outerThis.crashStats.crashes_by_driver_contrib_code);
        outerThis.driverContribChartView.change('table', changeset).run();
        outerThis.driverContribChartView.signal('numTicks', Math.min(maxDCCCrashCount, 8));
      }, (err) => {
        this.handleError(err);
      });

    }, (err) => {
      this.handleError(err);
    });
  }

  setMapData() {
    if (this.selectedRegions.type === 'barracks') {
      this.crashes = this.crashStats.crashes_by_barracks;
      this.crashesForMapping = this.crashStats.crashes_by_barracks_statewide;
    } else {
      this.crashes = this.crashStats.crashes_by_county;
      this.crashesForMapping = this.crashStats.crashes_by_county_statewide;
    }
    this.toggleMapView(this.selectedRegions.type);
    this.updateLeafletForState(this.selectedState);
  }

  onMapReady(heatmap) {
    this.heatmap = heatmap;
  }

  updateLeafletForState(state: string) {
    if (this.lastReportStateValue != this.selectedRegions.type + state) {
      this.updateGeoJson(state, this.selectedRegions.type);
      this.resetMapView(state);
      this.focusMapPart(this.selectedRegions.barracks.length > 0 ? this.selectedRegions.barracks : this.selectedRegions.counties);

      if (!this.stateSupportsTownMapView(state) && this.selectedRegions.type === 'counties') {
        this.toggleMapView('counties');
      }
      if (this.heatmap) {
        setTimeout(this.heatmap.invalidateSize);
      }
    }

    this.lastReportStateValue = this.selectedRegions.type + state;
  }

  updateGeoJson(state, region = 'barracks') {
    this.cms.calculateCrashesByBarracks(this.crashes, this.commonService.getJurisdictionKeys(region));
    if (this.lastReportStateValue != this.selectedRegions.type + state || this.selectedRegions.type !== region) {
      delete this.regionsGeoJson;
      this.regionsGeoJsonSubscription = this.commonService.getRegionsGeoJson(this.selectedState, region).subscribe((data: any) => {
        this.regionsGeoJson = data;

        this.regionsGeoJson.features.forEach((f) => {
          f.properties.state_abbrev = this.selectedState;
        });

        if (this.selectedRegions.type === 'counties') {
          this.countyMultiSelectOptions = data.features.map((c) => {
            return {
              county_name: c.properties.NAME,
              state_abbrev: this.selectedState
            };
          });
        }
        this.leafletLayers = this.cms.generateHeatMap(this.regionsGeoJson, this, this.selectedRegions.type);
      });
    } else {
      this.leafletLayers = this.cms.generateHeatMap(this.regionsGeoJson, this, this.selectedRegions.type);
    }
  }

  resetMapView(state?) {
    state = state || this.selectedState;
    this.leafletOptions = this.stateSettings[state].leafletOptions;
    this.leafletCenter = this.leafletOptions.center;
    this.leafletZoom = this.leafletOptions.zoom;
  }

  toggleMapView(regionType?) {
    if (this.selectedRegions.type !== regionType) {
      this.selectedBarracks = [];
      this.setSelectedRegionsValue([], regionType === 'cities' ? regionType : null);
    }
    this.resetMapView();
    this.leafletLayers = this.cms.generateHeatMap(this.regionsGeoJson, this, this.selectedRegions.type);
    this.updateGeoJson(this.selectedState, regionType);
    this.selectedRegions.type = regionType;
    if (this.selectedRegions.barracks.length > 0 || this.selectedRegions.counties.length > 0) {
      this.focusMapPart(this.selectedRegions.type === 'barracks' ? this.selectedRegions.barracks : this.selectedRegions.counties);
    }
  }

  regionSelected(sR: IJurisdictionInfo) {
    if (sR) {
      this.onInputChange(sR);
      setTimeout(() => {this.toggleMapView(sR.type);this.focusMapPart(sR.type === 'barracks' ? sR.barracks : sR.counties)});
    }
  }


  focusMapPart(selectedOptions?) {
    if (selectedOptions && selectedOptions.length > 0 && this.regionsGeoJson) {
      // disabled zooming in, too choppy
      //const flyOptions = {maxZoom: this.stateSettings[this.selectedState].leafletOptions.zoom + 1};
      //setTimeout(() => this.heatmap.flyToBounds(this.getSelectedRegionBoundary(selectedOptions, this.regionsGeoJson), flyOptions));
    } else {
      this.resetMapView();
    }
  }


  resetFilter() {
    this.resetSelectionValues();
    this.refreshCrashData();
  }

}
