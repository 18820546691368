import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InspectionService } from './inspection.service';
import { CommonService } from '../common/common.service';
import { BaseComponent } from '../common/common.component';
import * as _ from 'lodash';
import * as d3 from 'd3';
import ResizeObserver from 'resize-observer-polyfill';
import { Subscription } from 'rxjs/index';

declare var vega: any;
declare var vegaTooltip: any;

@Component({
	selector: 'app-inspection-trooper',
	templateUrl: './inspection-trooper.component.html',
	styleUrls: ['./inspection-trooper.component.css']
})
export class InspectionTrooperComponent extends BaseComponent implements OnInit {

	id: number;
	inspectionService: InspectionService;
	tableFillMaxColor = 'rgb(92,121,157)';
	tableFillMinColor = 'rgb(197,229,222)';
	inspectionStats: any;
	activeTrooperDetail: any;
	tableFillInterpolators = {};
	curViolationsDetail: any;

	trooper_detail_columns = [
		{prop: 'inspectorname', summaryfunc: () => null, name: 'Name'},
		{prop: 'inspectorcode', summaryfunc: () => null, name: 'Code'},
		{prop: 'num_inspections', summaryfunc: (cells) => _.sum(cells), name: 'Inspections'},
		{prop: 'num_violations', summaryfunc: (cells) => _.sum(cells), name: 'Violations'},
		{prop: 'num_out_of_service', summaryfunc: (cells) => _.sum(cells), name: 'OOS Inspections'}
	];

	trooperSearch: string;
	activeSortKey: string;
	activeSortDirection: 'asc' | 'desc';

	@ViewChild('inspectionsByLevelChart') inspectionsByLevelContainer: ElementRef;
	inspectionsByLevelView: any;
	inspectionStatSubscription: Subscription;


	inspectionLevelOptionsObject = [
		{"name" : "Level 1", "value" : 1},
		{"name" : "Level 2", "value" : 2},
		{"name" : "Level 3", "value" : 3},
		{"name" : "Level 4", "value" : 4},
		{"name" : "Level 5", "value" : 5},
		{"name" : "Level 6", "value" : 6},
		{"name" : "Level 7", "value" : 7}
	  ];

	  inspectionLevelDropdownSettings = {
		textField: "name",
		idField: "value",
		singleSelection: false,
		itemsShowLimit: 1
	  };



	constructor(insp: InspectionService, commonService: CommonService) {
		super(commonService);
		this.inspectionService = insp;
	}

	onInputChange(event) {
		this.refreshInspectionData();
	}

	ngOnInit() {
		super.ngOnInit();
		this.refreshInspectionData();
	}

	public getFillForField(fieldName: string, value: number) {
		return this.tableFillInterpolators[fieldName](value);
	}

	activateTrooper(trooperRec: any) {
		this.activeTrooperDetail = {
			'violations': _.filter(this.inspectionStats.trooper_violation_detail,
				r => r.inspectorcode == trooperRec.inspectorcode),
			'inspections_by_level': _.filter(this.inspectionStats.trooper_insp_level_detail,
				r => r.inspectorcode == trooperRec.inspectorcode),
			'trooper': trooperRec
		};

		this.curViolationsDetail = this.activeTrooperDetail.violations;
		this.buildInspectionsByLevelView(this.activeTrooperDetail.inspections_by_level);
	}

	buildInspectionsByLevelView(records: any) {
		if ( this.inspectionStats && this.inspectionStats.overall_inspection_counts.num_inspections === 0 ) {
			return;
		}
		var tooltip = new vegaTooltip.Handler();
		vega.loader().load('/assets/vega-specs/inspections-by-level.json')
			.then((data) => {
				let spec = JSON.parse(data);
				this.inspectionsByLevelView = new vega.View(vega.parse(spec))
					.renderer('svg')
					.insert('table', records)
					.initialize('#inspections-by-level-chart')
					.tooltip(tooltip.call)
					.width(Math.max(this.inspectionsByLevelContainer.nativeElement.offsetWidth - 75, 490))
					.height(300)
					.hover()
					.run();

				this.inspectionsByLevelView.addEventListener('click', (event, item) => {
					if( item ) {
						const lvl = item.datum.value || item.datum.insptypelevelcode;
						if(!lvl){
						return;
						}
						const val = {
						value: +lvl,
						name: `Level ${lvl}`
						};
						const valIndex = this.inspectionLevels.findIndex(e => e.value === val.value);
						if(valIndex === -1) {
						console.error(this.inspectionLevels)
						// event is fired twice on each click,
						// second times removes the value added orignally
						setTimeout(() => {
							if(this.inspectionLevels.findIndex(e => e.value === val.value)>-1) return 0;
							if (event.ctrlKey) {
							const levels = [...this.inspectionLevels];
							levels.push(val);
							this.inspectionLevels = levels;
							} else {
							this.inspectionLevels = [val];
							}
						},50);
						} else {
						setTimeout(() => {
							const levels = [...this.inspectionLevels];
							levels.splice(valIndex,1);
							this.inspectionLevels = levels;
						},40);
						}
					}
					});

				// when the container element is resized, resize the Vega view
				const ro = new ResizeObserver(entries => {
					for (let entry of entries) {
						this.inspectionsByLevelView.width((<HTMLElement>entry.target).offsetWidth - 50).runAsync();
					}
				});
				ro.observe(this.inspectionsByLevelContainer.nativeElement);

				return this;
			});
	}

	getSummaryCounts() {
		if ( this.activeTrooperDetail == undefined ) {
			return this.inspectionStats.overall_inspection_counts;
		} else {
			return this.activeTrooperDetail.trooper;
		}
	}

	toggleSort = (key: string) => {
		if ( this.activeSortKey == key ) {
			this.activeSortDirection = this.activeSortDirection == 'asc' ? 'desc' : 'asc';
		} else {
			this.activeSortKey = key;
			this.activeSortDirection = 'asc';
		}

		this.inspectionStats.trooper_detail = _.orderBy(
			this.inspectionStats.trooper_detail, this.activeSortKey, this.activeSortDirection);
	}

	sortDescending = (key: string) => {
		this.activeSortKey = key;
		this.activeSortDirection = 'desc';
		this.inspectionStats.trooper_detail = _.orderBy(
			this.inspectionStats.trooper_detail, this.activeSortKey, this.activeSortDirection);
	}

	trooperFilterMatch = (trooper: any) => {
		if ( !this.trooperSearch ) {
			return true;
		} else {
			return (trooper.inspectorcode && trooper.inspectorcode.toString().toLowerCase().includes(this.trooperSearch.toLowerCase())) ||
        (trooper.inspectorname && trooper.inspectorname.toLowerCase().includes(this.trooperSearch.toLowerCase()));
		}
	}

	clearActiveTrooper() {
		this.activeTrooperDetail = undefined;
		this.curViolationsDetail = this.inspectionStats.top_violation_types;
		this.buildInspectionsByLevelView(this.inspectionStats.inspections_by_level);
	}

	refreshInspectionData() {
		this.hasError = false;
		if ( this.inspectionStatSubscription ) {
			this.inspectionStatSubscription.unsubscribe();
		}

		this.performCommonChangeHandling();
		return this.inspectionStatSubscription = this.inspectionService.getInspectionStats(
					this.selectedRegions.barracks.filter(b => b.type == 'barracks').map(b => b.barracks_id),
					this.timeRange,
					this.selectedVehicleType, this.selectedState,
					_.map(this.selectedRegions.counties, c =>  c.county_fips),
					true,
					_.map(this.inspectionLevels, "value")
				).subscribe((result: any) => {
			result.trooper_detail.forEach(element => {
				element.num_inspections_str = element.num_inspections.toString();
			});
			this.inspectionStats = result;
			this.sortDescending('num_inspections');

			if ( this.activeTrooperDetail != undefined ) {
				let curTrooperInResults = _.find(result.trooper_detail, r => r.inspectorcode == this.activeTrooperDetail.trooper.inspectorcode);
				if ( curTrooperInResults ) {
					this.activateTrooper(this.activeTrooperDetail.trooper);
				} else {
					this.activeTrooperDetail = undefined;
					this.curViolationsDetail = this.inspectionStats.top_violation_types;
					this.buildInspectionsByLevelView(this.inspectionStats.inspections_by_level);
				}
			} else {
				this.curViolationsDetail = this.inspectionStats.top_violation_types;
				this.buildInspectionsByLevelView(this.inspectionStats.inspections_by_level);
			}

			['num_inspections', 'num_out_of_service', 'num_violations'].forEach(n => {
				let rawInterpolator = d3.interpolateRgb(this.tableFillMinColor, this.tableFillMaxColor);
				let vals = _.map(result.trooper_detail, n);
				let valMin = _.min(vals);
				let valMax = _.max(vals);
				if(valMin == valMax) {
					this.tableFillInterpolators[n] = (val: number) => rawInterpolator(0);
				} else {
					this.tableFillInterpolators[n] = (val: number) => rawInterpolator((val - valMin) / (valMax - valMin));
				}
			}, this);
		}, err => {
			this.handleError(err);
		});
	}

	resetFilter() {
		this.clearActiveTrooper();
		this.resetSelectionValues();
		this.refreshInspectionData();
	}

}
