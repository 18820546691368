import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../common/common.component';
import { CommonService } from '../common/common.service';
import { CrashService } from './crash.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { delay, share } from 'rxjs/internal/operators';

declare var vega: any;

@Component({
	selector: 'app-crash-trends',
	templateUrl: './crash-trends.component.html',
	styleUrls: ['./crash-trends.component.css']
})
export class CrashTrendsComponent extends BaseComponent implements OnInit {

	@ViewChild('allCMVChart') allCMVChart: ElementRef;
	allCMVChartView: any;
	allCMVCrashViewPromise: Promise<any>;

	@ViewChild('truckCMVChart') truckCMVChart: ElementRef;
	truckCMVChartView: any;
	truckCMVCrashViewPromise: Promise<any>;

	@ViewChild('busCMVChart') busCMVChart: ElementRef;
	busCMVChartView: any;
	busCMVCrashViewPromise: Promise<any>;

	@ViewChild('hazmatCMVChart') hazmatCMVChart: ElementRef;
	hazmatCMVChartView: any;
	hazmatCMVCrashViewPromise: Promise<any>;
	crashCounts: number;
	crashStats: any;
	lastCrashDate: Date;

	injuryDropdownSettings = {
		singleSelection: false,
		itemsShowLimit: 1,
	};

	constructor(commonService: CommonService, private crashService: CrashService) {
		super(commonService);
	}

	ngOnInit() {
		this.selectedInjuryStatus = [];
		super.ngOnInit();

		this.commonService.getCrashDateRange().subscribe((data: any) => {
			this.lastCrashDate = moment(data.last_crash_date).toDate();
			this.refreshCrashData();
		}, err => {
			this.hasError = true;
		});


		this.allCMVCrashViewPromise = this.setupVegaView('/assets/vega-specs/crashes-by-quarter.json', this.allCMVChart, 'allCMVChartView');
		this.truckCMVCrashViewPromise = this.setupVegaView('/assets/vega-specs/crashes-by-quarter.json', this.truckCMVChart, 'truckCMVChartView');
		this.busCMVCrashViewPromise = this.setupVegaView('/assets/vega-specs/crashes-by-quarter.json', this.busCMVChart, 'busCMVChartView');
		this.hazmatCMVCrashViewPromise = this.setupVegaView('/assets/vega-specs/crashes-by-quarter.json', this.hazmatCMVChart, 'hazmatCMVChartView');
	}

	getPostParameters() {
		const lastQuarter = this.getFiscalQuarterForDate(this.lastCrashDate);
		const endDateFilter = moment(this.getFiscalQuarterStartDate(lastQuarter)).subtract(1, 'day');
		const startDateFilter = moment(this.getFiscalQuarterStartDate(
			this.getFiscalQuarterForDate(endDateFilter.clone().subtract(3, 'y').toDate())));
		return {
			state_filter : this.selectedState,
			start_date_filter: startDateFilter.format('YYYY-MM-DD'),
			end_date_filter: endDateFilter.format('YYYY-MM-DD'),
			vehicle_type: this.selectedVehicleType,
			injury_status_filter: this.selectedInjuryStatus,
			barracks: _.filter(this.selectedRegions.barracks, b => b.type == 'barracks').map(b => b.barracks_id),
			counties: _.map(this.selectedRegions.counties, c => c.county_fips)
		};
	}

	onInputChange($event, forceUpdateField?) {
		if(forceUpdateField) {
		  this[forceUpdateField] = $event;
		}

		if ( !$event ) {
		  this.syncFilterBarCache(true);
			this.processData(this.crashStats);
		} else {
			this.refreshCrashData();
		}
	  }

	refreshCrashData() {
		this.performCommonChangeHandling();

		const postParams = this.getPostParameters();
		this.crashService.getCrashStats(postParams)
			.pipe(delay(900))
			.subscribe(data => {
				this.processData(data);
				setTimeout(() => {
					this.processData(data);
				}, 50);
			}, err => {
				this.handleError(err);
			});
	}

	processData(data) {
		this.crashStats = data;
		this.crashCounts = 0;
		_.each(this.crashStats.crashes_by_date, c => {
			const momentDate = moment(c.crashdate);
			if ( this.selectedYearType === 'Fiscal' ) {
				c.quarter = this.getFiscalQuarterForDate(momentDate.toDate());
				c.year_label = 'FY' + c.quarter.toString().substring(2, 4);
				c.quarter_label = 'Q' + c.quarter[1];
				c.year_quarter_label = c.year_label + ' ' + c.quarter_label;
				c.quarter_start = this.getFiscalQuarterStartDate(c.quarter);
			} else {
				c.quarter = [momentDate.year(), momentDate.quarter()];
				c.year_label = momentDate.year();
				c.quarter_label = 'Q' + c.quarter[1];
				c.year_quarter_label = c.year_label + ' ' + c.quarter_label;
				c.quarter_start = momentDate.startOf('quarter').toDate();
			}
			if ( this.crashCounts < c.num_crashes ) {
				this.crashCounts = c.num_crashes;
			}
		});

		this.crashStats.crashes_by_quarter = _.map(_.groupBy(this.crashStats.crashes_by_date, 'quarter'), (v, k) => {
			return {
				fiscal_quarter_start: v[0].quarter_start,
				fiscal_quarter_label: v[0].quarter_label,
				fiscal_year_label: v[0].year_label,
				fiscal_year_quarter_label: v[0].year_quarter_label,
				num_crashes: _.reduce(v, (acc, cur) => acc + cur.num_crashes, 0),
				num_truck_crashes: _.reduce(v, (acc, cur) => acc + cur.num_truck_crashes, 0),
				num_bus_crashes: _.reduce(v, (acc, cur) => acc + cur.num_bus_crashes, 0),
				num_hazmat_crashes: _.reduce(v, (acc, cur) => acc + cur.num_hazmat_crashes, 0)
			};
		});



		const calcDomain = function (crashesByQuarter): any {
			let max = _.max(_.map(crashesByQuarter, 'num_crashes')) * 1.1;
			// Add extra padding to compensate an icon of 34px
			max = max + max * 0.0864;
			return [0, Math.max(max, 1)];
		};

		const allCrashDomain: any = calcDomain(this.crashStats.crashes_by_quarter);

		this.allCMVCrashViewPromise.then(function (outerThis: CrashTrendsComponent) {
			outerThis.allCMVChartView.signal('ylabel', 'All CMV Crashes');
			const changeset = vega.changeset().remove(() => true).insert(outerThis.crashStats.crashes_by_quarter);
			outerThis.allCMVChartView.change('table', changeset).run();
			const changeset2 = vega.changeset().remove(() => true).insert(outerThis.crashStats.crashes_by_quarter);
			outerThis.allCMVChartView.change('table', changeset2).run();
			outerThis.allCMVChartView.signal('num_crash_domain', allCrashDomain);
		}, (err) => {
			this.handleError(err);
		});

		const barColors = {
			truck: '#881c1c',
			bus: 'rgb(28, 68, 28)',
			hazmat: 'rgb(28, 28, 68)'
		};

		['truck', 'bus', 'hazmat'].forEach(f => {
			this.crashStats[f + '_crashes_by_quarter'] = _.map(this.crashStats.crashes_by_quarter, v => {
				const v2 = _.clone(v);
				v2.num_crashes = v['num_' + f + '_crashes'];
				return v2;
			});

			this[f + 'CMVCrashViewPromise'].then((outerThis: CrashTrendsComponent) => {
				outerThis[f + 'CMVChartView'].signal('barcolor', barColors[f]);
				const ylabel = f == "hazmat" ? "HazMat Crashes" : _.startCase(f) + ' Crashes';
				outerThis[f + 'CMVChartView'].signal('ylabel', ylabel);
				const curCrashStats = _.orderBy(outerThis.crashStats[f + '_crashes_by_quarter'], "fiscal_quarter_start");
				// binding all CMV y-scale and truck y-scale
				const curCrashDomain: any = f == 'truck' ? allCrashDomain : calcDomain(curCrashStats);
				const changeset = vega.changeset().remove(() => true).insert(curCrashStats);
				outerThis[f + 'CMVChartView'].change('table', changeset).run();

				const changeset2 = vega.changeset().remove(() => true).insert(curCrashStats);
				outerThis[f + 'CMVChartView'].change('table', changeset2).run();
				outerThis[f + 'CMVChartView'].signal('num_crash_domain', curCrashDomain);
			}, (err) => {
				this.handleError(err);
			});
		});

	}


	resetFilter() {
		this.resetSelectionValues();
		this.refreshCrashData();
	}

}
