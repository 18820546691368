import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService, IUserInfo } from '../auth/auth.service';
import * as _ from 'lodash';
import { BaseComponent } from '../common/common.component';
import { CommonService } from '../common/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.css']
})
export class UsersComponent extends BaseComponent implements OnInit {

	users: IUserInfo[];
	authService: AuthService;
	hasError = false;
	@ViewChild('userEditModal') userEditModal: ElementRef;

	userForm: FormGroup;
	editingNewUser: boolean;
	showPasswordReset: boolean;

	constructor(authService: AuthService,
				commonService: CommonService,
				public formBuilder: FormBuilder,
				private modalService: NgbModal) {
		super(commonService);
		this.authService = authService;
	}

	ngOnInit() {
		this.authService.getUsers().subscribe((data) => {
			this.users = data;
		});
	}

	editUser(user: IUserInfo, isNew: boolean = false, showPasswordReset: boolean = false) {
		this.userForm = this.formBuilder.group({
			username: [user.username, [Validators.required, Validators.minLength(3)]],
			is_sys_admin: [user.is_sys_admin],
			has_risk_explorer_access: [user.has_risk_explorer_access],
			email: [user.email, [Validators.required, Validators.email]],
		});
		if ( showPasswordReset ) {
			this.userForm.addControl('password', new FormControl(user.password, [Validators.required, Validators.minLength(8)]));
			this.userForm.addControl('confirmPassword', new FormControl(user.confirmPassword, [Validators.required, Validators.minLength(8)]));
			this.userForm.setValidators(this.comparePasswords);
		}
		if ( !isNew ) {
			this.userForm.addControl('user_id', new FormControl(user.user_id, [Validators.required, Validators.minLength(8)]));
		}

		this.editingNewUser = isNew;
		this.showPasswordReset = isNew || showPasswordReset;
		const origUser = _.cloneDeep(this.userForm.value);
		this.modalService.open(this.userEditModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
		}, (reason) => {
			_.each(origUser, (v, k) => {
				user[k] = v;
			});
		});
	}

	saveUser() {
		if ( this.userForm.valid ) {
			this.modalService.dismissAll();
			this.hasError = false;
			this.authService.saveUser(this.userForm.value).subscribe((data: IUserInfo) => {
				if ( this.userForm.value.user_id ) {
					const usersIndex = this.users.findIndex((u) => u.user_id === this.userForm.value.user_id);
					this.users[usersIndex] = data;
				} else {
					this.users.push(data);
				}
			}, err => {
				this.handleError(err);
			});
		} else {
			Object.keys(this.userForm.controls).forEach((c) => {
				this.userForm.get(c).markAsTouched();
			});
		}

	}

	addUser() {
		this.editUser({username: '', is_sys_admin: false, has_risk_explorer_access: false, email: '', password: '', confirmPassword: ''}, true, true);
	}

	deleteUser(user: IUserInfo) {
		this.hasError = false;
		this.authService.deleteUser(user).subscribe(data => {
			this.users = _.without(this.users, user);
		}, err => {
			this.handleError(err);
		});
	}

	comparePasswords(group: FormGroup) { // here we have the 'passwords' group
		if ( group.controls.password ) {
			const pass = group.controls.password.value;
			const confirmPass = group.controls.confirmPassword.value;
			return pass === confirmPass ? null : {mismatch: true};
		}

	}
}

