import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDropdownSettings, ListItem } from './multiselect.model';

export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent),
    multi: true
};
const noop = () => {
};

@Component({
    selector: 'ng-multiselect-dropdown',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
    providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectComponent implements ControlValueAccessor {
    public _settings: IDropdownSettings;
    public _data: Array<ListItem> = [];
    public selectedItems: Array<ListItem> = [];
    public isDropdownOpen = true;
    _placeholder = 'Select';
    filter: ListItem = new ListItem(this.data);
    defaultSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'text',
        enableCheckAll: true,
        selectAllText: 'Select All',
        unSelectAllText: 'Deselect All',
        allowSearchFilter: false,
        limitSelection: -1,
        clearSearchFilter: true,
        maxHeight: 197,
        itemsShowLimit: 999999999999,
        searchPlaceholderText: 'Search',
        noDataAvailablePlaceholderText: 'No data available',
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
        defaultOpen: false
    };

    @Input()
    public set placeholder(value: string) {
        if ( value ) {
            this._placeholder = value;
        } else {
            this._placeholder = 'Select';
        }
    }

    @Input()
    disabled = false;

    @Input()
    public set settings(value: IDropdownSettings) {
        if ( value ) {
            this._settings = Object.assign(this.defaultSettings, value);
        } else {
            this._settings = Object.assign(this.defaultSettings);
        }
    }

    @Input()
    public set data(value: Array<any>) {
        if ( !value ) {
            this._data = [];
        } else {
            // const _items = value.filter((item: any) => {
            //   if (typeof item === 'string' || (typeof item === 'object' && item && item[this._settings.idField] && item[this._settings.textField])) {
            //     return item;
            //   }
            // });
            this._data = value.map(
                (item: any) =>
                    typeof item === 'string'
                        ? new ListItem(item)
                        : new ListItem({
                            id: item[this._settings.idField],
                            text: item[this._settings.textField],
                            indent: item.indent
                        })
            );
        }
    }

    @Output('onFilterChange')
    onFilterChange: EventEmitter<ListItem> = new EventEmitter<any>();
    @Output('onDropDownClose')
    onDropDownClose: EventEmitter<ListItem> = new EventEmitter<any>();

    @Output('onSelect')
    onSelect: EventEmitter<ListItem> = new EventEmitter<any>();

    @Output('onDeSelect')
    onDeSelect: EventEmitter<ListItem> = new EventEmitter<any>();

    @Output('onSelectAll')
    onSelectAll: EventEmitter<Array<ListItem>> = new EventEmitter<Array<any>>();

    @Output('onDeSelectAll')
    onDeSelectAll: EventEmitter<Array<ListItem>> = new EventEmitter<Array<any>>();

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    onFilterTextChange($event) {
        this.onFilterChange.emit($event);
    }

    constructor(private cdr: ChangeDetectorRef) {
    }

    onItemClick($event: any, item: ListItem) {
        if ( this.disabled ) {
            return false;
        }

        const found = this.isSelected(item);
        const allowAdd =
            this._settings.limitSelection === -1 ||
            (this._settings.limitSelection > 0 &&
                this.selectedItems.length < this._settings.limitSelection);
        if ( !found ) {
            if ( allowAdd ) {
                this.addSelected(item);
            }
        } else {
            this.removeSelected(item);
        }
        if (
            this._settings.singleSelection &&
            this._settings.closeDropDownOnSelection
        ) {
            this.closeDropdown();
        }
    }

    writeValue(value: any) {
        if ( value !== undefined && value !== null && value.length > 0 ) {
            if ( this._settings.singleSelection ) {
                try {
                    if ( value.length >= 1 ) {
                        const firstItem = value[0];
                        this.selectedItems = [
                            typeof firstItem === 'string'
                                ? new ListItem(firstItem)
                                : new ListItem({
                                    id: firstItem[this._settings.idField],
                                    text: firstItem[this._settings.textField]
                                })
                        ];
                    }
                } catch (e) {
                    // console.error(e.body.msg);
                }
            } else {
                const _data = value.map(
                    (item: any) =>
                        typeof item === 'string'
                            ? new ListItem(item)
                            : new ListItem({
                                id: item[this._settings.idField],
                                text: item[this._settings.textField]
                            })
                );
                if ( this._settings.limitSelection > 0 ) {
                    this.selectedItems = _data.splice(0, this._settings.limitSelection);
                } else {
                    this.selectedItems = _data;
                }
            }
        } else {
            this.selectedItems = [];
        }
        this.onChangeCallback(value);
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    // Set touched on blur
    @HostListener('blur')
    public onTouched() {
        this.closeDropdown();
        this.onTouchedCallback();
    }

    trackByFn(index, item) {
        return item.id;
    }

    isSelected(clickedItem: ListItem) {
        let found = false;
        this.selectedItems.forEach(item => {
            if ( clickedItem.id === item.id ) {
                found = true;
            }
        });
        return found;
    }

    isLimitSelectionReached(): boolean {
        return this._settings.limitSelection === this.selectedItems.length;
    }

    isAllItemsSelected(): boolean {
        return this._data.length === this.selectedItems.length;
    }

    isAnyItemSelected(): boolean {
        return this._data.length > 0;
    }

    showButton(): boolean {
        if ( !this._settings.singleSelection ) {
            if ( this._settings.limitSelection > 0 ) {
                return false;
            }
            // this._settings.enableCheckAll = this._settings.limitSelection === -1 ? true : false;
            return true; // !this._settings.singleSelection && this._settings.enableCheckAll && this._data.length > 0;
        } else {
            // should be disabled in single selection mode
            return false;
        }
    }

    itemShowRemaining(): number {
        return this.selectedItems.length - this._settings.itemsShowLimit;
    }

    addSelected(item: ListItem) {
        if ( this._settings.singleSelection ) {
            this.selectedItems = [];
            this.selectedItems.push(item);
        } else {
            this.selectedItems.push(item);
        }
        this.onChangeCallback(this.emittedValue(this.selectedItems));
        this.onSelect.emit(this.emittedValue(item));
    }

    removeSelected(itemSel: ListItem) {
        this.selectedItems.forEach(item => {
            if ( itemSel.id === item.id ) {
                this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
            }
        });
        this.onChangeCallback(this.emittedValue(this.selectedItems));
        this.onDeSelect.emit(this.emittedValue(itemSel));
    }

    emittedValue(val: any): any {
        const selected = [];
        if ( Array.isArray(val) ) {
            val.map(item => {
                if ( item.id === item.text ) {
                    selected.push(item.text);
                } else {
                    selected.push(this.objectify(item));
                }
            });
        } else {
            if ( val ) {
                if ( val.id === val.text ) {
                    return val.text;
                } else {
                    return this.objectify(val);
                }
            }
        }
        return selected;
    }

    objectify(val: ListItem) {
        const obj = {};
        obj[this._settings.idField] = val.id;
        obj[this._settings.textField] = val.text;
        return obj;
    }

    toggleDropdown(evt) {
        evt.preventDefault();
        if ( this.disabled && this._settings.singleSelection ) {
            return;
        }
        this._settings.defaultOpen = !this._settings.defaultOpen;
        if ( !this._settings.defaultOpen ) {
            this.onDropDownClose.emit();
        }
    }

    closeDropdown() {
        this._settings.defaultOpen = false;
        // clear search text
        if ( this._settings.clearSearchFilter ) {
            this.filter.text = '';
        }
        this.onDropDownClose.emit();
    }

    selectAll() {
        this.selectedItems = this._data.slice();
        this.onSelectAll.emit(this.emittedValue(this.selectedItems));
        this.onChangeCallback(this.emittedValue(this.selectedItems));
    }

    deselectAll() {
        this.selectedItems = [];
        this.onDeSelectAll.emit(this.emittedValue(this.selectedItems));
        this.onChangeCallback(this.emittedValue(this.selectedItems));
    }

    toggleSelectAll(clearAll?) {
        if ( this.disabled ) {
            return false;
        }
        if ( !this.isAllItemsSelected() ) {
            if( clearAll ){
                console.log(clearAll);
                this.selectedItems = [];
                this.onDeSelectAll.emit(this.emittedValue(this.selectedItems));
            } else {
                this.selectedItems = this._data.slice();
                this.onSelectAll.emit(this.emittedValue(this.selectedItems));
            }
        } else {
            console.log('all cleared');
            this.selectedItems = [];
            this.onDeSelectAll.emit(this.emittedValue(this.selectedItems));
        }
        this.onChangeCallback(this.emittedValue(this.selectedItems));
    }
}
