import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from './../../environments/environment';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {latLng, LatLngExpression} from "leaflet";
import * as polylabel from "polylabel";

export interface IMonthInfo {
  name: string;
  nameAbbrev: string;
  number: number;
  fiscalNumber: number;
  zbNumber: number;
}

export interface ITroopInfo {
  name: string;
}

export interface IBarracksInfo {
  barracks_id: number;
  name: string;
  type?: string;
  troop_name?: string;
  state: string,
  indent?: boolean;
  barracks?: IBarracksInfo[];
  selected?: boolean;
  stateOptions
  selectedBarracks?: IBarracksInfo[];
}

export interface ICountyInfo {
  county_name: string;
  state_abbrev: string;
  county_fips: number;
}

export interface IJurisdictionInfo {
  type: 'barracks' | 'counties';
  barracks: IBarracksInfo[];
  counties: ICountyInfo[];
}

export interface IDataFreshnessInfo {
  "first_crash_date": string,
  "first_inspection_date": string,
  "last_crash_date": string,
  "last_import_time": string,
  "last_inspection_date": string,
  "state_filter": string
}

export interface IYearInfo {
  name: number;
  selectedQuarters: number[];
}

export interface ITimeRangeInfo {
  fiscalYear: boolean;
  years: Array<IYearInfo>;
}

export interface IJurisdictionKeysInfo {
  regionType: 'barracks'|'counties'|'cities';
  idKey: 'barrack' | 'FIPS' | 'TOWN_ID';
  nameKey: 'barracks_name' | 'NAME' | 'TOWN';
  inspectionKey?: 'barracks_id' | 'county_fips' | 'map_town_id';
  crashKey?: 'barracks_id' | 'crashfipscountycode' | 'city_town_code';
}


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  httpClient: HttpClient;
  dataFreshnessInfoSubject = new BehaviorSubject<IDataFreshnessInfo>(null);
  private _stateSettings: any;
  private _selectedState: any;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.setStateSettings();
  }

  getCounties() {
    return this.httpClient.get<Array<ICountyInfo>>(environment.apiUrl + "/counties");
  }

  getTroops() {
    return this.httpClient.get<Array<ITroopInfo>>(environment.apiUrl + '/troops');
  }

  getTroopForBarracks(troop: IBarracksInfo) {
    return {'display': troop.troop_name, 'name': troop.troop_name, 'state': troop.state};
  }

  getBarracks() {
    return this.httpClient.get<Array<IBarracksInfo>>(environment.apiUrl + '/barracks').pipe(map((val: any) => {
      let troops = _.uniqBy(_.map(val, this.getTroopForBarracks), r => r.state + "_" + r.name);
      let combined = [];
      val.forEach(element => {
        combined.push({
          'name': element.barracks_name,
          'type': 'barracks',
          'troop': this.getTroopForBarracks(element).name,
          'state': element.state,
          'barracks_id': element.barracks_id
        });
      });
      var stateTroopIndexedBarracks = _.groupBy(combined, r => r.state + "_" + r.troop);
      troops.forEach(element => {
        combined.push({
          'name': element.display, 'type': 'troop', 'troop': element.name,
          'barracks': stateTroopIndexedBarracks[element.state + "_" + element.name],
          'selectedBarracks': [],
          'state': element.state
        });
      });
      return {
        'all': _.sortBy(combined, v => [v.troop, v.type == 'troop' ? '' : v.name]),
        'troops': _.filter(combined, t => t.type == 'troop')
      };
    }));
  }

  getAvailableYears(): Observable<number[]> {
    return this.getInspectionDateRange().pipe(map((data: any) => {
      return _.range(parseInt(data.first_inspection_date.split('-')[0]),
        parseInt(data.last_inspection_date.split('-')[0]) + 1);
    }));
  }

  getDataFreshnessInfo(state: string) {
    if (!this.dataFreshnessInfoSubject.value || this.dataFreshnessInfoSubject.value.state_filter != state) {
      this.httpClient.post(environment.apiUrl + '/data-freshness-info', {"state": state}).subscribe((res: IDataFreshnessInfo) => {
        this.dataFreshnessInfoSubject.next(res);
      });
    }
    return this.dataFreshnessInfoSubject;
  }

  getInspectionDateRange() {
    return this.httpClient.get(environment.apiUrl + '/inspection-date-range');
  }

  getCrashDateRange() {
    return this.httpClient.get(environment.apiUrl + '/crash-date-range');
  }

  getRegionsGeoJson(stateAbbrev: string, region: string = 'barracks') {
    return this.httpClient.get('/assets/vega-specs/data/' + region.toLowerCase() + '-' + stateAbbrev.toLowerCase() + '.geojson');
  }

  getCitiesGeoJson(stateAbbrev: string) {
    return this.httpClient.get('/assets/vega-specs/data/cities-' + stateAbbrev.toLowerCase() + '.geojson');
  }

  getCrashModalTilesGeoJson() {
    return this.httpClient.get('/assets/geojson/crash_model_tiles.geojson');
  }

  getCrashModelSubtilesGeoJson() {
    return this.httpClient.get('/assets/geojson/crash_model_subtiles.geojson');
  }

  get stateSettings() {
    return this._stateSettings
  }

  set selectedState(state) {
    this._selectedState = state;
  }

  getBarracksLabel() {
    return this.stateSettings[this._selectedState].barracksLabel;
  }

  getJurisdictionKeys(regionType): IJurisdictionKeysInfo {
    let idKey, nameKey, inspectionKey, crashKey;
    switch (regionType) {
      case 'barracks':
        idKey = 'barrack';
        nameKey = 'barracks_name';
        inspectionKey = 'barracks_id';
        crashKey = 'barracks_id';
        break;
      case 'cities':
        idKey = 'TOWN_ID';
        nameKey = 'TOWN';
        inspectionKey = 'map_town_id';
        crashKey = 'city_town_code';

        break;
      case 'counties':
        idKey = 'FIPS';
        nameKey = 'NAME';
        inspectionKey = 'county_fips';
        crashKey = 'crashfipscountycode';
        break;
    }
    return {idKey, nameKey, regionType, inspectionKey, crashKey};
  }

  getMinMax(data, key): {dataByCount: any, min: number, max: number} {
    if(data == undefined || data.length == 0) return {} as any;
    let dataByCount = data.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    let min;
    let max;
    // handle crash:overall data types
    if(data[0].num_crashes){
      const sortedData = _.orderBy(data, "num_crashes");
      min = sortedData[0].num_crashes;
      max = sortedData[sortedData.length - 1].num_crashes;
      Object.keys(dataByCount).forEach((e) => {
        dataByCount[e] = dataByCount[e].concat(Array(dataByCount[e][0].num_crashes - 1));
      });
    } else {
      Object.keys(dataByCount).forEach((b) => {
        if (!min) {
          min = dataByCount[b].length;
          max = dataByCount[b].length;
        }
        if (min > dataByCount[b].length) {
          min = dataByCount[b].length;
        }
        if (max < dataByCount[b].length) {
          max = dataByCount[b].length;
        }
      });
    }

    if (min === max) {
      min = 0;
      max = max ? max : 100;
    }
    return {
      dataByCount,
      min,
      max
    };
  }


  mapClickFilter(e, selectedRegions: IJurisdictionInfo, indexedBarracksOptions): IJurisdictionInfo {
      let counties = selectedRegions.counties;
      let barracks = selectedRegions.barracks;
      if (selectedRegions.type !== 'barracks') {
        const selectedCounty = {
          county_name: e.sourceTarget.feature.properties.NAME,
          county_fips: +e.sourceTarget.feature.properties.FIPS,
          state_abbrev: e.sourceTarget.feature.properties.state_abbrev
        };
        const countyIndex = counties.findIndex(c => c.county_fips == selectedCounty.county_fips);
        if (countyIndex > -1) {
          counties.splice(countyIndex, 1);
        } else if (e.originalEvent.ctrlKey) {
            counties.push(selectedCounty);
        } else {
            counties = [selectedCounty];
        }
      } else {
        const selectedBarracksId = e.sourceTarget.feature.properties.barrack;
        const selectedBarracks = indexedBarracksOptions[selectedBarracksId];
        const barrackIndex = barracks.findIndex(b => b.barracks_id == selectedBarracksId);
        if (barrackIndex > -1) {
          barracks.splice(barrackIndex, 1);
        } else if (e.originalEvent.ctrlKey) {
            barracks.push(selectedBarracks);
          } else {
            barracks = [selectedBarracks];
        }
      }
      return {
        type: selectedRegions.type ,
        counties, barracks
      }
    }


    getPolygonCenter(featurePolygon): LatLngExpression {
      let coordinates = featurePolygon.geometry.coordinates;
      if(Array.isArray(coordinates[0][0][0])) {
        coordinates = coordinates[0];
      }
      const center =  polylabel.default(coordinates, 0.1) as LatLngExpression;
      return {lat: center[1], lng: center[0] };
    }


  setStateSettings() {
    this._stateSettings = {
      "MA": {
        fullName: "Massachusetts",
        barracksLabel: "Barracks",
        troopBarracksLabel: "Troop/Barracks",
        showTroopHierarchy: true,
        supportsTownMapView: true,
        leafletOptions: {
          zoom: 8,
          minZoom: 8,
          center: latLng(42.26657151126584, -71.56451290885582)
        },
        supportsExtendedInspectionFields: true,
        supportsExtendedCrashFields: true,
        supportsLocalStateBreakdown: true
      },
      "WV": {
        fullName: "West Virginia",
        barracksLabel: "District",
        troopBarracksLabel: "Troop/District",
        showTroopHierarchy: true,
        supportsTownMapView: false,
        leafletOptions: {
          zoom: 7,
          minZoom: 6,
          center: latLng(38.6974711, -79.3251278)
        },
        supportsExtendedInspectionFields: false,
        supportsExtendedCrashFields: false,
        supportsLocalStateBreakdown: true
      },
      "MD": {
        fullName: "Maryland",
        barracksLabel: "Barracks",
        troopBarracksLabel: "Barracks",
        showTroopHierarchy: false,
        supportsTownMapView: false,
        leafletOptions: {
          zoom: 7,
          minZoom: 6,
          center: latLng(38.9, -76.9)
        },
        supportsExtendedInspectionFields: false,
        supportsExtendedCrashFields: false,
        supportsLocalStateBreakdown: true
      },
      "VA": {
        fullName: "Virginia",
        barracksLabel: "Division",
        troopBarracksLabel: "Division",
        showTroopHierarchy: false,
        supportsTownMapView: false,
        leafletOptions: {
          zoom: 6,
          minZoom: 6,
          center: latLng(38.0718117, -77.877017)
        },
        supportsExtendedInspectionFields: false,
        supportsExtendedCrashFields: false,
        supportsLocalStateBreakdown: true
      }
    }
  }

}
