import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {IMonthInfo, ITimeRangeInfo, IYearInfo} from '../common.service';
import {TimeRangeFormatterService} from '../time-range-formatter.service';

@Component({
  selector: 'app-time-range-selector',
  templateUrl: './time-range-selector.component.html',
  styleUrls: ['./time-range-selector.component.scss']
})
export class TimeRangeSelectorComponent implements OnChanges {


  _placeholder = 'All';
  selectedItemsDisplay: IDisplayItem[];
  showingDropdown = false;
  quarterArray = [1, 2, 3, 4];
  dropdownListStyle: { [klass: string]: any; } = {right: '0px'};
  years: { name: number, selectedQuarters: number[] }[];
  fiscalYear = false;
  private _selectedYear: number;
  private _yearOptions: number[];

  @Input('itemsShowLimit') itemsShowLimit = 4;

  @Input('showHierarchy') showHierarchy = false;

  @Output('valueChange') valueChange: EventEmitter<ITimeRangeInfo | boolean> = new EventEmitter<ITimeRangeInfo | boolean>();

  @Input('monthOptions') monthOptions: IMonthInfo[] = [];


  @Input('anchor') anchor: string;

  @Input('selectedYear') set selectedYear(value: number) {
    this._selectedYear = value;
    if(!this.years) {
       this.updateValues();
    }
  };

  constructor(private timeRangeFormatter: TimeRangeFormatterService) {

  }


  @Input('yearOptions') set yearOptions(value: number[]) {
    this._yearOptions = value;
    if(this.value.years.length > 0 && this.years && this.years.length !== this._yearOptions.length) {
      this.years = this._yearOptions.map((i) => {
        const selectedYear = this.value.years.find(y => y.name===i);
        return selectedYear ? selectedYear : {
          name: i,
          selectedQuarters: [],
        };
      });
    } else {
      this.updateValues();
    }
  }


  updateValues() {
    if (this._yearOptions && this._yearOptions.length > 0 && this._selectedYear) {
      setTimeout(() => {
        this.years = this._yearOptions.map((i) => {
          return {
            name: i,
            selectedQuarters: this.processSelectedQuarters(this._yearOptions, i),
          };
        });
        const selectedYear = this.years.find(y => y.name === this._selectedYear);
        if (selectedYear && selectedYear.selectedQuarters.length !== 4) {
          const currentDate = new Date();
          if (selectedYear.name === currentDate.getFullYear()) {
            const lastYearIndex = this.years.findIndex(y => y.name === selectedYear.name - 1);
            if (lastYearIndex > -1) {
              for (let i = 4; i > selectedYear.selectedQuarters.length; i--) {
                this.years[lastYearIndex].selectedQuarters.push(i);
              }
            }
          }
        }

        this.propagate();
      }, 100);
    }
  }


  processSelectedQuarters(yearOptions, i) {
    const currentDate = new Date();
    if (this._selectedYear === i) {
      return this.quarterArray.filter((q) => {
        const quarterMonths = this.getQuarterMonths(q, yearOptions, true) as { startMonth: IMonthInfo, endMonth: IMonthInfo };
        return i !== currentDate.getFullYear() || !(quarterMonths.startMonth.zbNumber > currentDate.getMonth() ||
          quarterMonths.endMonth.zbNumber > currentDate.getMonth());
      });
    }
    return [];
  }


  @Input() get value(): ITimeRangeInfo {
    return this.selectedValue;
  }


  set value(val: ITimeRangeInfo) {
    if (val) {
      this.fiscalYear = val.fiscalYear;

      this.years =  (this.years || val.years).map((y) => {
        const valYear = val.years.find(v => v.name === y.name);
        if (valYear) {
          y.selectedQuarters = valYear.selectedQuarters;
        }
        return y;
      });
      this.updateDisplayItems();
    } else if (val === null) {
      this.updateValues();
    }
  }


  private get selectedValue(): ITimeRangeInfo {
    return {
      fiscalYear: this.fiscalYear,
      years: this.years ? this.years.filter((y) => y.selectedQuarters.length > 0) : []
    };
  }


  ngOnChanges() {


    if (this.anchor == 'left') {
      this.dropdownListStyle = {left: '20px'};
    } else {
      this.dropdownListStyle = {right: '0px'};
    }
  }


  closeDropdown() {
    this.showingDropdown = false;
  }

  onItemClick(item: IDisplayItem) {
    const year = this.years.find((y) => y.name === item.year);
    if (item.quarter) {
      this.toggleQuarter(year, item.quarter);
    } else {
      this.toggleYear(year);
    }
  }

  /**
   *
   *
   * @param sqc Selected Quarters Count
   */
  getIcon(sqc: number): any {
    return sqc > 0 ? (sqc === 4 ? ['far', 'check-square'] : ['far', 'minus-square']) : ['far', 'square'];
  }

  toggleDropdown() {
    this.showingDropdown = !this.showingDropdown;
  }

  toggleQuarter(year: IYearInfo, quarter: number) {
    const quarterIndex = year.selectedQuarters.indexOf(quarter);
    if (quarterIndex > -1) {
      year.selectedQuarters.splice(quarterIndex, 1);
    } else {
      year.selectedQuarters.push(quarter);
    }
    this.propagate();
  }

  toggleCalendar() {
    this.fiscalYear = !this.fiscalYear;
    this.propagate();
  }

  selectAll() {
    this.years.forEach((y) => {
      y.selectedQuarters = [...this.quarterArray];
    });
    // this.selectedValue.years = this.years;
    this.propagate();

  }

  deselectAll() {
    this.years.forEach((y) => {
      y.selectedQuarters = [];
    });
    // this.selectedValue.years = [];
    this.propagate();

  }

  getQuarterMonths(quarter: number, value?, asObj?) {
    value = value || this.value;
    const monthKey = value && value.fiscalYear ? 'fiscalNumber' : 'number';
    const endMonth = this.monthOptions.find((m) => m[monthKey] === (quarter * 3));
    if (endMonth) {
      const startMonth = this.monthOptions.find((m) => m[monthKey] === (endMonth[monthKey] - 2));
      if (asObj) {
        return {startMonth, endMonth};
      }
      return startMonth.nameAbbrev + '-' + endMonth.nameAbbrev;
    }
  }


  updateDisplayItems() {
    this.selectedItemsDisplay = this.timeRangeFormatter.getSelectedItemsDisplay(this.selectedValue);
  }

  toggleYear(year: IYearInfo) {
    year.selectedQuarters = this.quarterArray.filter(q => year.selectedQuarters.indexOf(q) === -1);
    this.propagate();
  }

  itemShowRemaining(selectedItemsLength): number {
    return selectedItemsLength - this.itemsShowLimit;
  }

  propagate() {
    this.valueChange.emit(this.selectedValue.years.length > 0 ? this.selectedValue : false);
    this.updateDisplayItems();
  }
}


export interface IDisplayItem {
  name: string;
  year: number;
  quarter: number;
}
