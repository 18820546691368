import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/common.component';
import { CommonService } from '../../common/common.service';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent extends BaseComponent implements OnInit {
	resetForm: FormGroup;
	resetToken: string;
	formSubmitted: any;
	formSubmitting: any;

	constructor(commonService: CommonService,
				public authService: AuthService,
				private router: Router,
				private activatedRoute: ActivatedRoute,
				formBuilder: FormBuilder) {
		super(commonService);
		this.resetForm = formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			user: ['', Validators.required],
			password: ['', [Validators.required, Validators.minLength(8)]],
			confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
			reset_token: [],
		});

		activatedRoute.params.subscribe(params => {
			if ( params.user ) {
				this.resetForm.controls.user.setValue(params.user);
				this.resetForm.controls.reset_token.setValue(params.token);
				this.resetForm.controls.email.disable();
				this.resetForm.setValidators(this.comparePasswords);
				this.resetToken = params.token;
			} else {
				this.resetForm.controls.reset_token.disable();
				this.resetForm.controls.user.disable();
				this.resetForm.controls.password.disable();
				this.resetForm.controls.confirmPassword.disable();
			}
		});
	}

	ngOnInit() {
	}

	submitForm() {
		if ( this.resetForm.valid ) {
			this.formSubmitting = true;
			this.authService.resetPassword(this.resetForm.value).subscribe((data) => {
				this.formSubmitted = true;
				this.formSubmitting = false;
				this.hasError = false;
				if ( this.resetToken ) {
					setTimeout(() => {
						this.router.navigate(['/login']);
					}, 4000);
				}
			}, error => {
				this.formSubmitting = false;
				this.handleError(error);
			});
		} else {
			Object.keys(this.resetForm.controls).forEach((c) => {
				this.resetForm.get(c).markAsTouched();
			});
		}
	}

	comparePasswords(group: FormGroup) { // here we have the 'passwords' group
		const pass = group.controls.password.value;
		const confirmPass = group.controls.confirmPassword.value;
		return pass === confirmPass ? null : {mismatch: true};
	}
}
