import {Component, NgZone, OnInit} from '@angular/core';
import {CommonService, IJurisdictionInfo} from '../common/common.service';
import {CrashService} from '../crash/crash.service';
import {latLng, Map} from 'leaflet';
import {BaseComponent} from '../common/common.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {ICarrier, ICrash, ICrashFilterOptions, IRoute} from './crash-mapping-data';
import {CrashMappingMapService} from './crash-mapping-map.service';
import {DownloadDataService} from './crash-mapping-datatable/download-data.service';
import * as moment from 'moment';

declare var vega: any;


@Component({
  selector: 'app-crash-mapping',
  templateUrl: './crash-mapping.component.html',
  styleUrls: ['./crash-mapping.component.css']
})


export class CrashMappingComponent extends BaseComponent implements OnInit {
  filterForm: FormGroup;
  formValues: any;
  crashes: ICrash[];
  crashesForMapping: ICrash[];
  crashesBkp: ICrash[];
  leafletLayers: any[] = [];
  leafletHeatMapLayers = [];
  dataRequest: Subscription;
  barracksGeoJsonSubscription: Subscription;
  lastReportStateValue: any;
  lastCrashYearValue: any;
  lastCrashDate: any;
  bodyCrashData: any;
  truckRoutes: IRoute[];
  carriers: ICarrier[];
  // Variable to control filtering requests to backend if filteration can be done on frontend
  shouldFetch = true;
  vehicleTypeModel = 'All CMVs';
  enableHeatMap = false;
  barracksGeoJson: any;
  map: Map;
  heatmap: Map;
  leafletOptions: any;
  leafletHeatMapOptions: any;
  leafletOptionsCenter: any;
  leafletHeatMapOptionsCenter: any;
  leafletOptionsZoom: any;
  leafletHeatMapOptionsZoom: any;

  yearsOptionsObject: {
    id: number,
    text: string
  }[];
  dropdownSettings = {
    singleSelection: false,

    itemsShowLimit: 1,
  };
  yearDropdownSettings = {
    singleSelection: false,
    itemsShowLimit: 3
  };

  monthDropdownSettings = {
    idField: 'number',
    textField: 'name',
    itemsShowLimit: 1,
    allowSearchFilter: false
  };
  carriersDropdownSettings = {
    idField: 'carriername',
    textField: 'carriername',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  highlightsDropdownSettings = {
    itemsShowLimit: 1,
    allowSearchFilter: false
  };
  truckRouteDropdownSettings = {
    idField: 'streetname',
    textField: 'text',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  crashSeverityDropdownSettings = {
    idField: 'crash_severity_code',
    textField: 'crash_severity_descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  trafficWayDropdownSettings = {
    idField: 'trafy_descr_code',
    textField: 'trafy_descr_descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  mannerCollisionDropdownSettings = {
    idField: 'manr_coll_code',
    textField: 'manr_coll_descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  weatherCollisionDropdownSettings = {
    idField: 'weath_cond_code',
    textField: 'weath_cond_descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  cmvDriverDropdownSettings = {
    idField: 'drvr_cntrb_circ_code',
    textField: 'drvr_cntrb_circ_descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  liscenceRestrDropdownSettings = {
    idField: 'alars_value',
    textField: 'lics_restr_descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  interstateFlagDropdownSettings = {
    idField: 'interstate',
    textField: 'descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };

  vehiclegvwrsDropdownSettings = {
    idField: 'gross_vehc_wght_ratg_code',
    textField: 'gross_vehc_wght_ratg_descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  bodyTypesDropdownSettings = {
    idField: 'cargo_body_type_code',
    textField: 'description',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  ageDropdownSettings = {
    idField: 'cmv_driver_age',
    textField: 'cmv_driver_age',
  }

  highlightsObject = [
    {
      text: 'Non-Motorist',
      id: 'non_motorist_related',
      common: false
    },
    {
      text: 'Younger Driver',
      id: 'young_driver_related',
      common: true
    },
    {
      text: 'Older Driver',
      id: 'old_driver_related',
      common: true
    },
    {
      text: 'Rollover',
      id: 'rollover',
      common: false
    },
    {
      text: 'HazMat',
      id: 'num_hazmat',
      common: true
    },
    {
      text: 'Work Zone',
      id: 'workzone_related',
      common: false
    },
    {
      text: 'Distraction',
      id: 'distraction_related',
      common: false
    },
    {
      text: 'Alcohol/Drugs',
      id: 'alc_drugs_related',
      common: false
    },
    {
      text: 'Multi-CMV',
      id: 'multi_cmv',
      common: true
    },
    {
      text: 'Resulting Inspection',
      id: 'resulting_inspection',
      common: false
    },
    {
      text: 'Citation Issued',
      id: 'citation_issued',
      common: true
    },
    {
      text: 'Resulting OOS',
      id: 'resulting_oos_inspection',
      common: false
    },
    {
      text: 'Fatal',
      id: 'has_fatal_injury',
      common: true
    },
    {
      text: 'Interstate',
      id: 'interstate',
      common: true
    }
  ];

  popupData: any;
  crashFilterOptions: ICrashFilterOptions;
  regionsGeoJsonSubscription: any;
  regionsGeoJson: any;


  crashesByBarracks = {};
  showingFeatureDiv = false;
  focusFeature: any;

  constructor(commonService: CommonService,
              private crashService: CrashService,
              private zone: NgZone,
              protected cms: CrashMappingMapService,
              public crashDataService: DownloadDataService,
              public formBuilder: FormBuilder) {
    super(commonService);

  }


  getEnabledHighlights() {
    var extSupport = this.stateSettings[this.filterForm.value.report_state].supportsExtendedCrashFields;
    return _.filter(this.highlightsObject, h => extSupport || h.common)
  }

  updateLeafletForState(state: string) {
    if (this.lastReportStateValue != this.selectedRegions.type + state) {
      this.updateGeoJson(state, this.selectedRegions.type);
      this.resetMapView(state);
      this.focusMapPart(this.selectedRegions.barracks.length > 0 ? this.selectedRegions.barracks : this.selectedRegions.counties);

      if (!this.stateSupportsTownMapView(state) && this.selectedRegions.type === "counties") {
        this.toggleMapView('counties');
      }
      if (this.heatmap) {
        setTimeout(this.heatmap.invalidateSize);
      }
      if (this.map) {
        setTimeout(this.map.invalidateSize);
      }
    }

    this.lastReportStateValue = this.selectedRegions.type + state;
  }

  ngOnInit() {
    super.ngOnInit();
    this.resetSelectionValues();
    this.buildFilterForm();
    this.crashService.getCrashFields().subscribe((data: ICrashFilterOptions) => {
      this.crashFilterOptions = data;
      this.crashFilterOptions.codeIndexedCounty = _.keyBy(this.crashFilterOptions.county, c => parseInt(c.cnty_code));
      this.crashFilterOptions.codeIndexedBus = _.keyBy(this.crashFilterOptions.school_bus_related, b => b.schl_bus_reld_code)
      this.crashFilterOptions.codeIndexedPlacard = _.keyBy(this.crashFilterOptions.haz_mat_placard, p => p.haz_mat_plcd_code);
      this.crashFilterOptions.codeIndexedDriverContrib = _.keyBy(this.crashFilterOptions.driver_cntrb_circ, p => p.drvr_cntrb_circ_code);
    });


    this.cms.regionSubject.subscribe((data: IJurisdictionInfo) => {
      if(data){
        console.log(data);
        this.selectedRegions = data;
      }
    });


    this.filterForm.valueChanges.subscribe(() => {

      if (this.lastCrashYearValue !== this.filterForm.value.crash_year ||
        this.lastReportStateValue !== this.selectedRegions.type + this.filterForm.value.report_state) {
        this.setState();
        this.clearUnsupportedFormSelections();

        if (this.lastReportStateValue !== this.selectedRegions.type + this.filterForm.value.report_state) {
          this.refreshStateData();
          this.updateLeafletForState(this.filterForm.value.report_state);
        }

        let filterObj = {};
        if (this.filterForm.value.crash_year) {
          filterObj = {crash_year: this.filterForm.value.crash_year.map(v => v.id)};
        }
        // @ts-ignore
        filterObj.report_state = this.filterForm.value.report_state;
        this.lastCrashYearValue = this.filterForm.value.crash_year;
        this.lastReportStateValue = this.selectedRegions.type + this.filterForm.value.report_state;
        this.fetchData(filterObj);
      } else {
        this.filterData();
      }


    });
    this.cms.focusSubject.subscribe((data) => {
      this.zone.run(() => {
        this.showingFeatureDiv = data.showingFeatureDiv;
        this.focusFeature = data.focusFeature;
      });
    });
    this.commonService.getAvailableYears().subscribe((d) => {
      if (d) {
        this.yearsOptionsObject = d.map((y) => {
          return {
            id: y,
            text: y.toString()
          };
        });
      }
      let useYear = moment().month() > 3 ? moment().year() : moment().year() - 1;
      this.filterForm.controls.crash_year.setValue([{
        id: useYear,
        text: useYear.toString()
      }]);
      this.lastCrashYearValue = this.filterForm.value.crash_year;
    });
    this.cms.markerOnClick.subscribe((e) => {
      if (e) {
        const clickedCoordinates = this.crashes.find((c) => {
          return c.latitude === e.latlng.lat && c.longitude === e.latlng.lng;
        });
        this.popupData = clickedCoordinates;
      }
    });
  }

  clearUnsupportedFormSelections() {
    if(!this.hasExtendedFieldSupport()) {
      setTimeout(() =>
        this.filterForm.patchValue({
          trafficeway_descr : [],
          manr_coll_code : [],
          weath_cond : [],
          cmv_driver_contrib_code : [],
          license_classes : []
        })
      );
    }
  }

  hasExtendedFieldSupport() {
    return this.filterForm != undefined && this.stateSettings[this.filterForm.value.report_state].supportsExtendedCrashFields
  }

  buildFilterForm() {
    if (!this.filterForm) {
      this.filterForm = this.formBuilder.group({
        report_state: this.selectedState,
        barracks: [],
        counties: [],
        vehicle_type: ['All CMVs'],
        crash_year: [],
        crash_month: [],
        crash_dow: [],
        crash_hour: [],
        crash_severity_code: [],
        manr_coll_code: [],
        trafficeway_descr: [],
        weath_cond: [],
        cmv_driver_contrib_code: [],
        license_classes: [],
        carrierinterstateflags: [],
        vehiclegvwrs: [],
        cargo_body_types: [],
        resulting_inspection: [],
        citation_issued: [],
        oss_inspection: [],
        truck_route: [],
        carrier: [],
        highlights: [],
        cmv_driver_age: []
      });
    } else {
      let useYear = moment().month() > 3 ? moment().year() : moment().year() - 1;
      this.filterForm.reset({
        crash_year: [{id: useYear, text: useYear.toString()}],
        vehicle_type: 'All CMVs',
        report_state: this.selectedState,
      }, {
        emitEvent: false,
      })
    }

    // this.updateLeafletForState(this.filterForm.value.report_state);
  }

  filterData(regionType?) {
    if (!this.crashesBkp) {
      return;
    }
	const formData = this.filterForm.value;

    this.crashesForMapping = this.crashesBkp.filter((o) => {
      if (formData.vehicle_type) {
        switch (formData.vehicle_type) {
          case 'Bus':
            if (o.num_buses === 0) {
              return false;
            }
            break;
          case 'Large Truck':
            if (o.num_trucks === 0) {
              return false;
            }
            break;
        }
      }
      if (formData.truck_route && formData.truck_route.length > 0
        && !formData.truck_route.find((r) => r === o.streetname)) {
        return false;
      }
      if (formData.carrier && formData.carrier.length > 0) {
        const carriers = o.carriers.split(';');
        if (!formData.carrier.find((c) => carriers.indexOf(c) > -1)) {
          //console.log("kicking out at carrier");
          return false;
        }
      }

      if (formData.highlights && o[formData.highlights] < 1) {
        //console.log("kicking out at highlights");
        return false;
      }


      if (formData.crash_dow && formData.crash_dow.length > 0
        && !formData.crash_dow.find((b) => o.crash_dow === this.daysOptions.indexOf(b) + 1)) {
        //console.log("kicking out at DOW");
        return false;
      }

      if (formData.crash_hour && formData.crash_hour.length > 0
        && !formData.crash_hour.find((b) => o.crash_hour === +b.split(':')[0])) {
        //console.log("kicking out at crash hour");
        return false;
      }


      let checkSpecs = [
        [formData.crash_severity_code, o.crash_severity_code, 'crash_severity_code'],
        [formData.trafficeway_descr, o.trafy_descr_code, 'trafy_descr_code'],
        [formData.manr_coll_code, o.manr_coll_code, 'manr_coll_code'],
        [formData.weath_cond, o.weath_cond_code_1, 'weath_cond_code'],
        [formData.cmv_driver_contrib_code, o.cmv_driver_contrib_code, 'drvr_cntrb_circ_code'],
        [formData.license_classes, o.license_classes, 'alars_value'],
        [formData.vehiclegvwrs, +o.vehiclegvwrs, 'gross_vehc_wght_ratg_code'],
        [formData.cargo_body_types, o.cargo_body_types, 'description'],
        [formData.crash_month, o.crash_month, 'number'],
        [formData.cmv_driver_age, o.cmv_driver_age, 'cmv_driver_age']
	  ];

      let unmatched = checkSpecs.find(s => !this.checkSelectedValue(s[0], s[1], s[2]));
      //console.log("kicked out by ", unmatched);
      return unmatched === undefined;
	});

	// overall counts are filtered by jurisdiction
	this.crashes = this.crashesForMapping.filter(o => {
		return this.checkSelectedValue(formData.barracks, o.barracks_name, 'name') &&
			this.checkSelectedValue(formData.counties, o.crashfipscountycode, "county_fips");
	})


    this.toggleMapView(this.selectedRegions.type);
  }

  resetForm() {
    this.ngOnInit();
  }

  refreshStateData() {
    this.crashService.getRoutes(this.filterForm.value.report_state).subscribe((data) => {
      this.truckRoutes = _.orderBy(data, 'num_crashes', 'desc').map((r) => {
        r.text = r.streetname;
        return r;
      });
    });
    this.crashService.getCarriers(this.filterForm.value.report_state).subscribe((data) => {
      this.carriers = _.orderBy(data, 'num_crashes', 'desc');
    });
  }

  fetchData(filterObj) {
    if (this.dataRequest) {
      this.dataRequest.unsubscribe();
    }


    this.selectedState = this.filterForm.value.report_state;
    this.performCommonChangeHandling();

    // const filterObj = {};
    if (this.filterForm.value.crash_year) {
      // filterObj["crash_year"] = this.filterForm.value.crash_year.map(v => v.id);
    }
    // filterObj["report_state"] = this.filterForm.value.report_state;
    filterObj["require_geocode"] = filterObj.report_state == "MA";
    delete this.crashes;
    delete this.crashesBkp;
    this.dataRequest = this.crashService.getCrashes(filterObj).subscribe((data: { crashes: ICrash[] }) => {
        if (data) {
          this.hasError = false;
          this.crashesBkp = data.crashes;
          this.crashes = data.crashes;
          this.filterData();
        }
      },
      err => {
        this.handleError(err);
      }
    );
  }


  getCentroid(arr) {
    let twoTimesSignedArea = 0;
    let cxTimes6SignedArea = 0;
    let cyTimes6SignedArea = 0;

    const length = arr.length;

    const x = ((i) => {
      return arr[i % length][0];
    });
    const y = ((i) => {
      return arr[i % length][1];
    });

    for (let i = 0; i < arr.length; i++) {
      const twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
      twoTimesSignedArea += twoSA;
      cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
      cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
    }
    const sixSignedArea = 3 * twoTimesSignedArea;
    return [cxTimes6SignedArea / sixSignedArea, cyTimes6SignedArea / sixSignedArea];
  }


  focusMapPart(selectedOptions?) {
    if (selectedOptions && selectedOptions.length > 0 && this.regionsGeoJson) {
      // don't zoom in 'too far', want to give context
      var flyOptions = {maxZoom: this.stateSettings[this.filterForm.value.report_state].leafletOptions.zoom + 1};
      this.heatmap.flyToBounds(this.getSelectedRegionBoundary(selectedOptions, this.regionsGeoJson), flyOptions);
    } else {
      this.resetMapView();
    }
  }

  updateGeoJson(state, region = 'barracks') {
    this.cms.calculateCrashesByBarracks(this.crashes, this.commonService.getJurisdictionKeys(region));
    if (this.lastReportStateValue != this.selectedRegions.type + state || this.selectedRegions.type !== region) {
      delete this.regionsGeoJson;
      this.regionsGeoJsonSubscription = this.commonService.getRegionsGeoJson(this.filterForm.value.report_state, region).subscribe((data: any) => {
        this.regionsGeoJson = data;
        if (this.selectedRegions.type === 'counties') {
          this.countyMultiSelectOptions = data.features.map((c) => {
            return {
              county_name: c.properties.NAME,
              state_abbrev: this.filterForm.value.report_state

            };
          });
        }
        this.leafletHeatMapLayers = this.cms.generateHeatMap(this.regionsGeoJson, this, this.selectedRegions.type);
        this.leafletLayers = this.cms.addMarkers(this.crashes);
      });
    } else {
      this.leafletHeatMapLayers = this.cms.generateHeatMap(this.regionsGeoJson, this, this.selectedRegions.type);
      this.leafletLayers = this.cms.addMarkers(this.crashes);
    }
  }


  resetMapView(state?) {
      state = state || this.filterForm.value.report_state;
      this.leafletHeatMapOptions = this.stateSettings[state].leafletOptions;
      this.leafletHeatMapOptionsCenter = this.leafletHeatMapOptions.center;
      this.leafletHeatMapOptionsZoom = this.leafletHeatMapOptions.zoom;
      this.leafletOptions = this.stateSettings[state].leafletOptions;
      this.leafletOptionsCenter = this.leafletHeatMapOptionsCenter;
      this.leafletOptionsZoom = this.leafletHeatMapOptionsZoom;
  }


  toggleMapView(regionType?) {
    if (this.selectedRegions.type !== regionType) {
      // this.selectedRegions = [];
      this.selectedBarracks = [];
      this.setSelectedRegionsValue([], regionType === 'cities' ? regionType : null);
      // this.filterForm.controls.towns.setValue(null);
      this.filterForm.controls.counties.setValue(null);
      this.filterForm.controls.barracks.setValue(null);
    }// this.cms.calculateCrashesByBarracks(this.crashes,this.commonService.getJurisdictionKeys(this.selectedRegions.type));
    this.resetMapView();
    this.leafletHeatMapLayers = this.cms.generateHeatMap(this.regionsGeoJson, this, this.selectedRegions.type);
    this.leafletLayers = this.cms.addMarkers(this.crashes);
    this.updateGeoJson(this.filterForm.value.report_state, regionType);
    this.selectedRegions.type = regionType;
    if(this.selectedRegions.barracks.length>0 || this.selectedRegions.counties.length>0){
      this.focusMapPart(this.selectedRegions.type === 'barracks' ? this.selectedRegions.barracks : this.selectedRegions.counties);
    }
  }


  regionSelected(sR: IJurisdictionInfo) {
    if (sR) {
      // this.toggleMapView(sR.type);
      this.filterForm.patchValue({
        barracks: sR.barracks,
        counties: sR.counties
      });
      setTimeout(() => this.focusMapPart(sR.type === 'barracks' ? sR.barracks : sR.counties),100);
      // this.filterData(sR.type);
    }
  }

  onMapReady(map) {
    this.map = map;
  }


  onHeatMapReady(map) {
    this.heatmap = map;
  }

  closePopUp() {
    this.popupData = null;
  }

}

