import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CommonService, IBarracksInfo, ICountyInfo, IJurisdictionInfo} from '../common.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-Jurisdiction-selector',
  templateUrl: './jurisdiction-selector.component.html',
  styleUrls: ['./jurisdiction-selector.component.scss']
})
export class JurisdictionSelectorComponent implements OnInit, OnChanges {


  _placeholder = 'All';
  selectedItemsDisplay: IJurisdictionInfo[] | any = [];
  showingDropdown = false;
  _selectedItems: IJurisdictionInfo = {type: 'barracks', barracks: [], counties: []};
  dropdownListStyle: { [klass: string]: any; } = {right: '0px'};

  @Input('itemsShowLimit') itemsShowLimit = 2;

  @Input('showHierarchy') showHierarchy = false;

  @Input('items') items: IJurisdictionInfo;

  @Output('selectedItemsChange')
  selectedItemsChange: EventEmitter<IJurisdictionInfo> = new EventEmitter<any>();

  @Input('anchor') anchor: string;


  @Input() get selectedItems() {
    return this._selectedItems;
  }

   set selectedItems(val) {
    if( !val ){
      return;
    }
    this._selectedItems = val;
    if (this.items) {
      this.items.barracks.forEach(t => t.barracks.forEach(b => b.selected = false));
      this._selectedItems.barracks.forEach(b => b ? (b.selected = true) : '');
      this.items.barracks.forEach((b) => this.updateitemselection(b));
    }
    this.selectedItemsChange.emit(this.selectedItems);
    this.updateDisplayItems();

  }


  constructor(private commonService: CommonService) {

  }

  ngOnInit(): void {

  }


  ngOnChanges() {
    if (this.items) {
      this.items.barracks.forEach(b => this.updateitemselection(b));
    }

    if (this.anchor == 'left') {
      this.dropdownListStyle = {left: '20px'};
    } else {
      this.dropdownListStyle = {right: '0px'};
    }
    this.updateDisplayItems();
  }


  closeDropdown() {
    this.showingDropdown = false;
  }

  onItemClick(event, item: IBarracksInfo) {
    if (item.type == 'troop') {
      this.toggleTroop(item);
    } else {
      this.toggleBarracks(item);
    }
  }

  toggleDropdown() {
    this.showingDropdown = !this.showingDropdown;
  }

  toggleBarracks(item: IBarracksInfo) {
    item.selected = !item.selected;
    const parentTroop = _.find(this.items.barracks, t => t.troop_name == item.troop_name);
    this.setFormattedValue(_.filter(_.flatMap(this.items.barracks, 'barracks'), b => b.selected));
  }

  selectAll() {
    if (this.selectedItems.type === 'barracks') {
      this.setFormattedValue(_.flatMap(this.items.barracks, 'barracks'));
    } else {
      this.setFormattedValue(this.items.counties);
    }
  }

  deselectAll() {
    this.setFormattedValue();
  }


  updateitemselection(troop: IBarracksInfo) {
    troop.selectedBarracks = [];
    troop.barracks.forEach((b) => {
      if(this.selectedItems.barracks.find(s => s.barracks_id === b.barracks_id && s.selected)) {
        b.selected = true;
        troop.selectedBarracks.push(b);
      }
    });
  }

  updateDisplayItems() {
    if (this.selectedItems.type === 'barracks') {
      let label = this.getBarrackLabel();
      if(label === 'District'){
        label += 's';
      }
      this._placeholder = `All ${label}`;
      this.selectedItemsDisplay = _.flatMap(this.items.barracks, t => {
        if (t.selectedBarracks.length == t.barracks.length) {
          return [t];
        } else {
          return t.selectedBarracks;
        }
      });
    } else {
      this._placeholder = `All Counties`;
      this.selectedItemsDisplay = this.selectedItems.counties;
    }
  }

  toggleTroop(item: IBarracksInfo) {
    if (item.selectedBarracks.length == item.barracks.length) {
      item.barracks.forEach(b => b.selected = false);
    } else {
      item.barracks.forEach(b => b.selected = true);
    }
    this.setFormattedValue(_.filter(_.flatMap(this.items.barracks, 'barracks'), b => b.selected));
  }

  toggleCounty(item: ICountyInfo) {
    if (this.isSelected(item)) {
      this.setFormattedValue(this.selectedItems.counties.filter(c => c.county_name != item.county_name));
    } else {
      const counties = this.selectedItems.counties;
      counties.push(item);
      this.setFormattedValue(counties);
    }
  }

  isSelected(item: ICountyInfo) {
    return this.selectedItems.counties.find(c => c.county_name === item.county_name);
  }

  itemShowRemaining(): number {
    return this.selectedItemsDisplay.length - this.itemsShowLimit;
  }

  setFormattedValue(value = [], type?) {
    type = type || this.selectedItems.type;
    this.selectedItems = {
      type,
      barracks: type === 'barracks' ? value : [],
      counties: type === 'counties' ? value : [],
    };
  }

  getBarrackLabel(){
    return this.commonService.getBarracksLabel();
  }
}
