import { Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {InspectionService} from './inspection.service';
import {CommonService} from '../common/common.service';
import * as L from 'leaflet';
import {Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import * as d3 from 'd3';
import * as moment from 'moment';
import {BaseComponent} from '../common/common.component';


declare var vega: any;
declare var vegaTooltip: any;
/**
 * Adds invert field to GeoJSONOptions for Leaflet.snogylop support
 */
interface InvertibleGeoJSONOptions extends L.GeoJSONOptions<any> {
  invert: boolean;
}

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.css']
})
export class InspectionComponent extends BaseComponent implements OnInit {

  id: number;
  inspectionService: InspectionService;
  zone: NgZone;
  mapType = 'barracks';

  @ViewChild('inspectionsByMonthChart') inspectionsByMonthContainer: ElementRef;
  inspectionsByMonthView: any;
  @ViewChild('inspectionsByLevelChart') inspectionsByLevelContainer: ElementRef;
  inspectionsByLevelView: any;

  inspectionsByMonthPromise: Promise<any>;
  inspectionsByLevelPromise: Promise<any>;

  map: L.Map;

  lastReportStateValue: string;

  leafletLayers = [];
  showingFeatureDiv = false;
  focusFeature: any;

  inspectionStats: any;

  inspMaxColor = 'rgb(92,121,157)';
  inspMinColor = 'rgb(197,229,222)';
  crashMinColor = 'rgb(21,184,0)';
  crashMaxColor = 'rgb(220,17,15)';


  regionGeoJsonObs: Observable<Object>;
  regionGeoJsonSubscription: Subscription;
  inspectionStatSubscription: Subscription;

  leafletOptions: any;
  leafletOptionsCenter: any;
  leafletOptionsZoom: any;

  inspectionLevelOptionsObject = [
    {"name" : "Level 1", "value" : 1},
    {"name" : "Level 2", "value" : 2},
    {"name" : "Level 3", "value" : 3},
    {"name" : "Level 4", "value" : 4},
    {"name" : "Level 5", "value" : 5},
    {"name" : "Level 6", "value" : 6},
    {"name" : "Level 7", "value" : 7}
  ];

  inspectionLevelDropdownSettings = {
    textField: "name",
    idField: "value",
    singleSelection: false,
    itemsShowLimit: 1
  };

  topCarrierMode: any;


  constructor(insp: InspectionService, commonService: CommonService, zone: NgZone) {
    super(commonService);
    this.inspectionService = insp;
    this.commonService = commonService;
    this.zone = zone;
  }

  onInputChange(event) {
    this.refreshInspectionData();
  }

  onMapReady(map: L.Map) {
    this.map = map;
  }

  buildMapLegend(lowInspValue, highInspValue, lowCrashValue, highCrashValue) {
    const w = 100;
    const h = 300;
    d3.select('#map-legend-container').selectAll('*').remove();
    const key = d3.select('#map-legend-container').append('svg')
      .attr('width', w)
      .attr('height', h)
      .attr('class', 'legend');

    const defs = key.append('defs');

    const buildSingleLegend = (
      minColor: string, maxColor: string,
      lowValue: number, highValue: number,
      title: string, yoffset: number) => {
      const componentHeight = (h - 50) / 2.0;
      const legend = defs.append('svg:linearGradient')
        .attr('id', 'gradient' + title)
        .attr('x1', '100%')
        .attr('y1', '0%')
        .attr('x2', '100%')
        .attr('y2', '100%')
        .attr('spreadMethod', 'pad');

      legend.append('stop')
        .attr('offset', '0%')
        .attr('stop-color', maxColor)
        .attr('stop-opacity', 1);

      legend.append('stop')
        .attr('offset', '100%')
        .attr('stop-color', minColor)
        .attr('stop-opacity', 1);

      key.append('text')
        .html(title)
        .attr('y', 15 + yoffset);

      const y = d3.scaleLinear()
        .range([componentHeight, 0])
        .domain([lowValue, highValue]);

      const yAxis = d3.axisRight(y).ticks(5);

      const gElm = key.append('g');
      if(title==='Crashes'){
        this.buildCrashLegend(gElm);
      } else {
        key.append('rect')
        .attr('width', w - 50)
        .attr('height', componentHeight)
        .style('fill', 'url(#gradient' + title + ')')
        .attr('transform', 'translate(0,' + (yoffset + 20) + ')');
      }

      gElm.attr('class', 'y axis')
        .attr('transform', 'translate(51,' + (20 + yoffset) + ')')
        .call(yAxis);
    };

    buildSingleLegend(this.inspMinColor, this.inspMaxColor, lowInspValue, highInspValue, 'Inspections', 0);

    if (lowCrashValue) {
      buildSingleLegend(this.inspMinColor, this.inspMaxColor, lowCrashValue, highCrashValue, 'Crashes', 150);
    }
  }

  buildCrashLegend(legend){
    const linearScale = d3.scaleLinear()
      .domain([0, 100])
      .range([0, 100]);

    const sequentialScale = d3.scaleSequential(d3.interpolateRgb(this.crashMaxColor,this.crashMinColor))
      .domain([0, 100]);

    const myData = Array(6).fill(20).map((e,i) => e*i);


    legend.selectAll('circle')
      .data(myData)
      .enter()
      .append('circle')
      .attr('r', 6)
      .attr('transform', 'translate(-25,12)')
      .attr('cy', function(d) {
        return linearScale(d);
      })
      .style('fill', function(d) {
        return sequentialScale(d);
      });
  }

  safeInterpolate(val: number, minVal: number, maxVal: number, interpolator: any) {
    const featurePct = (val - minVal) / (maxVal - minVal);
    let color: string;
    if (val == maxVal) {
      color = interpolator(1);
    } else if (val == 0) {
      color = interpolator(0);
    } else {
      color = interpolator(featurePct);
    }
    return color;
  }

  refreshInspectionData() {
    this.hasError = false;
    if (this.inspectionStatSubscription) {
      this.inspectionStatSubscription.unsubscribe();
    }
    if (this.regionGeoJsonSubscription) {
      this.regionGeoJsonSubscription.unsubscribe();
    }
    this.performCommonChangeHandling();
    localStorage.setItem("topCarrierMode", this.topCarrierMode);

    this.updateLeafletForState(this.selectedState);

    return this.inspectionStatSubscription = this.inspectionService.getInspectionStats(
      _.filter(this.selectedRegions.barracks, b => b.type == 'barracks').map(b => b.barracks_id),
      this.timeRange, this.selectedVehicleType,
      this.selectedState,
      _.map(this.selectedRegions.counties, c => c.county_fips),
      undefined,
      _.map(this.inspectionLevels, "value")
    ).subscribe((result) => {
      this.inspectionStats = result;
      this.updateMap();
      this.inspectionStats.inspections_by_month.forEach(function (r) {
        const parts = r.yearmon.split('-').map(s => parseInt(s));
        r.yearmon = new Date(parts[0], parts[1] - 1, parts[2]);
        r.yearmon_fmt = moment(r.yearmon).format('MMM YYYY');
      });

      // refresh monthly inspection data, ensuring the spec has been loaded
      this.inspectionsByMonthPromise.then(function (outerThis: InspectionComponent) {
        let inspectionsAverage = 0;
        const recentInspectionsAvg = _.reduce(_.takeRight(outerThis.inspectionStats.inspections_by_month, 3), (c, m:any) => c + m.inspections, 0) / 3.0;
        outerThis.inspectionStats.inspections_by_month.forEach(i => inspectionsAverage += i.inspections);
        inspectionsAverage = inspectionsAverage / outerThis.inspectionStats.inspections_by_month.length;
        const orient = recentInspectionsAvg < inspectionsAverage ? 'top-right' : 'bottom-right';

        outerThis.inspectionsByMonthView.signal('legend_orient', orient);
        const changeset = vega.changeset().remove(() => true).insert(outerThis.inspectionStats.inspections_by_month);
        outerThis.inspectionsByMonthView.change('table', changeset).run();
      }, (err) => {
        this.hasError = false;
      });

      this.inspectionsByLevelPromise.then( (outerThis: InspectionComponent) => {
        // selected
        const changeset = vega.changeset().remove(() => true).insert(outerThis.inspectionStats.inspections_by_level.map((e)=>{
          e.selected = '';
          if(this.inspectionLevels && this.inspectionLevels.find(l => l.value === e.insptypelevelcode)) {
            e.selected = '*';
          }
          return e;
        }));
        console.log(outerThis.inspectionStats.inspections_by_level.map((e)=>{
          e.selected = '';
          if(this.inspectionLevels.find(l => l.value === e.insptypelevelcode)) {
            e.selected = '*';
          }
          return e;
        }));

        outerThis.inspectionsByLevelView.change('table', changeset).run();
        outerThis.inspectionsByLevelView.addEventListener('click', (event, item) => {
          if( item ) {
            const lvl = item.datum.value || item.datum.insptypelevelcode;
            if(!lvl){
              return;
            }
            const val = {
              value: +lvl,
              name: `Level ${lvl}`
            };
            const valIndex = this.inspectionLevels.findIndex(e => e.value === val.value);
            if(valIndex === -1) {
              console.error(this.inspectionLevels)
              // event is fired twice on each click,
              // second times removes the value added originally
              setTimeout(() => {
                if(this.inspectionLevels.findIndex(e => e.value === val.value)>-1) return 0;
                if (event.ctrlKey) {
                  const levels = [...this.inspectionLevels];
                  levels.push(val);
                  this.inspectionLevels = levels;
                } else {
                  this.inspectionLevels = [val];
                }
              },50);
            } else {
              setTimeout(() => {
                const levels = [...this.inspectionLevels];
                levels.splice(valIndex,1);
                this.inspectionLevels = levels;
              },40);
            }
          }
        });
      }, (err) => {
        this.hasError = true;
      });
    }, (err) => {
      this.hasError = true;
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.topCarrierMode = localStorage.getItem("topCarrierMode") || "violation_rate";

    this.inspectionsByMonthPromise = this.setupVegaView(
      '/assets/vega-specs/inspections-by-month.json',
      this.inspectionsByMonthContainer,
      'inspectionsByMonthView');

    this.inspectionsByLevelPromise = this.setupVegaView(
      '/assets/vega-specs/inspections-by-level.json',
      this.inspectionsByLevelContainer,
      'inspectionsByLevelView');

    this.refreshInspectionData();
  }

  updateLeafletForState(state: string) {
    if (this.lastReportStateValue != this.selectedRegions.type + '-' + state) {
      this.regionGeoJsonObs = this.commonService.getRegionsGeoJson(this.selectedState, this.selectedRegions.type);
      this.leafletOptions = this.stateLeafletOptions[state];
      this.leafletOptionsCenter = this.leafletOptions.center;
      this.leafletOptionsZoom = this.leafletOptions.zoom;

    }
    if (this.map) {
      setTimeout(this.map.invalidateSize);
      let i = 0;

      this.map.eachLayer((layer) => {
        i++;
        if(i===1) return;
        this.map.removeLayer(layer);
      });
    }

    this.lastReportStateValue = this.selectedRegions.type + '-' + state;
  }

  updateMap() {
    // apply styles to barracks after ensuring geojson has been loaded
    let inspectionCounts, crashCounts;
    if (this.selectedRegions.type === 'barracks') {
      inspectionCounts = _.keyBy(this.inspectionStats.inspections_by_barracks_statewide, 'barracks_id');
      crashCounts = _.keyBy(this.inspectionStats.crashes_by_barracks_statewide, 'barracks_id');
    } else {
      inspectionCounts = _.keyBy(this.inspectionStats.inspections_by_county_statewide, 'county_fips');
      crashCounts = _.keyBy(this.inspectionStats.crashes_by_county_statewide, 'crashfipscountycode');

    }
    const maxInspections = _.max(_.map(inspectionCounts, (v) => v.num_inspections));
    let minInspections = _.min(_.map(inspectionCounts, (v) => v.num_inspections));
    if (minInspections == maxInspections) {
      minInspections = 0;
    }
    const maxCrash = _.max(_.map(crashCounts, (v) => v.num_crashes));
    let minCrash = _.min(_.map(crashCounts, (v) => v.num_crashes));
    if (minCrash == maxCrash) {
      minCrash = 0;
    }

    this.regionGeoJsonSubscription = this.regionGeoJsonObs.subscribe((data: any) => {
      data.features.forEach((f) => {
        let inspectionRegion;
        let crashRegion;
        if (this.selectedRegions.type === 'barracks') {
          inspectionRegion = inspectionCounts[f.properties.barrack];
          crashRegion = crashCounts[f.properties.barrack]
        } else {
          // Some states' county fips have a decimal with zero
          inspectionRegion = inspectionCounts[Math.round(f.properties.FIPS)];
          crashRegion = crashCounts[Math.round(f.properties.FIPS)]

        }

        if (inspectionRegion != undefined) {
          f.properties.name = f.properties.NAME ? f.properties.NAME : f.properties.barracks_name;
          f.properties.state_abbrev = this.selectedState;
          f.properties.inspections = inspectionRegion.num_inspections;
        } else {
          f.properties.inspections = 0;
        }
        if (crashCounts && crashRegion != undefined) {
          f.properties.crashes = crashRegion.num_crashes;
        } else {
          f.properties.crashes = 0;
        }
      });

      // add and style the geojson polygons
      const inspInterpolator = d3.interpolateRgb(this.inspMinColor, this.inspMaxColor);
      const crashInterpolator = d3.interpolateRgb(this.crashMinColor, this.crashMaxColor);
      this.buildMapLegend(minInspections, maxInspections, minCrash, maxCrash);
      const selectedRegion = this.selectedRegions.barracks.length > 0 ? this.selectedRegions.barracks :
        (this.selectedRegions.counties.length > 0 ? this.selectedRegions.counties : null)  as any[];
      this.leafletLayers = [
        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 10, minZoom: 6}),
        L.geoJSON(data, {
          invert: true,
          style: {
            color: '#999999',
            weight: 5,
            opacity: 0,
            fillOpacity: 0.02,
          }
        } as InvertibleGeoJSONOptions),
        L.geoJSON(data as any, {
          invert: false,
          style: (feature) => {
            const color = this.safeInterpolate(feature.properties.inspections,
              minInspections, maxInspections,
              inspInterpolator);
            const style = {
              color: 'black',
              fillColor: color,
              opacity: 1,
              weight: 1,
              fillOpacity: 0.9
            };
            if (selectedRegion && !selectedRegion.find((r) => this.matchRegion(r, feature))) {
              style.opacity = 0.3;
              style.fillOpacity = 0.4;
            }
            return style;
          },
          onEachFeature: (feature, layer) => {
            layer.on({
              click: ((e) => this.selectedRegions = this.commonService.mapClickFilter(e, this.selectedRegions, this.indexedBarracksOptions)),
              mouseover: (e: any) => {
                if (selectedRegion && !selectedRegion.find(r => this.matchRegion(r, feature))) {
                  // return;
                }
                // running with zone to make sure changes trigger databinding
                this.zone.run(() => {
                  this.showingFeatureDiv = true;
                  this.focusFeature = feature;
                });
              },
              mouseout: (e) => {
                this.zone.run(() => {
                  this.showingFeatureDiv = false;
                });
              }
            });


            if (selectedRegion && !selectedRegion.find(r => this.matchRegion(r, feature))) {
              // return;
            }

            let center = this.commonService.getPolygonCenter(feature);

            // crude bandaid for the weird shape of cape cod
            if (feature.properties.barracks_name == 'D-2') {
              center = [41.703666, -70.177655];
            }

              const color = this.safeInterpolate(feature.properties.crashes,
                minCrash, maxCrash,
                crashInterpolator);
            let fillOpacity = 1;
            if (selectedRegion && !selectedRegion.find((r) => this.matchRegion(r, feature))) {
              fillOpacity = 0.5;
            }
              setTimeout(() => {
                L.circle(center, {
                  color,
                  fillColor: color,
                  fillOpacity,
                  radius: 2000
                }).addTo(this.map).on({
                  mouseover: (e: any) => {
                    // running with zone to make sure changes trigger databinding
                    this.zone.run(() => {
                      this.showingFeatureDiv = true;
                      this.focusFeature = feature;
                    });
                  },
                  mouseout: (e) => {
                    this.zone.run(() => {
                      this.showingFeatureDiv = false;
                    });
                  }
                });
              });
          },
        } as InvertibleGeoJSONOptions)
      ];

      if (selectedRegion) {
        // don't zoom in 'too far', want to give context
        var flyOptions = {maxZoom: this.stateSettings[this.selectedState].leafletOptions.zoom + 1};
        this.map.flyToBounds(this.getSelectedRegionBoundary(selectedRegion, data), flyOptions);
      } else {
        this.leafletOptionsCenter = this.leafletOptions.center;
        this.leafletOptionsZoom = this.leafletOptions.zoom;
      }
    });

  }





  resetFilter() {
    this.resetSelectionValues();
    this.refreshInspectionData();
  }
}
