import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private router: Router) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// if we have an auth token, add and send it
		let authToken = localStorage.getItem('auth-token');
		if ( authToken ) {
			request = request.clone({
				setHeaders: {
					Authorization: `${authToken}`
				}
			});
		}

		// unauthorized redirects to login
		return next.handle(request).pipe(catchError((err: HttpResponse<any>) => {
			if ( err.status == 401
				&& !this.router.isActive('password-reset', false)
				&& !this.router.isActive('/', true)
				&& !this.router.isActive('request-account', false) ) {
				this.router.navigate(['/login']);
			}
			throw err;
		}));
	}
}
