import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../common/common.component';
import { CommonService } from '../common/common.service';
import { latLng, tileLayer } from 'leaflet';
import { CrashRiskMappingService } from './crash-risk-mapping.service';
import { CrashService } from '../crash/crash.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
	selector: 'app-crash-risk-model',
	templateUrl: './crash-risk-model.component.html',
	styleUrls: ['./crash-risk-model.component.css']
})
export class CrashRiskModelComponent extends BaseComponent implements OnInit {

	inputForm: FormGroup;

	smallTileZoomThreshold = 12;
	currentMapZoom = 8;
	leafletOptions = {
		zoom: 8,
		minZoom: 8,
		center: latLng(42.26657151126584, -71.56451290885582),
		preferCanvas: true
	};

	leafletLayers: any = [
		tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		})
	];

	crashModelTilesGeoJson: any;
	crashModelSubtilesGeoJson: any;
	crashRisks: any;
	crashTileSplits: {number : {number : number}};

	precipOptions = ["None", "Rain", "Snow"];
	windOptions = ["None/Light", "Strong"];
	temperatureOptions = [
		{"display" : "Below 0", "value" : -10},
		{"display" : "0-32", "value" : 20},
		{"display" : "33-40", "value" : 35},
		{"display" : "41-60", "value" : 50},
		{"display" : "61-80", "value" : 70},
		{"display" : "Above 80", "value" : 90},
	];
	trafficOptions = ["Light", "Typical", "Heavy"];
	roadWorkOptions = ["No", "Yes"];
	inspectionActivityOptions = ['Low', 'Typical', 'High'];	

	showingFeatureDiv = false;
	focusFeature: any;
	relativeHeat = false;

	constructor(commonService: CommonService,
				private crashService: CrashService,
				public crashRiskMapping: CrashRiskMappingService,
				public formBuilder: FormBuilder) {
		super(commonService);
		this.crashRiskMapping.focusSubject.subscribe(v => {
			this.showingFeatureDiv = v.showingFeatureDiv;
			this.focusFeature = v.focusFeature;
		});
	}

	ngOnInit() {

		let oneMonthMoment = moment().add(1, 'month');
		let tomorrow = {year: moment().year(), month: moment().month() + 1, day: moment().date()+1};
		this.inputForm = this.formBuilder.group({
			wind: "None/Light",
			precip: "None",
			temp: 50,
			date: tomorrow,
			startDate: tomorrow,
			endDate: {year:oneMonthMoment.year(), month: oneMonthMoment.month()+1, day: oneMonthMoment.date()},
			traffic: 'Typical',
			roadWork: 'No',
			inspectionActivity: 'Typical',
			useDateRange: false,
			useWeatherForecast: false
		});

		this.commonService.getCrashModalTilesGeoJson().subscribe((data: any) => {
			if ( !this.crashModelTilesGeoJson && data ) {
				this.crashModelTilesGeoJson = data;
				this.updatePredictions(true);
		
				this.inputForm.valueChanges.subscribe(() => {
					this.updatePredictions(false);
				});
			}
		}, err => this.handleError(err));

		this.commonService.getCrashModelSubtilesGeoJson().subscribe((data:any) => {
			if ( !this.crashModelSubtilesGeoJson && data ) {
				this.crashModelSubtilesGeoJson = data;
			}
		}, err => this.handleError(err));

		this.crashService.getCrashTileSplits().subscribe((data) => {
			this.crashTileSplits = data;
		}, err => {
			this.handleError(err)
		});
	}

	handleMapZoom($event) {
		let origZoom = this.currentMapZoom;
		this.currentMapZoom = $event.target._zoom;
		if((this.currentMapZoom < this.smallTileZoomThreshold) != (origZoom < this.smallTileZoomThreshold)) {
			this.updateLeafletLayers(false);
		}
	}

	updateLeafletLayers(recomputeMinMax) {
		if(this.currentMapZoom < this.smallTileZoomThreshold) {
			this.leafletLayers = this.crashRiskMapping.generateHeatMap(this.crashModelTilesGeoJson, this.crashRisks, recomputeMinMax, this.relativeHeat);
		} else {
			let crashSplits = this.crashTileSplits;
			let subtileRisks = _.fromPairs(_.flatMap(this.crashRisks, (v, k) => {
				return _.map(crashSplits[k], (pct, idx) => {
					return [`${k}_${idx}`, {"pred_crash_rate" : pct * v.pred_crash_rate, "baseline_crash_rate" : pct * v.baseline_crash_rate}];
				});
			}));

			this.leafletLayers = this.crashRiskMapping.generateHeatMap(this.crashModelSubtilesGeoJson, subtileRisks, recomputeMinMax, this.relativeHeat);
		}
	}

	updatePredictions(recomputeMinMax) {
		this.hasError = false;
		let postData = {
			"precip" : this.inputForm.value.precip,
			"wind" : this.inputForm.value.wind,
			"avg_temp" : this.inputForm.value.temp,
			"traffic" : this.inputForm.value.traffic,
			"road_work" : this.inputForm.value.roadWork,
			"inspection_activity" : this.inputForm.value.inspectionActivity,
			"use_date_range" : this.inputForm.value.useDateRange,
			"use_weather_forecast" : this.inputForm.value.useWeatherForecast
		};

		let formatDate = d => `${d.year}-${d.month.toString().padStart(2, '0')}-${d.day.toString().padStart(2, '0')}`;
		if(this.inputForm.value.useDateRange) {
			postData["start_date"] = formatDate(this.inputForm.value.startDate);
			postData["end_date"] = formatDate(this.inputForm.value.endDate);
		} else {
			postData["date"] = formatDate(this.inputForm.value.date);
		}

		this.crashService.getCrashRisks(postData).subscribe((data) => {
			this.crashRisks = data;
			this.updateLeafletLayers(recomputeMinMax);
		}, err => {
			this.handleError(err);
		});
	}

	resetFilter() {

	}
}
