import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as _ from 'lodash';

export interface IMultiSelectBtnToggleEntry {
  text: string;
  id: string;
  selected?: boolean;
}

@Component({
  selector: 'app-multi-select-btn-toggle',
  templateUrl: './multi-select-btn-toggle.component.html',
  styleUrls: ['./multi-select-btn-toggle.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiSelectBtnToggleComponent),
    }
  ]
})
export class MultiSelectBtnToggleComponent implements OnInit, ControlValueAccessor {

  @Input('entries') entries: IMultiSelectBtnToggleEntry[];

  _onChange: (_: any) => void;
  _onTouched: (_: any) => void;

  noneSelected: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  writeValue(obj: any): void {
    this.noneSelected = true;
    this.entries.forEach(e => {
      e.selected = _.find(obj, o => o == e.id);
      this.noneSelected = this.noneSelected && !e.selected;
    });

  }

  handleChange($event) {
    this.noneSelected = true;
    let changedValue;
    this.entries = this.entries.map((e) => {
      if (e.id === $event.id && $event.selected) {
        e.selected = $event.selected;
        changedValue = e.id;
      } else {
        e.selected = false;
      }
      this.noneSelected = this.noneSelected && !e.selected;
      return e;
    });
    this._onChange(changedValue);
    this._onTouched($event);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

}
