import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment'
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface IUserInfo {
	user_id?: number;
	username: string;
	email: string;
	is_sys_admin: boolean;
	has_risk_explorer_access: boolean;
	password?: string;
	confirmPassword?: string;
}

export interface ILoginRequest {
	username: string;
	password: string;
	expires: string;
}

export interface ILoginResponse {
	user_rec: IUserInfo;
	AUTH_TOKEN: string;
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	httpClient: HttpClient;

	userInfo: IUserInfo;
	checkedUserInfo: boolean = false;

	constructor(httpClient: HttpClient) {
		this.httpClient = httpClient;
		this.getUserInfo().subscribe((data) => {
			this.userInfo = data;
			this.checkedUserInfo = true;
		}, err => {
			this.checkedUserInfo = true;
		});
	}

	getUsers() {
		return this.httpClient.post<IUserInfo[]>(environment.apiUrl + '/users', {});
	}

	deleteUser(user: IUserInfo) {
		return this.httpClient.post(environment.apiUrl + '/user/delete', user);
	}

	saveUser(user: IUserInfo) {
		return this.httpClient.post(environment.apiUrl + '/user', user);
	}

	getUserInfo() {
		return this.httpClient.get<IUserInfo>(environment.apiUrl + '/whoami');
	}

	login(loginInfo: ILoginRequest) {
		return this.httpClient.post(environment.apiUrl + '/login', loginInfo).pipe(switchMap((r: ILoginResponse) => {
				this.userInfo = r.user_rec;
				localStorage.setItem('auth-token', r.AUTH_TOKEN);
				return of(r);
			})
		);
	}

	logout() {
		this.userInfo = null;
		localStorage.removeItem('auth-token');

	}

	resetPassword(data) {
		if ( data.reset_token ) {
			return this.httpClient.post(environment.apiUrl + '/reset-password', data);
		}
		return this.httpClient.post(environment.apiUrl + '/forgot-password', data);
	}

	getPublicKey(): Observable<any> {
		return this.httpClient.get(`${environment.apiUrl}/getPublicKey`);
	}
}
