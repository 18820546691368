import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AccessGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const requiresLogin = route.data.requiresLogin || false;

		if ( requiresLogin ) {
			var isLoggedIn = this.authService.userInfo != null;
			// allow a user to a route even if we're not sure they're allowed to access it
			// optimistically assuming they are allowed and if not the API will kick them out
			if ( isLoggedIn || !this.authService.checkedUserInfo ) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
}
