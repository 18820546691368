import { Component, Input, OnInit } from '@angular/core';
import { IDataFreshnessInfo } from '../common.service';

@Component({
  selector: 'app-freshness-info-footer',
  templateUrl: './freshness-info-footer.component.html',
  styleUrls: ['./freshness-info-footer.component.css']
})
export class FreshnessInfoFooterComponent implements OnInit {

  @Input()
  dataFreshnessInfo:IDataFreshnessInfo;

  constructor() { }

  ngOnInit() {
  }

}
