import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HomeService } from './home.service';
import { BaseComponent } from '../common/common.component';
import { CommonService } from '../common/common.service';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';

declare var vega: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {
  homeService:HomeService;
  authService:AuthService;

  constructor(commonService:CommonService, homeService:HomeService, authService:AuthService) {
    super(commonService);
    this.homeService = homeService;
    this.authService = authService;
   }

  ngOnInit() {

  }
}
