import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../common/common.component';
import { CommonService } from '../common/common.service';

@Component({
  selector: 'app-goal-tracking',
  templateUrl: './goal-tracking.component.html',
  styleUrls: ['./goal-tracking.component.css']
})
export class GoalTrackingComponent extends BaseComponent implements OnInit {

  inspectionDateRange: any;

  constructor(commonService:CommonService) {
    super(commonService)
   }

  ngOnInit() {
    super.ngOnInit();

    this.commonService.getInspectionDateRange().subscribe(data => {
      this.inspectionDateRange = data;
    }, err => {
      this.handleError(err);
    });
  }

  onInputChange(event) {

  }

}
