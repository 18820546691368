import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMultiSelectDropDownModule } from '../ng-multiselect-dropdown/src/ng-multiselect-dropdown.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InspectionComponent } from './inspection/inspection.component';
import { AccessGuard } from './auth/access-guard.service';
import { AuthInterceptor } from './auth/auth-interceptor.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './auth/login.component';
import { HomeComponent } from './home/home.component';
import { NgbModule, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { InspectionTrooperComponent } from './inspection/inspection-trooper.component';
import { GoalSettingComponent } from './goal-setting/goal-setting.component';
import { UsersComponent } from './users/users.component';
import { PredicateFilterPipe } from './util/filter.pipe';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown, faSort, faSortDown, faSortUp, faCaretRight, faCaretDown, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faMinusSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

import { CrashLocationComponent } from './crash/crash-location.component';
import { CrashTimeComponent } from './crash/crash-time.component';
import { InspectionCountsBarComponent } from './inspection/inspection-counts-bar.component';
import { SortIconComponent } from './common/sort-icon.component';
import { BarracksSelectorComponent } from './common/barracks-selector.component';
import { ClickOutsideDirective } from './common/click-outside.directive';
import { CrashTrendsComponent } from './crash/crash-trends.component';
import { CrashCountsBarComponent } from './crash/crash-counts-bar.component';
import { CrashComponent } from './crash/crash.component';
import { GoalTrackingComponent } from './goal-tracking/goal-tracking.component';
import { GoalManagementComponent } from './goal-management/goal-management.component';
import { CrashMappingComponent } from './crash-mapping/crash-mapping.component';
import { CrashMappingDatatableComponent } from './crash-mapping/crash-mapping-datatable/crash-mapping-datatable.component';
import { InspectionMappingComponent } from './inspection-mapping/inspection-mapping.component';
import { MultiSelectBtnToggleComponent } from './multi-select-btn-toggle/multi-select-btn-toggle.component';
import { InspectionMappingDatatableComponent } from './inspection-mapping/inspection-mapping-datatable/inspection-mapping-datatable.component';
import { CrashRiskModelComponent } from './crash-risk-model/crash-risk-model.component';
import { AboutComponent } from './about/about.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { RequestAccountComponent } from './request-account/request-account.component';
import { Ng5SliderModule } from 'ng5-slider';
import { MultiSelectAccordionComponent } from './multi-select-accordion/multi-select-accordion.component';
import { BooleanYNPipe } from './boolean-yn.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimeRangeSelectorComponent } from './common/time-range-selector/time-range-selector.component';
import { FreshnessInfoLabelComponent } from './common/freshness-info-label/freshness-info-label.component';
import { JurisdictionSelectorComponent } from './common/jurisdiction-selector/jurisdiction-selector.component';
import { FreshnessInfoFooterComponent } from './common/freshness-info-footer/freshness-info-footer.component';
import { CurrentSelectionsLabelComponent } from './common/current-selections-label/current-selections-label.component';
import { HelpTooltipComponent } from './common/help-tooltip/help-tooltip.component';

@NgModule({
	declarations: [
		AppComponent,
		InspectionComponent,
		LoginComponent,
		HomeComponent,
		PageNotFoundComponent,
		InspectionTrooperComponent,
		GoalSettingComponent,
		UsersComponent,
		PredicateFilterPipe,
		CrashLocationComponent,
		CrashTimeComponent,
		InspectionCountsBarComponent,
		CrashCountsBarComponent,
		SortIconComponent,
		BarracksSelectorComponent,
		ClickOutsideDirective,
		CrashTrendsComponent,
		CrashComponent,
		GoalTrackingComponent,
		GoalManagementComponent,
		CrashMappingComponent,
		CrashMappingDatatableComponent,
		InspectionMappingComponent,
		InspectionMappingDatatableComponent,
		MultiSelectBtnToggleComponent,
		CrashRiskModelComponent,
		AboutComponent,
		PasswordResetComponent,
		RequestAccountComponent,
		MultiSelectAccordionComponent,
		BooleanYNPipe,
		TimeRangeSelectorComponent,
		FreshnessInfoLabelComponent,
    	JurisdictionSelectorComponent,
		FreshnessInfoFooterComponent,
		CurrentSelectionsLabelComponent,
		HelpTooltipComponent
  ],
  imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgMultiSelectDropDownModule.forRoot(),
		LoadingBarHttpClientModule,
		FormsModule,
		CommonModule,
		LeafletModule.forRoot(),
		NgxDatatableModule,
		NgbModule.forRoot(),
		FontAwesomeModule,
		ReactiveFormsModule,
		Ng5SliderModule,
		BrowserAnimationsModule
	],
	providers: [
		AccessGuard,
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		library.add(faSort);
		library.add(faSortDown);
		library.add(faSortUp);
		library.add(faMinusSquare);
		library.add(faCheckSquare);
		library.add(faArrowDown);
		library.add(faSquare);
		library.add(faCaretDown);
		library.add(faCaretRight);
		library.add(faQuestionCircle);
	}
}
