import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as d3 from 'd3';
import { geoJSON, icon, marker, tileLayer } from 'leaflet';
import { InvertibleGeoJSONOptions } from '../crash-mapping/crash-mapping-data';
import { CrashMappingComponent } from '../crash-mapping/crash-mapping.component';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class CrashRiskMappingService {
	private crashMinColor = 'rgb(74,161,82)';
	private crashMidColor = 'rgb(245,210,122)';
	private crashMaxColor = 'rgb(200,0,49)';

	private crashMinColorRelative = 'rgb(5, 0, 255)';
	private crashMidColorRelative = 'rgb(74,161,82)';
	private crashMaxColorRelative = 'rgb(200,0,49)';

	protected crashesByBarracks: any;
	private minCrashBarrack: number;
	private maxCrashBarrack: number;
	focusSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
	markerOnClick: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private zone: NgZone) {
	}

	generateHeatMap(barracksGeoJson, crashesRisk, recomputeMinMax, relativeHeat) {
		if ( barracksGeoJson && crashesRisk) {
			if(recomputeMinMax || !(this.maxCrashBarrack > 0)) {
				let baselines = _.values(_.map(crashesRisk, "baseline_crash_rate")).sort();
				let vals: number[];
				if(relativeHeat) {
					vals = _.values(_.map(crashesRisk, r => r.pred_crash_rate - r.baseline_crash_rate)).sort();
					// +/- 30%
					this.minCrashBarrack = -baselines[Math.floor(baselines.length * 0.9)] * 0.3;
					this.maxCrashBarrack = baselines[Math.floor(baselines.length * 0.9)] * 0.3;
				} else {
					vals = baselines;
					this.minCrashBarrack = 0;
					// calibrating so that top 10% of tiles are red
					this.maxCrashBarrack = vals[Math.floor(vals.length * 0.9)];
				}
			}

			barracksGeoJson.features = barracksGeoJson.features.map((f) => {
				if ( crashesRisk[f.properties.tile_id] !== undefined ) {
					f.properties.pred_crash_rate = crashesRisk[f.properties.tile_id].pred_crash_rate;
					f.properties.baseline_crash_rate = crashesRisk[f.properties.tile_id].baseline_crash_rate;
				} else {
					f.properties.crashes = null;
				}
				return f;
			});
		}
		this.buildMapLegend(relativeHeat);

		// add and style the geojson polygons
		// const inspInterpolator = d3.interpolateRgb(inspMinColor, inspMaxColor);
		let interp1, interp2;
		if(relativeHeat) {
			interp1 = d3.interpolateRgb(this.crashMinColorRelative, this.crashMidColorRelative);
			interp2 = d3.interpolateRgb(this.crashMidColorRelative, this.crashMaxColorRelative);
		} else {
			interp1 = d3.interpolateRgb(this.crashMinColor, this.crashMidColor);
			interp2 = d3.interpolateRgb(this.crashMidColor, this.crashMaxColor);
		}
		const crashInterpolator = t => {
			return (t < 0.5 ? interp1(t * 2) : interp2((t - 0.5) * 2));
		};

		const leafletLayers: any = [
			tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 16, minZoom: 6}),
			geoJSON(barracksGeoJson, {
				invert: false,
				style: (feature) => {
					let color: string;
					if(relativeHeat) {
						color = this.safeInterpolate(feature.properties.pred_crash_rate - feature.properties.baseline_crash_rate,
							this.minCrashBarrack, this.maxCrashBarrack,
							crashInterpolator, relativeHeat);
					} else {
						color = this.safeInterpolate(feature.properties.pred_crash_rate,
							this.minCrashBarrack, this.maxCrashBarrack,
							crashInterpolator, relativeHeat);
					}


					return {
						color: 'black',
						fillColor: color,
						opacity: 1,
						weight: 0.6,
						fillOpacity: 0.7
					};
				},
				onEachFeature: (feature, layer) => {
					layer.on({
						// click: this.buildMapClickEvent(cmComponent),
						mouseover: (e: any) => {
							this.zone.run(() => {
								this.focusSubject.next({
									showingFeatureDiv: true,
									focusFeature: feature
								});
							})
						},
						mouseout: (e) => {
							this.zone.run(() => {
								this.focusSubject.next({
									showingFeatureDiv: false,
								});
							});
						}
					});
				},
			} as InvertibleGeoJSONOptions)
		];
		return leafletLayers;

	}

	addMarkers(crashes) {
		const leafletLayers: any = [
			tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
			})
		];
		crashes.forEach((gp) => {
			leafletLayers.push(marker([+gp.latitude, +gp.longitude], {
				icon: icon({
					iconSize: [12, 20],
					iconAnchor: [6, 19],
					iconUrl: 'assets/marker-icon.png',
					// shadowUrl: 'assets/marker-shadow.png'
				}),
				clickable: true,
			}).on('click', (e) => {
				this.markerOnClick.next(e);
			}));
		});
		return leafletLayers;
	}

	buildMapClickEvent(outerThis: CrashMappingComponent) {
		return (e: any) => {
			const selectedBarracksName = e.sourceTarget.feature.properties.barrack;
			const selectedBarracks = outerThis.nameIndexedBarracksOptions[selectedBarracksName];
			if ( e.originalEvent.ctrlKey && outerThis.selectedBarracks != undefined ) {
				outerThis.selectedBarracks.push(selectedBarracks);
				outerThis.selectedBarracks = [...outerThis.selectedBarracks];
			} else {
				outerThis.selectedBarracks = [selectedBarracks];
			}
			// this.mapOptions.center = latLng(e.sourceTarget.feature.geometry.coordinates[0][0].lat, e.sourceTarget.feature.geometry.coordinates[0][0].lang);
			// this.mapOptions.zoom = 9;
			// this.mapOptionsSubject.next(this.mapOptions);
		};

	}

	safeInterpolate(val: number, minVal: number, maxVal: number, interpolator: any, relativeHeat: boolean) {
		const featurePct = (val - minVal) / (maxVal - minVal);
		let color: string;
		if ( val >= maxVal ) {
			color = interpolator(1);
		} else if ( val == 0 && !relativeHeat) {
			color = interpolator(0);
		} else {
			color = interpolator(featurePct);
		}
		return color;
	}

	buildMapLegend(relativeHeat) {
		const w = 100;
		const h = 350;
		document.getElementById('map-legend-container').innerHTML = '<br>';
		// d3.select('#').selectAll('*').remove();
		const key = d3.select('#map-legend-container').append('svg')
			.attr('width', w)
			.attr('height', h)
			.attr('class', 'legend');

		const defs = key.append('defs');

		const buildSingleLegend = (
			minColor: string, midColor: string, maxColor: string,
			lowValue: number, highValue: number,
			title: string, yoffset: number) => {
			const componentHeight = (h - 50);
			const legend = defs.append('svg:linearGradient')
				.attr('id', 'gradient' + title.replace(" ", ""))
				.attr('x1', '100%')
				.attr('y1', '0%')
				.attr('x2', '100%')
				.attr('y2', '100%')
				.attr('spreadMethod', 'pad');

			legend.append('stop')
				.attr('offset', '0%')
				.attr('stop-color', maxColor)
				.attr('stop-opacity', 1);

			legend.append('stop')
				.attr('offset', '50%')
				.attr('stop-color', midColor)
				.attr('stop-opacity', 1);

			legend.append('stop')
				.attr('offset', '100%')
				.attr('stop-color', minColor)
				.attr('stop-opacity', 1);

			key.append('text')
				.html(title)
				.attr('y', 15 + yoffset);

			key.append('rect')
				.attr('width', w - 50)
				.attr('height', componentHeight)
				.style('fill', 'url(#gradient' + title.replace(" ", "") + ')')
				.attr('transform', 'translate(0,' + (yoffset + 20) + ')');

			const y = d3.scaleLinear()
				.range([componentHeight, 0])
				.domain([lowValue, highValue]);

			let formatPercent = d3.format('.2%');
			const yAxis = d3.axisRight(y).ticks(5).tickFormat(formatPercent);

			key.append('g')
				.attr('class', 'y axis')
				.attr('transform', 'translate(51,' + (20 + yoffset) + ')')
				.call(yAxis);
		};

		if(relativeHeat) {
			buildSingleLegend(this.crashMinColorRelative, this.crashMidColorRelative, this.crashMaxColorRelative,
				this.minCrashBarrack, this.maxCrashBarrack, 'Rate Change', 0);
		} else {
			buildSingleLegend(this.crashMinColor, this.crashMidColor, this.crashMaxColor,
				this.minCrashBarrack, this.maxCrashBarrack, 'Rate', 0);
		}
	}

}
