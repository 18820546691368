import {
  CommonService,
  IBarracksInfo,
  IDataFreshnessInfo,
  IMonthInfo,
  IJurisdictionInfo, ICountyInfo, ITimeRangeInfo
} from './common.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  AfterViewChecked,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import {latLng} from 'leaflet';
import { BehaviorSubject } from 'rxjs';

declare var vega: any;
declare var vegaTooltip: any;
declare var vegaEmbed: any;

export class BaseComponent implements AfterViewChecked, OnDestroy {
  commonService: CommonService;

  countyDropdownSettings = {
    singleSelection: false,
    itemsShowLimit: 1,
    idField: "county_fips",
    textField: "county_name",
    allowSearchFilter: true
  };

  countyMultiSelectOptions = [];

  monthOptions: IMonthInfo[] = [
    {name: 'January', nameAbbrev: 'Jan', number: 1, fiscalNumber: 4, zbNumber: 0},
    {name: 'February', nameAbbrev: 'Feb', number: 2, fiscalNumber: 5, zbNumber: 1},
    {name: 'March', nameAbbrev: 'Mar', number: 3, fiscalNumber: 6, zbNumber: 2},
    {name: 'April', nameAbbrev: 'Apr', number: 4, fiscalNumber: 7, zbNumber: 3},
    {name: 'May', nameAbbrev: 'May', number: 5, fiscalNumber: 8, zbNumber: 4},
    {name: 'June', nameAbbrev: 'Jun', number: 6, fiscalNumber: 9, zbNumber: 5},
    {name: 'July', nameAbbrev: 'Jul', number: 7, fiscalNumber: 10, zbNumber: 6},
    {name: 'August', nameAbbrev: 'Aug', number: 8, fiscalNumber: 11, zbNumber: 7},
    {name: 'September', nameAbbrev: 'Sep', number: 9, fiscalNumber: 12, zbNumber: 8},
    {name: 'October', nameAbbrev: 'Oct', number: 10, fiscalNumber: 1, zbNumber: 9},
    {name: 'November', nameAbbrev: 'Nov', number: 11, fiscalNumber: 2, zbNumber: 10},
    {name: 'December', nameAbbrev: 'Dec', number: 12, fiscalNumber: 3, zbNumber: 11},
  ];

  daysOptions = [
    'Mon',
    'Tue',
    'Wed',
    'Thr',
    'Fri',
    'Sat',
    'Sun'
  ];

  timeHours = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ];

  hourOptions = [
    {"text": '00:00', "id": 0},
    {"text": '01:00', "id": 1},
    {"text": '02:00', "id": 2},
    {"text": '03:00', "id": 3},
    {"text": '04:00', "id": 4},
    {"text": '05:00', "id": 5},
    {"text": '06:00', "id": 6},
    {"text": '07:00', "id": 7},
    {"text": '08:00', "id": 8},
    {"text": '09:00', "id": 9},
    {"text": '10:00', "id": 10},
    {"text": '11:00', "id": 11},
    {"text": '12:00', "id": 12},
    {"text": '13:00', "id": 13},
    {"text": '14:00', "id": 14},
    {"text": '15:00', "id": 15},
    {"text": '16:00', "id": 16},
    {"text": '17:00', "id": 17},
    {"text": '18:00', "id": 18},
    {"text": '19:00', "id": 19},
    {"text": '20:00', "id": 20},
    {"text": '21:00', "id": 21},
    {"text": '22:00', "id": 22},
    {"text": '23:00', "id": 23}
  ];

  fiscalOrderMonthOptions: IMonthInfo[] = _.sortBy(this.monthOptions, 'fiscalNumber');

  dataFreshnessInfoSubject: BehaviorSubject<IDataFreshnessInfo | null>;

  barracksOptions: IBarracksInfo[];
  troopOptions: IBarracksInfo[];
  selectedBarracks: IBarracksInfo[];
  selectedRegions: IJurisdictionInfo = { type: 'barracks', barracks: [], counties: [] };
  selectedCounties = [];
  inspectionLevels:{value:number,name: string}[] = [];

  indexedBarracksOptions: { [id: string]: IBarracksInfo } = {};
  nameIndexedBarracksOptions: { [name: string]: IBarracksInfo } = {};
  troopIndexedBarracks: { [name: string]: IBarracksInfo[] } = {};

  yearOptions: number[];
  yearTypeOptions: string[];

  selectedYearType: string;
  selectedYear: number;
  timeRange: ITimeRangeInfo = null;
  tooltipHandler: any;
  countyOptions: any = [];

  barracksDropdownSettings = {
    idField: 'name',
    textField: 'name',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  vehicleTypeOptions: string[];
  selectedVehicleType: string;
  hasError = false;
  emptyResults = false;

  selectedState: string;
  stateOptions = ['MA', 'WV', 'MD', 'VA'];

  stateSettings: any;

  stateLeafletOptions = _.mapValues(this.stateSettings, "leafletOptions");
  showTroopHierarchyByState = _.mapValues(this.stateSettings, "showTroopHierarchy");

  injuryOptions = ['All', 'Fatal Injury', 'Non-Fatal Injury', 'No Injury / Towaway'];
  injuryMultiSelectOptions = ['Fatal Injury', 'Non-Fatal Injury', 'No Injury / Towaway'];
  selectedInjuryStatus: string | string[] = 'All';
  highlightsOptions: string[] = ['Non-Motorist Related',
    'Younger Driver Related',
    'Older Driver Related',
    'Rollover',
    'HazMat',
    'Work Zone',
    'Bus Related',
    'Distraction Related',
    'Alcohol/Drugs Related'
  ];
  errorMessage: string;
  @ViewChild('fixedSubHeader') fixedSubHeader: ElementRef;
  @ViewChild('placeholderHeader') placeholderHeader: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setPlaceholderHeight();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.toggleScrollIndicator();
  }

  constructor(commonService: CommonService) {
    this.commonService = commonService;
    this.dataFreshnessInfoSubject = new BehaviorSubject<IDataFreshnessInfo | null>(null);
    this.stateSettings = commonService.stateSettings;
    this.stateLeafletOptions = _.mapValues(this.stateSettings, "leafletOptions");
    this.showTroopHierarchyByState = _.mapValues(this.stateSettings, "showTroopHierarchy");
    this.setState();
  }

  ngOnInit() {
    this.tooltipHandler = new vegaTooltip.Handler();

    this.commonService.getBarracks().subscribe((b) => {
      this.barracksOptions = _.sortBy(b.all, i => i.name);
      this.troopOptions = _.sortBy(b.troops, i => i.name).map(a => {
          a.barracks = _.sortBy(a.barracks, i => i.name);
          return a;
        });
      this.barracksOptions.forEach(v => {
        v.indent = v.type == 'barracks';
      });
      this.indexedBarracksOptions = _.keyBy(this.barracksOptions, 'barracks_id');
      this.nameIndexedBarracksOptions = _.keyBy(this.barracksOptions, 'name');
      this.troopIndexedBarracks = _.groupBy(this.barracksOptions, 'troop');
    });
    this.selectedBarracks = [];

    this.commonService.getCounties().subscribe((d) => {
      this.countyOptions = _.mapValues(_.groupBy(d, "state_abbrev"), (v) => _.sortBy(v, v => v.county_name));
    });

    this.selectedYear = moment().year();
    this.selectedYearType = 'Fiscal';

    this.commonService.getAvailableYears().subscribe((d) => {
      this.selectedYear = d[d.length - 1];
      this.yearOptions = d;
    });
    this.yearTypeOptions = ['Fiscal', 'Calendar'];

    this.vehicleTypeOptions = ['Large Truck', 'Bus', 'All CMVs'];
    this.selectedVehicleType = 'All CMVs';
    this.syncFilterBarCache();
  }

  syncFilterBarCache(cacheIt?: boolean): void {
    if (cacheIt) {
      const cache = {
        selectedState: this.selectedState,
        selectedRegions: this.selectedRegions,
        selectedVehicleType: this.selectedVehicleType,
        inspectionLevels: this.inspectionLevels,
        selectedInjuryStatus: this.selectedInjuryStatus,
        timeRange: this.timeRange,
        selectedYearType: this.selectedYearType,
      };
      localStorage.filterBar = JSON.stringify(cache);
    } else {
      if (!localStorage.filterBar) return;
      const cache = JSON.parse(localStorage.filterBar);
      this.selectedState = cache.selectedState;
      this.selectedRegions = cache.selectedRegions;
      this.selectedVehicleType = cache.selectedVehicleType;
      this.inspectionLevels = cache.inspectionLevels;
      this.selectedInjuryStatus = cache.selectedInjuryStatus;
      this.timeRange = cache.timeRange;
      this.selectedYearType = cache.selectedYearType;
    }
  }

  performCommonChangeHandling() {
    this.updateStateLocalStorage();
    this.syncFilterBarCache(true);
    this.dataFreshnessInfoSubject = this.commonService.getDataFreshnessInfo(this.selectedState);
    this.dropInvalidJurisdictionSelections();
  }

  dropInvalidJurisdictionSelections() {
    if(this.selectedRegions.type == "barracks") {
      var newBarracks = _.filter(this.selectedRegions.barracks, b => b.state == this.selectedState);
      if(newBarracks.length != this.selectedRegions.barracks.length) {
        this.selectedRegions = {
          type : "barracks",
          barracks : newBarracks,
          counties : []
        };
      }
    } else {
      var newCounties = _.filter(this.selectedRegions.counties, b => b.state_abbrev == this.selectedState);
      if(newCounties.length != this.selectedRegions.counties.length) {
        this.selectedRegions = {
          type : "counties",
          barracks : [],
          counties : newCounties
        };
      }
    }
  }

  ngAfterViewChecked() {
    this.setPlaceholderHeight();
    this.toggleScrollIndicator();
  }

  getBarracksLabel(selectedState) {
    return this.stateSettings[selectedState].barracksLabel;
  }

  getTroopBarracksLabel(selectedState) {
    return this.stateSettings[selectedState].troopBarracksLabel;
  }

  stateSupportsLocalStateBreakdown(selectedState) {
    return this.stateSettings[selectedState].supportsLocalStateBreakdown;
  }

  stateSupportsTownMapView(selectedState) {
    return this.stateSettings[selectedState].supportsTownMapView;
  }

  getTroopOptions(selectedState) {
    return _.filter(this.troopOptions, t => t.state == selectedState);
  }

  getRegionOption(): IJurisdictionInfo {
    this.commonService.selectedState = this.selectedState;
    return {
      barracks: this.getTroopOptions(this.selectedState),
      counties: this.countyOptions[this.selectedState]
    } as IJurisdictionInfo;
  }

  getSelectedRegionBoundary(selectedRegion, geoJson) {
    let coordinates = [];
    selectedRegion.forEach((s) => {
      geoJson.features.forEach((bg) => {
        if (this.matchRegion(s,bg)) {
          if(bg.geometry.type == "Polygon") {
            coordinates = coordinates.concat(bg.geometry.coordinates[0]);
          } else if(bg.geometry.coordinates.length[0] > 1 ){
            coordinates = coordinates.concat(bg.geometry.coordinates.flatMap((c) => {
              if( c.length > 2 ) {
                return c.flatMap( f => f );
              }
              return c;
            }));
          } else {
            coordinates = coordinates.concat(bg.geometry.coordinates[0][0]);
          }
        }
      });
    });

    // correcting lang/lat order
    // @ts-ignore
    return coordinates.flatMap((c) => {
      if(c.length > 2) {
        return c;
      } else return [c];
    }).map((c) => {
      return [c[1], c[0]];
    });
  }

  matchRegion(region, feature){
    return ((region.county_name && feature.properties.NAME === region.county_name) ||
      (region.barracks_id && feature.properties.barrack == region.barracks_id));
  }

  setSelectedRegionsValue(value: IBarracksInfo[]|ICountyInfo[] = [], type?){
    type = type || this.selectedRegions.type;
    this.selectedRegions = {
      type,
      barracks: this.selectedRegions.type === 'barracks' ? (value as IBarracksInfo[]) : [],
      counties: this.selectedRegions.type === 'counties' ? (value as ICountyInfo[]) : []
    }
  }

  supportsExtendedInspectionFields(selectedState) {
    return this.stateSettings[selectedState].supportsExtendedInspectionFields;
  }

  /** Returns a pair [year, quarter#] */
  getFiscalQuarterForDate(d: Date) {
    let refDate = moment(d).add(3, 'months');
    return [refDate.year(), Math.floor(refDate.month() / 3) + 1];
  }

  /** Given a pair [year, quarter#] returns the start date of the fiscal quarter */
  getFiscalQuarterStartDate(quarter: number[]): Date {
    return moment([quarter[0], 0, 1]).add(-3, 'months').add((quarter[1] - 1) * 3, 'months').toDate();
  }

  getSelectedBarracksDisplay() {
    const selItemsDisplay = _.flatMap(this.troopOptions, t => {
      if (t.selectedBarracks.length == t.barracks.length) {
        return [t];
      } else {
        return t.selectedBarracks;
      }
    });
    const displayNames = _.map(selItemsDisplay, 'name');
    const selBarracksCount = _.filter(this.barracksOptions, b => b.selected).length;

    if (displayNames.length > 5) {
      return selBarracksCount + ' Barracks Selected';
    } else {
      return displayNames.join(', ');
    }
  }

  setupVegaView(specUrl: string, container: ElementRef, viewPropName: string, height: number = 300): Promise<any> {
    return vegaEmbed('#' + container.nativeElement.id, specUrl, {
      width: container.nativeElement.offsetWidth - 50,
      height,
      renderer: 'svg',
      actions: false
    }).then(data => {
      this[viewPropName] = data.view;
      // when the container element is resized, resize the Vega view
      const ro = new ResizeObserver(entries => {
        for (const entry of entries) {
          const newWidth = (entry.target as HTMLElement).offsetWidth - 75;
          this[viewPropName].width(newWidth).runAsync();
        }
      });
      ro.observe(container.nativeElement.parentNode);
      return this;
    }, err => {
      this.handleError(err);
      throw err;
    });
  }

  clearError() {
    this.hasError = false;
  }

  handleError(errResp: any) {
    console.error(errResp);
    this.hasError = true;
    if (errResp.error != undefined && errResp.error.message != undefined) {
      this.errorMessage = errResp.error.message;
    } else {
      this.errorMessage = 'An unexpected error has occurred';
    }
  }

  setPlaceholderHeight() {
    if (this.placeholderHeader && this.fixedSubHeader) {
      this.placeholderHeader.nativeElement.style.height = this.fixedSubHeader.nativeElement.offsetHeight + 'px';
    }
  }


  toggleScrollIndicator() {
    const topScroll = window.pageYOffset || document.documentElement.scrollTop;
    const documentHeight =
      Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );
    const scrollIndicator = topScroll + window.innerHeight < documentHeight;
    const scrollElement = document.getElementById('scrollIndicator');
    if (scrollIndicator) {
      scrollElement.style.display = 'block';
    } else {
      scrollElement.style.display = 'none';
    }
  }

  resetSelectionValues() {
    // this.selectedState = this.stateOptions[0];
    this.setState();
    this.selectedRegions = {
      type: this.selectedRegions.type,
      barracks: [],
      counties: []
    }
    this.selectedBarracks = [];
    this.selectedInjuryStatus = [];
    this.selectedVehicleType = 'All CMVs';
    this.selectedYear = this.yearOptions ? this.yearOptions[this.yearOptions.length - 1] : moment().year();
    this.selectedYearType = 'Fiscal';
    this.timeRange = null;
    this.inspectionLevels = [];
  }

  updateStateLocalStorage() {
    if(this.selectedState) {
      localStorage.selectedState = this.selectedState;
    } else if(localStorage.selectedState){
      this.selectedState = localStorage.selectedState;
    } else {
      localStorage.selectedState = this.selectedState = 'MA';
    }
  }

  setState() {
    this.updateStateLocalStorage();
    this.setSelectedRegionsValue();
  }

  /**
   * Checks that the data value is accepted by a filter form element
   * @param formItem The form field to check the data value against (e.g. day of week)
   * @param dataItem The data value to be checked (e.g. Monday)
   * @param compareSettings Either a string defining the property on the form field to check against,
   * or a ng-multi-select compatible dropdown settings object
   */
  checkSelectedValue(formItem, dataItem, compareSettings) {
    if (formItem && formItem.length > 0) {
      let compareKey: string;
      if (compareSettings != undefined) {
        if (typeof (compareSettings) == "string") {
          compareKey = compareSettings;
        } else {
          compareKey = compareSettings.idField;
        }

        if (!formItem[0].hasOwnProperty(compareKey)) {
          throw `property '${compareKey}' does not exist on reference field`;
        }

        let matchedItem = formItem.find((b) => {
          return (dataItem === b[compareKey]);
        });
        return matchedItem != undefined;
      } else {
        // form item is primitive type
        return formItem.find(b => b == dataItem) != undefined;
      }
    }
    return true;
  }


  ngOnDestroy(): void {
    document.getElementById('scrollIndicator').style.display = 'none';
  }


}
