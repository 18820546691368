import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  httpClient:HttpClient;

  constructor(httpClient:HttpClient) {  
    this.httpClient = httpClient;
   }

  getSummaryStats() {
    return this.httpClient.get(environment.apiUrl + "/home-summary-stats");
  }
}
