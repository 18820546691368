import { Component, OnInit, Input } from '@angular/core';
import { IDataFreshnessInfo } from '../common.service';

@Component({
  selector: 'app-freshness-info-label',
  templateUrl: './freshness-info-label.component.html'
})
export class FreshnessInfoLabelComponent implements OnInit {

  @Input() dataFreshnessInfo:IDataFreshnessInfo;
  vehicleType:string;

  constructor() { }

  ngOnInit() {
  }
}