import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { InspectionDataDownloadService } from './inspection-data-download.service';

@Component({
	selector: 'app-inspection-mapping-datatable',
	templateUrl: './inspection-mapping-datatable.component.html',
	styleUrls: ['./inspection-mapping-datatable.component.css']
})
export class InspectionMappingDatatableComponent implements OnInit {
	@Input() inspectionData = Array(5);
	public detailToggle = {};
	@Input() barracksLabel: string;

	constructor(private downloadDataService: InspectionDataDownloadService) {
	}

	ngOnInit() {
	}

	download() {
		this.downloadDataService.generateCSV(this.inspectionData);
	}

	get slicedInspectionData() {
		if ( this.inspectionData ) {
			const slicedInspections = this.inspectionData.slice(0, 50);
			
			return slicedInspections;
		}
		return [];
	}


}
