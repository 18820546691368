import {Component} from '@angular/core';

import {AuthService} from './auth/auth.service';
import {Router, NavigationEnd} from '@angular/router';
import {CommonService} from "./common/common.service";

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  notificationSeen = false;
  constructor(authService: AuthService, router: Router, private commonService: CommonService) {
    this.authService = authService;
    this.router = router;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.authService.userInfo != undefined && this.authService.userInfo.username != undefined) {
          gtag('set', {'user_id': this.authService.userInfo.username});
        }
        gtag('config', 'UA-75723572-3', {'page_path': event.urlAfterRedirects});
      }
    });
  }


  get fixedNavBar() {
    return this.router.url.indexOf('crash') > -1 || this.router.url.indexOf('inspection') > -1;
  }

  title = 'data-portal';
  authService: AuthService;
  router: Router;

  public isNavbarCollapsed = false;


  scrollDown() {
    window.scrollBy({left: 0, top: window.innerHeight, behavior: 'smooth'});
  }


  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
