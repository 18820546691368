import {Injectable} from '@angular/core';
import {geoJSON, icon, marker, tileLayer} from 'leaflet';
import * as d3 from 'd3';
import {InvertibleGeoJSONOptions} from './inspection-mapping-data';
import {InspectionMappingComponent} from './inspection-mapping.component';
import {BehaviorSubject} from 'rxjs/index';
import {CommonService, IJurisdictionKeysInfo} from "../common/common.service";
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class InspectionMappingMapService {
	private inspectionMinColor = 'rgb(197,229,222)';
	private inspectionMidColor = 'rgb(245,210,122)';
	private inspectionMaxColor = 'rgb(92,121,157)';
	protected inspections: any;
	protected inspectionsBkp: any = {};
	protected minInspection: any;
	protected maxInspection: any;
	regionSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
	focusSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

	constructor(private commonService: CommonService) {
	}

  onInputChange(e){

  }

	generateHeatMap(geoJsonData, imComponent: InspectionMappingComponent, barracksType?) {
    const jurisdictionKeys = this.commonService.getJurisdictionKeys(barracksType);
    if(!imComponent.inspectionsForMapping) return;
    this.inspectionsBkp = this.calculateInspections(imComponent.inspectionsForMapping, jurisdictionKeys, true);
    this.buildMapLegend(barracksType);
		if (geoJsonData && this.inspectionsBkp && !_.isEmpty(this.inspectionsBkp)) {
			geoJsonData.features = geoJsonData.features.map((f) => {
				if (this.inspectionsBkp.dataByCount[f.properties[jurisdictionKeys.idKey]] !== undefined) {
					f.properties.inspections = this.inspectionsBkp.dataByCount[f.properties[jurisdictionKeys.idKey]].length;
					f.properties.name  = f.properties[jurisdictionKeys.nameKey];
					f.properties.state_abbrev  = imComponent.selectedState;
				} else {
					f.properties.inspections = null;
				}
				return f;
			});
			// add and style the geojson polygons
			// const inspInterpolator = d3.interpolateRgb(inspMinColor, inspMaxColor);
			const interpolator = d3.interpolateRgb(this.inspectionMinColor, this.inspectionMaxColor);
			// const interp2 = d3.interpolateRgb(this.inspectionMidColor, this.inspectionMaxColor);
			// const inspectionInterpolator = t => {
			// 	return (t < 0.5 ? interp1(t * 2) : interp2((t - 0.5) * 2));
			// };
      const selectedRegions = imComponent.selectedRegions.type === 'barracks' ? imComponent.filterForm.value.barracks : imComponent.filterForm.value.counties;
      const leafletLayers: any = [
				tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 10, minZoom: 4}),
				geoJSON(geoJsonData, {
					invert: true,
					style: {
						color: '#999999',
						weight: 5,
						opacity: 0,
						fillOpacity: imComponent.selectedRegions.type ? 0.02 : 0.002,
					}
				} as InvertibleGeoJSONOptions),
				geoJSON(geoJsonData, {
					invert: false,
					style: (feature) => {
            const color = this.safeInterpolate(feature.properties.inspections,
							this.inspectionsBkp.min, this.inspectionsBkp.max,
							interpolator);
						// TODO Implement for TOwns as well
						let  fillOpacity = 0.9;
						let opacity = 1;
						if (selectedRegions.length > 0 && !selectedRegions.find((b) => imComponent.matchRegion(b, feature))) {
              opacity = 0.4;
              fillOpacity = 0.4;
						}
						// else if (imComponent.regionsGeoJson.length > 0) {
						// 	regionExists = imComponent.regionsGeoJson.find((t) => {
						// 		return t.name === feature.properties.TOWN_ID;
						// 	});
						// 	fillOpacity = 0.4;
						// }
            // console.log(fillOpacity, imComponent.selectedRegions);
						return {
							color: 'black',
							fillColor: color,
							opacity,
							weight: 1,
							fillOpacity,
						};
					},
					onEachFeature: (feature, layer) => {
						layer.on({
              click: ((e) =>  this.regionSubject.next(this.commonService.mapClickFilter(e, imComponent.selectedRegions, imComponent.indexedBarracksOptions))),
							mouseover: (e: any) => {
                this.focusSubject.next({
                  showingFeatureDiv: true,
                  focusFeature: feature
                });

                // if ( selectedRegions.length > 0 &&
                //       !selectedRegions.find((b) => {
                //         return ((b.county_name && feature.properties.NAME === b.county_name) ||
                //           (b.name && feature.properties.barracks_name === b.name));
                //
                //      })
                // ) ||
                //   (imComponent.regionsGeoJson.length > 0 &&
                //     !imComponent.regionsGeoJson.find((t) => {
                //         return t.name === feature.properties.TOWN_ID;
                //    }))
                // ) {
                //   return;
                // }
								// running with zone to make sure changes trigger databinding
							},
							mouseout: (e) => {
								this.focusSubject.next({
									showingFeatureDiv: false,
								});
							}
						});

						if (imComponent.filterForm.value.barracks.length > 0) {
							const barrackExists = imComponent.filterForm.value.barracks.find(b => imComponent.matchRegion(b, feature));
							if (!barrackExists) {
								return;
							}
						}
					},
				} as InvertibleGeoJSONOptions)
			];
			return leafletLayers;
		}
	}

	addMarkers(inspections) {
		const leafletLayers: any = [
			tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
			})
		];
		inspections.forEach((gp) => {
			leafletLayers.push(marker([+gp.latitude, +gp.longitude], {
				icon: icon({
					iconSize: [12, 20],
					iconAnchor: [6, 19],
					iconUrl: 'assets/marker-icon.png',
					// shadowUrl: 'assets/marker-shadow.png'
				})
			}));
		});
		return leafletLayers;
	}


	safeInterpolate(val: number, minVal: number, maxVal: number, interpolator: any) {
		const featurePct = (val - minVal) / (maxVal - minVal);
		let color: string;
		if (val === maxVal) {
			color = interpolator(1);
		} else if (val === 0) {
			color = interpolator(0);
		} else {
			color = interpolator(featurePct);
		}
		return color;
	}

	calculateInspections(inspections, jurisdictionKeys: IJurisdictionKeysInfo, returnValue?) {
	  if(!inspections) return;
		const calculatedData = this.commonService.getMinMax(inspections, jurisdictionKeys.inspectionKey);

    if( returnValue ){
		  return calculatedData;
    }
		this.inspections = calculatedData.dataByCount;
		// barrackInspections = this.getMinMax(inspectionsBkp, 'barracks_name');
		this.minInspection = calculatedData.min;
		this.maxInspection = calculatedData.max;
	}


	buildMapLegend(barracksType?) {
		const w = 100;
		const h = 200;
		document.getElementById('map-legend-container').innerHTML = '<br>';
		// d3.select('#').selectAll('*').remove();
		const key = d3.select('#map-legend-container').append('svg')
			.attr('width', w)
			.attr('height', h)
			.attr('class', 'legend');

		const defs = key.append('defs');

		const buildSingleLegend = (
			minColor: string, maxColor: string,
			lowValue: number, highValue: number,
			title: string, yoffset: number) => {
			const componentHeight = (h - 50);
			const legend = defs.append('svg:linearGradient')
				.attr('id', 'gradient' + title)
				.attr('x1', '100%')
				.attr('y1', '0%')
				.attr('x2', '100%')
				.attr('y2', '100%')
				.attr('spreadMethod', 'pad');

			legend.append('stop')
				.attr('offset', '0%')
				.attr('stop-color', maxColor)
				.attr('stop-opacity', 1);

			// legend.append('stop')
			// 	.attr('offset', '50%')
			// 	.attr('stop-color', midColor)
			// 	.attr('stop-opacity', 1);

			legend.append('stop')
				.attr('offset', '100%')
				.attr('stop-color', minColor)
				.attr('stop-opacity', 1);

			key.append('text')
				.html(title)
				.attr('y', 15 + yoffset);

			key.append('rect')
				.attr('width', w - 50)
				.attr('height', componentHeight)
				.style('fill', 'url(#gradient' + title + ')')
				.attr('transform', 'translate(0,' + (yoffset + 20) + ')');

			const y = d3.scaleLinear()
				.range([componentHeight, 0])
				.domain([lowValue, highValue]);

			const yAxis = d3.axisRight(y).ticks(5);

			key.append('g')
				.attr('class', 'y axis')
				.attr('transform', 'translate(51,' + (20 + yoffset) + ')')
				.call(yAxis);
		};

			buildSingleLegend(this.inspectionMinColor, this.inspectionMaxColor,
				this.inspectionsBkp.min, this.inspectionsBkp.max, 'Inspections', 0);
	}

}
