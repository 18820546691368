import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { IBarracksInfo, CommonService } from '../common/common.service';
import * as L from 'leaflet';
import { Observable } from 'rxjs';
import * as _ from "lodash";
import * as d3 from 'd3';
import * as moment from 'moment';
import ResizeObserver from 'resize-observer-polyfill';
import { BaseComponent } from '../common/common.component';

declare var vega: any;
declare var vegaTooltip: any;

@Component({
  selector: 'app-crash-location',
  templateUrl: './crash-location.component.html',
  styleUrls: ['./crash-location.component.css']
})
export class CrashLocationComponent extends BaseComponent implements OnInit {

  id: number;
  commonService: CommonService;
  zone: NgZone;

  crashStats:any;

  inspMinColor = "#1e2e44";
  inspMaxColor = "#67acf6";
  crashMinColor = "rgb(21,184,0)";
  crashMaxColor = "rgb(220,17,15)";  

  constructor(commonService:CommonService, zone: NgZone) {
    super(commonService);
    this.zone = zone;
  }

  onInputChange(event) {
    this.refreshCrashData();
  }  

  refreshCrashData() {

  }

  ngOnInit() {
    super.ngOnInit();
    var tooltip = new vegaTooltip.Handler();  
  }

}
