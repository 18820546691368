/* tslint:disable:align */
import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ICarrier, IInspection, IRoute} from './inspection-mapping-data';
import {Subscription} from 'rxjs';
import {latLng, Map} from 'leaflet';
import {CommonService, IJurisdictionInfo} from '../common/common.service';
import {InspectionService} from '../inspection/inspection.service';
import {InspectionMappingMapService} from './inspection-mapping-map.service';
import * as _ from 'lodash';
import {BaseComponent} from '../common/common.component';
import {CrashService} from '../crash/crash.service';
import {ICrashFilterOptions} from '../crash-mapping/crash-mapping-data';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-inspection-mapping',
  templateUrl: './inspection-mapping.component.html',
  styleUrls: ['./inspection-mapping.component.css']
})
export class InspectionMappingComponent extends BaseComponent implements OnInit {
  filterForm: FormGroup;
  formValues: any;
  inspections: IInspection[];
  inspectionsForMapping: IInspection[];
  inspectionsBkp: any;
  leafletLayers: any[] = [];
  leafletHeatMapLayers = [];
  dataRequest: Subscription;
  regionsGeoJsonSubscription: Subscription;
  citiesGeoJsonSubscription: Subscription;
  lastReportStateValue: any;
  lastInspectionYearValue: any;
  lastInspectionDate: any;
  bodyInspectionData: any;
  truckRoutes: IRoute[];
  fieldLookups: ICrashFilterOptions;
  carriers: ICarrier[];
  // Variable to control filtering requests to backend if filteration can be done on frontend
  shouldFetch = true;
  vehicleTypeModel = 'All CMVs';
  enableHeatMap = false;
  regionsGeoJson: any;
  citiesGeoJson: any;
  mapType = 'barracks';
  heatmap: Map;

  leafletHeatMapOptions: any;
  leafletHeatMapOptionsCenter: any;
  leafletHeatMapOptionsZoom: any;

  yearsOptionsObject: {
    id: number,
    text: string
  }[];
  yearDropdownSettings = {
    idField: 'id',
    textField: 'text',
    singleSelection: false,
    itemsShowLimit: 1,
  };
  dowDropdownSettings = {
    idField: 'id',
    textField: 'text',
    singleSelection: false,
    itemsShowLimit: 1,
  };
  hourDropdownSettings = {
    idField: 'id',
    textField: 'text',
    singleSelection: false,
    itemsShowLimit: 1,
  };
  monthDropdownSettings = {
    idField: 'number',
    textField: 'name',
    itemsShowLimit: 1,
    allowSearchFilter: false
  };
  carriersDropdownSettings = {
    idField: 'carriername',
    textField: 'carriername',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    singleSelection: false,
    unSelectAllText: 'Deselect All'
  };
  highlightsDropdownSettings = {
    itemsShowLimit: 1,
    allowSearchFilter: false
  };
  truckRouteDropdownSettings = {
    idField: 'streetname',
    textField: 'streetname',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  interstateOptions = [
    {text: 'Interstate', id: 'Y'},
    {text: 'Intrastate', id: 'N'}
  ];

  interstateDropdownSettings = {
    idField: 'id',
    textField: 'text'
  };

  vehiclegvwrsDropdownSettings = {
    idField: 'gross_vehc_wght_ratg_code',
    textField: 'gross_vehc_wght_ratg_descr',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };

  highlightsObject = [
    {

      text: 'HazMat',
      id: 'hazmat',
    },
    {
      text: 'Result of Crash',
      id: 'result_of_crash'
    },
    {
      text: 'Traffic Enforcement',
      id: 'traffic_enforcement'
    }
  ];


  inspectionLevelOptionsObject = [
    {name: 'Level 1: Full Inspection', insptypelevelcode: 1},
    {name: 'Level 2: Walk-Around Inspection', insptypelevelcode: 2},
    {name: 'Level 3: Driver-Only Inspection', insptypelevelcode: 3},
    {name: 'Level 4: Special Study', insptypelevelcode: 4},
    {name: 'Level 5: Vehicle-Only Inspection', insptypelevelcode: 5},
    {name: 'Level 6: Radioactive Inspection', insptypelevelcode: 6},
    {name: 'Level 7: Small Pass. Vehicle Inspection', insptypelevelcode: 6},
  ];

  inspectionLevelDropdownSettings = {
    idField: 'insptypelevelcode',
    textField: 'name',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  resultingOosOptions = [
    //{name: 'Any', id: 'Any'},
    {name: 'Driver', id: 'Driver'},
    {name: 'Vehicle', id: 'Vehicle'},
    {name: 'None', id: 'None'}
  ];

  resultingOosDropdownSettings = {
    idField: 'id',
    textField: 'name'
  };

  violationsIssuedOptions = [
    //{name: 'Any', id: 'Any'},
    {name: 'Driver', id: 'Driver'},
    {name: 'Vehicle', id: 'Vehicle'},
    {name: 'None', id: 'None'}
  ];

  violationsIssuedDropdownSettings = {
    idField: 'id',
    textField: 'name'
  };

  inspectionDurations = [
    {name: '1-10 mins'},
    {name: '10-30 mins'},
    {name: '30-60 mins'},
    {name: '60+ mins'},
  ];

  inspectionDurationDropdownSettings = {
    idField: 'name',
    textField: 'name',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  highwayDropdownSettings = {
    idField: 'highwaydesctext',
    textField: 'highwaydesctext',
    allowSearchFilter: true,
    itemsShowLimit: 1
  };

  inspectionsByBarracks = {};
  focusFeature: any;

  constructor(commonService: CommonService,
              private inspectionService: InspectionService,
              private crashService: CrashService,
              private zone: NgZone,
              protected ims: InspectionMappingMapService,
              public formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef) {
    super(commonService);

  }

  refreshRoutes() {
    this.crashService.getRoutes(this.filterForm.value["report_state"]).subscribe((data) => {
      this.truckRoutes = _.orderBy(data, 'num_inspections', 'desc');
    });
  }

  refreshCarriers() {
    this.crashService.getCarriers(this.filterForm.value["report_state"]).subscribe((data) => {
      this.carriers = _.orderBy(data, 'num_inspections', 'desc');
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildFilterForm();
    this.refreshRoutes();
    this.refreshCarriers();

    this.crashService.getCrashFields().subscribe((data: ICrashFilterOptions) => {
      this.fieldLookups = data;
    });

    // this.updateGeoJson(this.filterForm.value.report_state);

    this.ims.regionSubject.subscribe((data: IJurisdictionInfo) => {
      if(data){
        this.selectedRegions = data;
      }
    });
    this.filterForm.valueChanges.subscribe(() => {
      this.processFormChanges();
    });
    this.ims.focusSubject.subscribe((data) => {
      this.zone.run(() => {
        this.focusFeature = data.focusFeature;
      });
    });
    this.commonService.getAvailableYears().subscribe((d) => {
      if (d) {
        this.yearsOptionsObject = d.map((y) => {
          return {
            id: y,
            text: y.toString()
          };
        });

        let useYear = moment().month() > 3 ? moment().year() : moment().year() - 1;
        this.filterForm.controls.inspection_year.setValue([{
          id: useYear,
          text: useYear.toString()
        }]);
        
        this.lastInspectionYearValue = this.filterForm.value.inspection_year;
        this.lastReportStateValue = this.selectedRegions.type+this.filterForm.value.report_state;  
      }
    });

    /* TODO require at least 9 months of history, needs work */
    /*this.commonService.getDataFreshnessInfo(this.filterForm.value.report_state).pipe(filter(x => x != null), take(1)).subscribe(s => {
      let lcd = moment(s.last_crash_date)
      let maxAvailableYear = lcd.month() >= 9 ? lcd.year() : lcd.year() - 1;
    });*/

  }

  processFormChanges() {
    if (this.yearsOptionsObject == undefined || !this.filterForm.value.report_state) {
      // skip fetching data until we get the date range info
      return;
    }

    if (this.lastInspectionYearValue !== this.filterForm.value.inspection_year ||
      this.lastReportStateValue !== this.selectedRegions.type + this.filterForm.value.report_state) {
      this.setState();

      if (this.lastReportStateValue !== this.selectedRegions.type + this.filterForm.value.report_state) {
        this.refreshRoutes();
        this.refreshCarriers();
        this.updateLeafletForState(this.filterForm.value.report_state);
      }

      let filterObj = {};
      if (this.filterForm.value.inspection_year) {
        filterObj = {inspection_year: this.filterForm.value.inspection_year.map(v => v.id)};
      }
      // @ts-ignore
      filterObj.report_state = this.filterForm.value.report_state;
      this.lastInspectionYearValue = this.filterForm.value.inspection_year;
      this.lastReportStateValue = this.selectedRegions.type+this.filterForm.value.report_state;
      this.fetchData(filterObj);
    } else {
      this.filterData();
    }
  }

  updateGeoJson(state, region = 'barracks') {
    this.ims.calculateInspections(this.inspections, this.commonService.getJurisdictionKeys(region));
    if (this.lastReportStateValue != this.selectedRegions.type+state || this.selectedRegions.type !== region) {
      this.regionsGeoJsonSubscription = this.commonService.getRegionsGeoJson(this.filterForm.value.report_state, region).subscribe((data: any) => {
        this.regionsGeoJson = data;
        if (this.selectedRegions.type === 'counties') {
          this.countyMultiSelectOptions = data.features.map((c) => {
            return {
              county_name: c.properties.NAME,
              state_abbrev: this.filterForm.value.report_state

            };
          });
        }
        this.leafletHeatMapLayers = this.ims.generateHeatMap(this.regionsGeoJson, this, this.selectedRegions.type);
      });
    } else {
      this.leafletHeatMapLayers = this.ims.generateHeatMap(this.regionsGeoJson, this, this.selectedRegions.type);
    }
  }

  updateLeafletForState(state: string) {
    if (this.lastReportStateValue != this.selectedRegions.type+state) {
      this.updateGeoJson(state, this.selectedRegions.type);
      this.resetMapView(state);

      if (!this.stateSupportsTownMapView(state) && this.selectedRegions.type === "counties") {
        this.toggleMapView('counties');
      }

      if (this.heatmap) {
        setTimeout(this.heatmap.invalidateSize);
      }

    }

    this.lastReportStateValue = this.selectedRegions.type+state;
  }

  buildFilterForm() {
    this.filterForm = this.formBuilder.group({
      report_state: this.selectedState,
      counties: [],
      barracks: [],
      towns: [],
      vehicle_type: ['All CMVs'],
      inspection_year: [],
      inspection_month: [],
      inspection_level: [],
      inspection_duration_bucket: [],
      carrier: [],
      highlights: [],
      dow: [],
      hour: [],
      gwvr_type: [],
      interstate: [],
      any_crash_related: null,
      any_out_of_service: null,
      traffic_enforcement: null,
      hazmat: null,
      violations_issued: [],
      resulting_oos: [],
      location: []
    });
  }

  filterData() {
    if (!this.inspectionsBkp) {
      return;
    }

    if(this.selectedState != this.filterForm.value.report_state) {
      this.filterForm.value.counties = [];
      this.filterForm.value.barracks = [];
    }
    this.selectedState = this.filterForm.value.report_state;
    this.performCommonChangeHandling();

    const formData = this.filterForm.value;

    this.inspectionsForMapping = this.inspectionsBkp.filter((o) => {
      if (formData.vehicle_type == 'Bus' && o.vehicletruckbusind != 'B') {
        return false;
      }

      if (formData.vehicle_type == 'Large Truck' && o.vehicletruckbusind != 'T') {
        return false;
      }

      if (formData.highlights && o[formData.highlights] < 1) {
        return false;
      }

      if (formData.violations_issued && formData.violations_issued.length > 0) {
        const violationTypes = [
          o.num_driver_violations > 0 ? 'Driver' : 'Other',
          o.num_vehicle_violations > 0 ? 'Vehicle' : 'Other',
          o.num_violations > 0 ? 'Any' : 'None',
        ];

        if (_.intersection(formData.violations_issued.map(v =>
          v[this.violationsIssuedDropdownSettings.idField]), violationTypes).length == 0) {
          return false;
        }
      }

      if (formData.resulting_oos && formData.resulting_oos.length > 0) {
        const oosTypes = [
          o.any_driver_out_of_service > 0 ? 'Driver' : 'Other',
          o.any_vehicle_out_of_service > 0 ? 'Vehicle' : 'Other',
          o.any_out_of_service > 0 ? 'Any' : 'None',
        ];

        if (_.intersection(formData.resulting_oos.map(v =>
          v[this.resultingOosDropdownSettings.idField]), oosTypes).length == 0) {
          return false;
        }
      }

      // check all checkbox fields against the corresponding field on the object
      const unmatchedField = ['any_crash_related', 'any_out_of_service', 'traffic_enforcement', 'hazmat'].find(f => {
        return formData[f] && o[f] < 1;
      });

      if (unmatchedField != undefined) {
        return false;
      }



      const checkSpecs = [
        [formData.inspection_month, o.month, this.monthDropdownSettings],
        [formData.towns, o.msp_town_id, 'name'],
        // [formData.barracks, o.barracks_name, this.barracksDropdownSettings],
        // [formData.counties, o.county_fips, 'county_fips'],
        [formData.inspection_level, o.insptypelevelcode, this.inspectionLevelDropdownSettings],
        [formData.inspection_year, o.year, this.yearDropdownSettings],
        [formData.inspection_duration_bucket, o.inspection_duration_bucket, this.inspectionDurationDropdownSettings],
        [formData.dow, o.downame, undefined],
        [formData.hour, o.hour, this.hourDropdownSettings],
        [formData.gwvr_type, o.gwvr_type, this.vehiclegvwrsDropdownSettings],
        [formData.interstate, o.carrierinterstateflag, this.interstateDropdownSettings],
        [formData.location, o.highwaydesctext],
        [formData.carrier, o.carriername],
      ];

      const unmatched = checkSpecs.find(s => !this.checkSelectedValue(s[0], s[1], s[2]));
      // console.log("kicked out by ", unmatched);
      return unmatched === undefined;
    });

    this.inspections = this.inspectionsForMapping.filter(o => {
      return this.checkSelectedValue(formData.barracks, o.barracks_name, 'name') &&
			  this.checkSelectedValue(formData.counties, o.county_fips, "county_fips");
    })

    this.toggleMapView(this.selectedRegions.type);
  }


  resetForm() {
    this.filterForm.reset();
    this.setSelectedRegionsValue();
    let useYear = moment().month() > 3 ? moment().year() : moment().year() - 1;
    this.filterForm.controls.inspection_year.setValue([{
      id: useYear,
      text: useYear.toString()
    }]);
    this.filterForm.controls.report_state.setValue(this.selectedState);
    this.vehicleTypeModel = 'All CMVs';
    this.selectedBarracks = [];
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
      this.changeDetectorRef.markForCheck();
    });
  }

  fetchData(filterData?) {
    if (this.dataRequest) {
      this.dataRequest.unsubscribe();
    }
    this.dataRequest = this.inspectionService.getInspectionsMapping(filterData).subscribe((data: { inspections: any }) => {
        if (data) {
          this.hasError = false;
          this.inspectionsBkp = data.inspections;
          this.inspections = data.inspections;
          this.filterData();
          // this.toggleMapView(this.selectedRegions.type);
        }
      },
      err => {
        this.handleError(err);
      }
    );
  }

  regionSelected(sR: IJurisdictionInfo) {
    if(sR){
      this.filterForm.patchValue({
        barracks: sR.barracks,
        counties: sR.counties
      });
      // this.toggleMapView(sR.type);
      this.focusMapPart(sR.type === 'barracks' ? sR.barracks : sR.counties);
      // this.filterData();
    }
  }

  focusMapPart(selectedOptions?) {
    if (selectedOptions && selectedOptions.length > 0) {
      // don't zoom in 'too far', want to give context
      var flyOptions = {maxZoom: this.stateSettings[this.filterForm.value.report_state].leafletOptions.zoom + 1};
      this.heatmap.flyToBounds(this.getSelectedRegionBoundary(selectedOptions, this.regionsGeoJson), flyOptions);
    } else {
      this.resetMapView();
    }
  }

  getCentroid(arr) {
    let twoTimesSignedArea = 0;
    let cxTimes6SignedArea = 0;
    let cyTimes6SignedArea = 0;

    const length = arr.length;

    const x = ((i) => {
      return arr[i % length][0];
    });
    const y = ((i) => {
      return arr[i % length][1];
    });

    for (let i = 0; i < arr.length; i++) {
      const twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
      twoTimesSignedArea += twoSA;
      cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
      cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
    }
    const sixSignedArea = 3 * twoTimesSignedArea;
    return [cxTimes6SignedArea / sixSignedArea, cyTimes6SignedArea / sixSignedArea];
  }

  toggleMapView(regionType?) {
    if (this.selectedRegions.type !== regionType) {
      // this.selectedRegions = [];
      this.selectedBarracks = [];
      this.setSelectedRegionsValue([],regionType === 'cities' ? regionType : null );
      this.filterForm.controls.towns.setValue(null);
      this.filterForm.controls.counties.setValue(null);
      this.filterForm.controls.barracks.setValue(null);
      this.resetMapView();
    }
    this.leafletHeatMapLayers = this.ims.generateHeatMap(this.regionsGeoJson, this, regionType);
    this.updateGeoJson(this.filterForm.value.report_state, regionType);
    this.selectedRegions.type = regionType;
  }

  resetMapView(state?) {
      state = state || this.filterForm.value.report_state;
      this.leafletHeatMapOptions = this.stateSettings[state].leafletOptions;
      this.leafletHeatMapOptionsCenter = this.leafletHeatMapOptions.center;
      this.leafletHeatMapOptionsZoom = this.leafletHeatMapOptions.zoom;
  }

  onHeatMapReady(map) {
    this.heatmap = map;
    this.heatmap.fitBounds([
      [42.9, -72.8],
      [41.2, -69.8]
    ]);
  }

}
