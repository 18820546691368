import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sort-icon',
  templateUrl: './sort-icon.component.html'
})
export class SortIconComponent implements OnInit {

  @Input("fieldName") fieldName:string;
  @Input("activeSortKey") activeSortKey:string;
  @Input("activeSortDirection") activeSortDirection:string;

  constructor() { }

  ngOnInit() {
  }

}
