import { Injectable } from '@angular/core';
import { IDisplayItem } from './time-range-selector/time-range-selector.component';
import {ITimeRangeInfo} from "./common.service";

@Injectable({
  providedIn: 'root'
})
export class TimeRangeFormatterService {

  constructor() { }

  getSelectedItemsDisplay(val: ITimeRangeInfo): IDisplayItem[] {
    if(!val) {
      return [];
    }
    const prefix = val.fiscalYear ? 'FY' : 'CY';
    // @ts-ignore : TypeScript Version linting Issue for array flatmap
    return val.years.flatMap((y: { name: string, selectedQuarters: any[] }) => {
      let year = '' + y.name;
      year = year.slice(-2);
      if (y.selectedQuarters.length == 4) {
      return {name: prefix + year, year: y.name};
      } else if (y.selectedQuarters.length > 0) {
      return y.selectedQuarters.map((q) => {
        return {name: prefix + year + '-Q' + q, year: y.name, quarter: q};
      });
      }
    }).filter(d => typeof d == 'object');
  }
}
