import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IBarracksInfo } from './common.service';
import * as _ from 'lodash';
import { JurisdictionFormatterService } from './jurisdiction-formatter.service';

@Component({
  selector: 'app-barracks-selector',
  templateUrl: './barracks-selector.component.html',
  styleUrls: ['./barracks-selector.component.scss']
})
export class BarracksSelectorComponent implements OnInit {


  _placeholder = "Statewide";
  selectedItemsDisplay: IBarracksInfo[] = [];
  showingDropdown: boolean = false;
  @Input("itemsShowLimit") itemsShowLimit: number = 2;

  @Input("showHierarchy") showHierarchy: boolean = false;

  @Output("selectedBarracksChange")
  selectedBarracksChange: EventEmitter<IBarracksInfo[]> = new EventEmitter<any>();

  _selectedBarracks: IBarracksInfo[] = [];
  @Input("troops") troops: IBarracksInfo[] = [];

  @Input("anchor") anchor: string;

  allBarracksSelected: boolean;

  dropdownListStyle: { [klass: string]: any; } = {"right" : "0px"};

  constructor(private jurisdictionFormatter: JurisdictionFormatterService) {

  }

  @Input()
  get selectedBarracks() {
    return this._selectedBarracks;
  }

  set selectedBarracks(val) {
    this._selectedBarracks = val;
    this.selectedBarracksChange.emit(this.selectedBarracks);
    if(this.troops) {
      this.troops.forEach(t => t.barracks.forEach(b => b.selected = false));
      this._selectedBarracks.forEach(b => b ? (b.selected = true) : '');
      this.troops.forEach(this.updateTroopSelection);
    }

    this.updateDisplayItems();

  }

  ngOnInit(): void {

  }

  closeDropdown() {
    this.showingDropdown = false;
  }

  onItemClick(event, item:IBarracksInfo) {
    if(item.type == "troop") {
      this.toggleTroop(item);
    } else {
      this.toggleBarracks(item);
    }
  }

  toggleDropdown() {
    this.showingDropdown = !this.showingDropdown;
  }

  toggleBarracks(item:IBarracksInfo) {
    item.selected = !item.selected;
    let parentTroop = _.find(this.troops, t => t.troop_name == item.troop_name);
    this.selectedBarracks = _.filter(_.flatMap(this.troops, "barracks"), b => b.selected);
  }

  selectAll() {
    this.selectedBarracks = _.flatMap(this.troops, "barracks");
  }

  deselectAll() {
    this.selectedBarracks = [];
  }

  ngOnChanges() {
    if(this.troops) {
      this.troops.forEach(this.updateTroopSelection);
      var allBarracks = _.flatMap(this.troops, "barracks");
      this.selectedBarracks.forEach(b => b ? (b.selected = true): '');
    }

    if(this.anchor == "left") {
      this.dropdownListStyle = {"left": "20px"};
    } else {
      this.dropdownListStyle = {"right": "0px"};
    }
  }

  updateTroopSelection(troop:IBarracksInfo) {
    troop.selectedBarracks = _.filter(troop.barracks, b => b.selected);
  }

  updateDisplayItems() {
    this.selectedItemsDisplay = this.jurisdictionFormatter.getSelectedItemsDisplay(this.troops);
  }

  toggleTroop(item:IBarracksInfo) {
    if(item.selectedBarracks.length == item.barracks.length) {
      item.barracks.forEach(b => b.selected = false);
    } else {
      item.barracks.forEach(b => b.selected = true);
    }
    var allBarracks = _.flatMap(this.troops, "barracks");
    this.selectedBarracks = _.filter(allBarracks, b => b.selected);
  }

  itemShowRemaining(): number {
    return this.selectedItemsDisplay.length - this.itemsShowLimit;
  }

}
