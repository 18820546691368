import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-tooltip',
  templateUrl: './help-tooltip.component.html',
  styleUrls: ['./help-tooltip.component.css']
})
export class HelpTooltipComponent implements OnInit {

  constructor() { }

  @Input("text")
  text: string;

  ngOnInit() {
  }

}
