import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IBarracksInfo, ICountyInfo } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class JurisdictionFormatterService {

  constructor() { }

  collapseDisplay(entries: IBarracksInfo[]): string {
    if(entries.length > 0) {
      return _.map(entries, e => e.name).join(", ")
    } else {
      return "Statewide"
    }
  }

  collapseDisplayCounty(entries: ICountyInfo[]): string {
    if(entries.length > 0) {
      return _.map(entries, e => e.county_name).join(", ")
    } else {
      return "Statewide"
    }
  }  

  getSelectedItemsDisplay(troops: IBarracksInfo[]): IBarracksInfo[] {
    var allBarracks = _.flatMap(troops, "barracks");
    var selectedBarracks = _.filter(allBarracks, b => b.selected);
    if(allBarracks.length == selectedBarracks.length) {
      return [];
    }

    return _.flatMap(troops, t => {
      if(t.selectedBarracks.length == t.barracks.length) {
        return [t];
      } else {
        return t.selectedBarracks;
      }
    });
  }
}
