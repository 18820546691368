import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

export interface IMultiSelectAccordionToggleEntry {
	text: string;
  id: string;
  selected?: boolean;
  originalValue: any
}

export const MULTISELECT_ACCORDION_DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MultiSelectAccordionComponent),
	multi: true
};

@Component({
  selector: 'app-multi-select-accordion',
  templateUrl: './multi-select-accordion.component.html',
  styleUrls: ['./multi-select-accordion.component.css'],
  providers: [MULTISELECT_ACCORDION_DROPDOWN_CONTROL_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectAccordionComponent implements OnInit, ControlValueAccessor {

	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

  showingDropdown = false;
  @Input("title") title:string;
  @Input("data") data: any;
  @Input("settings") settings:any;

  @Output("selectedEntriesChange")
  selectedEntriesChange: EventEmitter<IMultiSelectAccordionToggleEntry[]> = new EventEmitter<any>();

  _allEntries: IMultiSelectAccordionToggleEntry[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) { 
    
  }

  @Input()
  get selectedEntries() {
    return this._allEntries.filter(e => e.selected);
  }

  set selectedEntries(val) {
    this._allEntries.forEach(e => {
      e.selected = _.find(val, v => v.id == e.id) != undefined;
    });
    
    this.changeDetectorRef.detectChanges();
  }

  handleChange(entry) {
    this.onChangeCallback(this.selectedEntries.map(e => e.originalValue));
  }

  writeValue(obj: any): void {
    console.log(obj);
    this.selectedEntries = this.convertEntries(obj);
    console.log(this.selectedEntries);
    this.onChangeCallback(this.selectedEntries.map(e => e.originalValue));
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
    
  }

  closeDropdown() {
    this.showingDropdown = false;
  } 

  onItemClick(event, item:IMultiSelectAccordionToggleEntry) {
    if(_.includes(this.selectedEntries, item)) {
      this.selectedEntries = _.without(this.selectedEntries, item);
    } else {
      this.selectedEntries.push(item);
    }
  }
  
  toggleDropdown() {
    this.showingDropdown = !this.showingDropdown;
  }

  selectAll() {
    this.selectedEntries = _.clone(this._allEntries);
    this.onChangeCallback(this.selectedEntries.map(e => e.originalValue));
  }

  deselectAll() {
    this.selectedEntries = [];
    this.onChangeCallback(this.selectedEntries.map(e => e.originalValue));
  }

  convertEntries(entries: any):IMultiSelectAccordionToggleEntry[] {
    if(entries) {
      return _.map(entries, e => {
        return {
          text: e[this.settings.textField], 
          id: e[this.settings.idField],
          originalValue: e
        };
      });
    } else {
      return [];
    }
  }

  ngOnChanges() {
    this._allEntries = this.convertEntries(this.data);
  }
}
