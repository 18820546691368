import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../common/common.component';
import { CommonService } from '../common/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestAccountService } from './request-account.service';

@Component({
	selector: 'app-request-account',
	templateUrl: './request-account.component.html',
	styleUrls: ['./request-account.component.css']
})
export class RequestAccountComponent extends BaseComponent implements OnInit {
	requestForm: FormGroup;
	formSubmitting: boolean;
	formSubmitted: boolean;

	constructor(commonService: CommonService,
				private raService: RequestAccountService,
				private formBuilder: FormBuilder) {
		super(commonService);
	}

	ngOnInit() {
		this.requestForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			jobTitle: ['', [Validators.required]],
			organization: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			comment: [],
		});
	}

	submitForm() {
		if ( this.requestForm.valid ) {
			this.formSubmitting = true;
			this.raService.sendRequest(this.requestForm.value).subscribe((response: { status }) => {
				if ( response.status === 'OK' ) {
					this.requestForm.reset();
					this.formSubmitted = true;
				}
				this.formSubmitting = false;
			}, (err) => {
				this.formSubmitting = false;
				this.handleError(err);
			});
		} else {
			Object.keys(this.requestForm.controls).forEach((c) => {
				this.requestForm.get(c).markAsTouched();
			});
		}
	}


	get name() {
		return this.requestForm.controls.name;
	}

	get jobTitle() {
		return this.requestForm.controls.jobTitle;
	}

	get organization() {
		return this.requestForm.controls.organization;
	}

	get email() {
		return this.requestForm.controls.email;
	}

	get comment() {
		return this.requestForm.controls.comment;
	}

}
