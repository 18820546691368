import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import {IBarracksInfo, ICountyInfo, ITimeRangeInfo, ITroopInfo} from '../common.service';
import { JurisdictionFormatterService } from '../jurisdiction-formatter.service';
import { TimeRangeFormatterService } from '../time-range-formatter.service';

@Component({
  selector: 'app-current-selections-label',
  templateUrl: './current-selections-label.component.html',
  styleUrls: ['./current-selections-label.component.css']
})
export class CurrentSelectionsLabelComponent implements OnInit {

  @Input()
  stringFilters: string[]

  @Input()
  stringArrayFilters: string[][];

  @Input()
  troops: IBarracksInfo[];

  @Input()
  selectedTimeRange: ITimeRangeInfo;

  @Input()
  counties: ICountyInfo[];

  @Input()
  formValue: any;

  @Input()
  inspectionLevels: {name: string, value: string}[];

  _display: string;

  constructor(private jurisdictionFormatter: JurisdictionFormatterService,
    private timeRangeFormatter: TimeRangeFormatterService) { }

  ngOnInit() {

  }

  ngOnChanges() {
    let displayVals = (this.stringFilters || []).filter(s => s != "All").concat([
      this.counties && this.counties.length > 0 ?
        this.jurisdictionFormatter.collapseDisplayCounty(this.counties) :
        this.jurisdictionFormatter.collapseDisplay(this.jurisdictionFormatter.getSelectedItemsDisplay(this.troops)),
      _.map(this.timeRangeFormatter.getSelectedItemsDisplay(this.selectedTimeRange), c => c.name).join(", "),
      this.inspectionLevels ? _.map(this.inspectionLevels, "name").join(", ") : null
    ]);

    if(this.formValue) {
      _.map(this.formValue, (v, k) => {
        if(v) {
          if(v.constructor == Array) {
            let checkFields = ["name", "text", "description", "descr", "gross_vehc_wght_ratg_descr", "crash_severity_descr",
                "manr_coll_descr", "weath_cond_descr", "drvr_cntrb_circ_descr", "lics_restr_code", "lics_restr_descr",
                "cmv_driver_age", "county_name"];
            if(v.length > 0 && typeof v[0] == "object") {
              let f = _.find(checkFields, f => v[0][f] != undefined);
              displayVals.push(v.map(e => e[f]).join(", "));
            } else {
              displayVals.push(v.join(", "));
            }
          } else {
            displayVals.push(v);
          }
        }
      });
    }

    _.each(this.stringArrayFilters, arr => {
      if(Array.isArray(arr) && !(arr.length == 1 && arr[0] == "All")) {
        displayVals.push(arr.join(", "));
      }
    });

    let disp = _.map(_.filter(displayVals, s => s), s => "(" + s + ")").join(", ");
    if(disp && disp != "(Statewide)") {
      this._display = 'Filtered: ' + disp;
    } else {
      this._display = "";
    }
  }

}
